export const state = ()=>{
  return {
    guidDom:null,//要显示的节点
    guideLoadig:false,//是否正在生成引导图
    guidDomShow:false,//是否需要显示引导
    guidShowPopup:false,
    guidShowPopupType:''
  }
}
export const mutations = {
  setGuidDom(state,val){
    state.guidDom = val
  },
  setGuideLoadig(state,val){
    state.guideLoadig = val
  },
  setGuidDomShow(state,val){
    state.guidDomShow = val
  },
  setGuidShowPopup(state,val){
    state.guidShowPopup = val
  },
  setGuidShowPopupType(state,val){
    state.guidShowPopupType = val
  }
}