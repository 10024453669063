var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "chatBox"
    }
  }, [_c('div', {
    class: {
      'chatLogBox': true,
      'chatLogBoxZindex': _vm.showChatWindow
    },
    attrs: {
      "id": "home-guide-step5"
    }
  }, [_c('div', {
    staticClass: "logHouver"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$store.state.register && !_vm.$store.state.dialogLogin && !_vm.$store.state.findPassword && _vm.$route.path != '/specificationsPolicy',
      expression: "!$store.state.register && !$store.state.dialogLogin && !$store.state.findPassword &&$route.path!='/specificationsPolicy'"
    }],
    staticClass: "chat-icon",
    on: {
      "click": _vm.openChatWindow
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showChatWindowCss,
      expression: "!showChatWindowCss"
    }],
    staticClass: "logImg",
    attrs: {
      "src": require("assets/images/chatLog.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChatWindow || _vm.showChatWindowCss,
      expression: "showChatWindow || showChatWindowCss"
    }],
    class: _vm.showChatWindow ? 'logImg logDiv' : 'displayNone',
    attrs: {
      "src": require("assets/images/chatClose.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChatWindow || _vm.showChatWindowCss,
      expression: "showChatWindow || showChatWindowCss"
    }],
    class: !_vm.showChatWindow ? 'logDiv2 logImg' : 'displayNone',
    attrs: {
      "src": require("assets/images/chatClose.svg")
    }
  }), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showChatWindow && _vm.unreadNum && _vm.loginToken,
      expression: "!showChatWindow && unreadNum && loginToken"
    }],
    staticClass: "numSpan"
  }, [_vm._v("\n          " + _vm._s(_vm.unreadNum ? _vm.unreadNum : '') + "\n        ")])]), _vm._v(" "), _c('ChatWindow', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChatWindowCss,
      expression: "showChatWindowCss"
    }],
    class: _vm.showChatWindow ? 'cssShow' : 'cssClose',
    on: {
      "showIAgree": _vm.updateShowIAgree
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }