var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.$store.state.chatLog.pageLoading,
      expression: "$store.state.chatLog.pageLoading"
    }],
    staticClass: "bigBox"
  }, [_vm.roomType && _vm.roomType.room_type != 4 ? _c('div', {
    staticClass: "relationDiv",
    on: {
      "click": function ($event) {
        return _vm.openNewDetailPage(_vm.roomType);
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.roomType && _vm.roomType.room_type == 1,
      expression: " roomType && roomType.room_type == 1"
    }],
    staticClass: "relationImg",
    attrs: {
      "src": require("assets/images/quoteSvg.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.roomType && _vm.roomType.room_type == 2,
      expression: " roomType && roomType.room_type == 2"
    }],
    staticClass: "relationImg",
    attrs: {
      "src": require("assets/images/orderSvg.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.roomType.room_type == 1,
      expression: "roomType.room_type == 1"
    }]
  }, [_vm._v(_vm._s(_vm.roomType.relation_name ? `Quote No: ${_vm.roomType.relation_name}` : ''))]), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.roomType.room_type == 2,
      expression: "roomType.room_type == 2"
    }]
  }, [_vm._v(_vm._s(_vm.roomType.relation_name ? `Order No: ${_vm.roomType.relation_name}` : ''))])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "chatMain",
    attrs: {
      "id": "chatMain"
    },
    on: {
      "scroll": _vm.scrollChange
    }
  }, [_c('div', {
    staticClass: "chatMain-Div",
    attrs: {
      "id": "chatMainDiv"
    }
  }, _vm._l(_vm.theMessageList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "msgDiv",
      style: item.is_sender === 1 ? {
        display: 'flex',
        justifyContent: 'flex-end'
      } : {}
    }, [item.showDateTiele ? _c('div', {
      staticClass: "listDateTitle"
    }, [_vm._v("\n          " + _vm._s(item.showDateTiele) + "\n        ")]) : item.is_sender === 0 ? _c('div', {
      staticClass: "senderBox"
    }, [_c('div', {
      staticClass: "userImg"
    }, [_vm.accInfo.headImg ? _c('img', {
      style: _vm.imgStyle,
      attrs: {
        "src": _vm.imgUrl + _vm.accInfo.headImg
      }
    }) : _c('img', {
      style: _vm.imgStyle,
      attrs: {
        "src": require("assets/images/defaultHead.png")
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "nameAndCon"
    }, [_c('div', {
      staticClass: "nickname"
    }, [_vm._v(_vm._s(item.nickname))]), _vm._v(" "), _c('div', {
      staticClass: "msgContent"
    }, [item.content ? _c('p', {
      staticClass: "msgText"
    }, [_vm._v(_vm._s(_vm._f("escape")(item.content)))]) : item.talk_records_file.length && item.talk_records_file[0].file_type && item.talk_records_file[0].file_type == 6 ? _c('div', {
      staticClass: "selfMsgImg"
    }, [_c('img', {
      staticClass: "img",
      attrs: {
        "src": _vm.imgUrl + item.talk_records_file[0].file_url
      },
      on: {
        "click": function ($event) {
          return _vm.onLookImg(item.talk_records_file);
        },
        "load": _vm.imageLoad
      }
    })]) : item.talk_records_file.length && item.talk_records_file[0].file_type ? _c('div', {
      staticClass: "msgText msgFile",
      on: {
        "click": function ($event) {
          return _vm.dowloadFile(item);
        }
      }
    }, [_vm._m(0, true), _vm._v(" "), _c('div', {
      staticClass: "fileInfo"
    }, [_c('div', {
      staticClass: "fileName"
    }, [_vm._v(_vm._s(item.talk_records_file[0].file_name))]), _vm._v(" "), _c('div', {
      staticClass: "fileSize"
    }, [_vm._v(_vm._s(_vm.getSize(item.talk_records_file[0].file_size)))])])]) : _c('div', {
      staticClass: "msgFile msgText"
    }, [_vm._m(1, true), _vm._v(" "), _vm._m(2, true)]), _vm._v(" "), _c('div', {
      staticClass: "createTime"
    }, [_vm._v(_vm._s(_vm.transTimeType(item.create_time)))])])])]) : item.is_sender === 1 ? _c('div', {
      staticClass: "selfBox"
    }, [_c('div', {
      staticClass: "selfMsgBox"
    }, [_c('div', {
      staticClass: "createTime"
    }, [_vm._v(_vm._s(_vm.transTimeType(item.create_time)))]), _vm._v(" "), _c('div', {
      staticClass: "selfContent"
    }, [item.content ? _c('p', {
      staticClass: "selfMsgTxt"
    }, [_vm._v(_vm._s(_vm._f("escape")(item.content)))]) : item.extra_params && _vm.chatMessage.room_type == 1 ? _c('div', {
      staticClass: "quoteInfo",
      on: {
        "click": function ($event) {
          return _vm.openNewDetailPage({
            relation_name: item.extra_params.no,
            room_type: 1
          });
        }
      }
    }, [_c('div', {
      staticClass: "quoteTitle"
    }, [_vm._v("Quote information:")]), _vm._v(" "), _c('div', {
      staticClass: "quoteNo"
    }, [_c('div', [_vm._v("Quote No: " + _vm._s(item.extra_params.no))]), _vm._v(" "), _c('div', [_vm._v("Create time: " + _vm._s(_vm.transTimeType(item.extra_params.create_time, '1')))])])]) : item.extra_params && _vm.chatMessage.room_type == 2 ? _c('div', {
      staticClass: "quoteInfo",
      on: {
        "click": function ($event) {
          return _vm.openNewDetailPage({
            relation_name: item.extra_params.no,
            room_type: 2
          });
        }
      }
    }, [_c('div', {
      staticClass: "quoteTitle"
    }, [_vm._v("Order information:")]), _vm._v(" "), _c('div', {
      staticClass: "quoteNo"
    }, [_c('div', [_vm._v("Order No: " + _vm._s(item.extra_params.no))]), _vm._v(" "), _c('div', [_vm._v("Create time: " + _vm._s(_vm.transTimeType(item.extra_params.create_time, '1')))])])]) : item.talk_records_file.length && item.talk_records_file[0].file_type && item.talk_records_file[0].file_type == 6 ? _c('div', {
      staticClass: "selfMsgImg"
    }, [_c('img', {
      staticClass: "img",
      attrs: {
        "src": _vm.imgUrl + item.talk_records_file[0].file_url
      },
      on: {
        "click": function ($event) {
          return _vm.onLookImg(item.talk_records_file);
        },
        "load": _vm.imageLoad
      }
    })]) : item.talk_records_file.length ? _c('div', {
      staticClass: "msgFile",
      on: {
        "click": function ($event) {
          return _vm.dowloadFile(item);
        }
      }
    }, [_vm._m(3, true), _vm._v(" "), _c('div', {
      staticClass: "fileInfo"
    }, [_c('div', {
      staticClass: "fileName"
    }, [_vm._v(_vm._s(item.talk_records_file[0].file_name))]), _vm._v(" "), _c('div', {
      staticClass: "fileSize"
    }, [_vm._v(_vm._s(_vm.getSize(item.talk_records_file[0].file_size)))])])]) : _vm._e()])])]) : _vm._e()]);
  }), 0)]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.networkBreak,
      expression: "networkBreak"
    }],
    staticClass: "netErr"
  }, [_vm._m(4)]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadFileList.length > 0,
      expression: "uploadFileList.length > 0"
    }],
    staticClass: "fileList",
    style: _vm.drop ? {
      background: 'opacity'
    } : {}
  }, [_c('div', {
    staticClass: "fileListDiv",
    style: _vm.drop ? {
      boxShadow: 'none',
      overflow: 'hidden'
    } : {}
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['1', '3', '4'].includes(_vm.uploadStatus),
      expression: "['1','3','4'].includes(uploadStatus)"
    }],
    staticClass: "drop",
    style: _vm.drop ? {
      borderRadius: '8px 8px 0 0'
    } : {
      borderRadius: '0 0 8px 8px'
    },
    on: {
      "click": function ($event) {
        _vm.drop = !_vm.drop;
      }
    }
  }, [_c('i', {
    class: _vm.drop ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
  })]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.uploadStatus == '2',
      expression: "uploadStatus == '2'"
    }],
    staticClass: "drop dropHover dropLoading",
    style: _vm.drop ? {
      borderRadius: '8px 8px 0 0'
    } : {
      borderRadius: '0 0 8px 8px'
    },
    on: {
      "click": function ($event) {
        _vm.drop = !_vm.drop;
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-loading hoverClose"
  }), _vm._v(" "), _c('i', {
    class: _vm.drop ? 'el-icon-arrow-up hoverOpen' : 'el-icon-arrow-down hoverOpen'
  })]), _vm._v(" "), _vm._l(_vm.uploadFileList, function (item, index) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.drop,
        expression: "!drop"
      }],
      key: item.uid,
      staticClass: "file",
      class: {
        'file-failed': item.status == 'failed'
      }
    }, [_c('div', {
      staticClass: "fileCon"
    }, [_c('div', {
      staticClass: "top-left"
    }, [item.coverFile ? _c('span', {
      staticClass: "top-left-span"
    }, [_c('img', {
      staticClass: "fileImg",
      style: _vm.footerImgStyle(item.coverFile),
      attrs: {
        "src": item.coverFile,
        "alt": "",
        "srcset": ""
      }
    })]) : _c('img', {
      staticClass: "fileImg",
      staticStyle: {
        "border": "0"
      },
      attrs: {
        "src": require("assets/images/chatTxt.svg"),
        "alt": ""
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "top-fileTex"
    }, [_c('span', {
      class: item.status !== 'failed' ? 'top-fileTex-text' : 'top-fileTex-text uploadFail'
    }, [_vm._v(_vm._s(item.file.name))]), _vm._v(" "), _c('span', {
      class: item.status !== 'failed' ? 'size' : 'size uploadFail'
    }, [_vm._v("\n                " + _vm._s(_vm.getSize(item.file.size)) + "\n              ")])])]), _vm._v(" "), _c('div', {
      staticClass: "top-right"
    }, [_c('span', {
      staticClass: "retry",
      on: {
        "click": function ($event) {
          return _vm.uploadRetry(item);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "reset",
        "width": _vm.px2rem('16px'),
        "height": _vm.px2rem('16px'),
        "color": "#878787"
      }
    })], 1), _vm._v(" "), item.status == 'ready' ? _c('span', {
      staticClass: "pencent"
    }, [_vm._v(_vm._s(item.progess) + "%")]) : item.status == 'success' ? _c('span', {
      staticClass: "pencent"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "success",
        "width": _vm.px2rem('19px'),
        "height": _vm.px2rem('19px'),
        "color": "#15A759"
      }
    })], 1) : item.status == 'failed' ? _c('span', {
      staticClass: "pencent"
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "gantanhao",
        "width": _vm.px2rem('16px'),
        "height": _vm.px2rem('16px'),
        "color": "#ED7B2F"
      }
    })], 1) : _vm._e(), _vm._v(" "), _c('span', {
      staticClass: "md-close",
      on: {
        "click": function ($event) {
          return _vm.deleteFile(item, index);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "icon-class": "delete",
        "color": "#878787",
        "width": _vm.px2rem('16px'),
        "height": _vm.px2rem('16px')
      }
    })], 1)])])]);
  })], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": require("assets/images/sendFileLog.svg")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": require("assets/images/sendFileLog.svg")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fileInfo"
  }, [_c('div', {
    staticClass: "fileName"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": require("assets/images/sendFileLog.svg")
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "netError"
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/networkWar.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v("\n        Network error, please check the connection and try again.\n      ")])]);

}]

export { render, staticRenderFns }