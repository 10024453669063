var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type === 'ball' ? _c('div', {
    staticClass: "loading-3d"
  }, [_vm._v("\n  " + _vm._s(!_vm.$store.state.isGraphicsLibraryLoaded ? 'The graphics library is being initialized' : _vm.content) + "\n  "), _c('div', {
    staticClass: "sp sp-3balls"
  })]) : _vm.type === 'dot' ? _c('div', {
    staticClass: "loader"
  }, [_c('div', {
    staticClass: "m-load"
  }), _vm._v(" "), _vm._m(0)]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-load2"
  }, [_c('div', {
    staticClass: "item"
  }), _vm._v(" "), _c('div', {
    staticClass: "item"
  }), _vm._v(" "), _c('div', {
    staticClass: "item"
  }), _vm._v(" "), _c('div', {
    staticClass: "item"
  })]);

}]

export { render, staticRenderFns }