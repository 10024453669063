<template>
  <div v-if="type==='ball'" class="loading-3d">
    {{ !$store.state.isGraphicsLibraryLoaded? 'The graphics library is being initialized' : content}}
    <div class="sp sp-3balls"></div>
  </div>
  <div v-else-if="type==='dot'" class="loader">
    <div class="m-load"></div>
    <div class="m-load2">
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    content:{
      type:String,
      default:' Loading model'
    },
    type:{
      type:String,
      default:'ball' 
    }
  }
}
</script>

<style lang="scss" scoped>
 .loading-3d{
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);

    /*css加载动画 begin*/
    .sp {
      display: inline-block;
      width: 24px;
      height: 24px;
      clear: both;
      margin: 0 auto;
    }
    .sp-3balls,
    .sp-3balls:before,
    .sp-3balls:after {
      border-radius: 50%;
      background-color: #2b2b2b;
      width: 8px;
      height: 8px;
      transform-origin: center center;
      display: inline-block;
    }
    .sp-3balls {
      position: relative;
      left: 5px;
      animation: spScaleAlpha 1s infinite linear;
    
    }
    .sp-3balls::before,.sp-3balls::after {
      content: '';
      position: absolute;
      opacity: .25;
    }
    .sp-3balls:before {
      left: 24px;
      top: 0px;
      animation: spScaleAlphaBefore 1s infinite linear;
    }
    .sp-3balls::after {
      left: 12px;
      top: 0px;
     animation: spScaleAlphaAfter 1s infinite linear;
    }
    @keyframes spScaleAlpha {
      0% { opacity: 1; }
      33% {  opacity: 0.25; }
      66% { opacity: 0.25; }
      100% {  opacity: 1; }
    }
    @keyframes spScaleAlphaBefore {
      0% { opacity: 0.25; }
      33% { opacity: 1; }
      66% { opacity: 0.25; }
    }
    @keyframes spScaleAlphaAfter {
      33% { opacity: 0.25; }
      66% { opacity: 1; }
      100% { opacity: 0.25; }
    }
  }
.m-load,
.m-load2 {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: transparent;
}
/** 加载动画的静态样式 **/
.loader {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.m-load2 .item {
  position: absolute;
  left: 50%;
  top: 0;
  width: 8px;
  height: 100%;
}
.m-load2 .item:before,
.m-load2 .item:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #ffffff;
}
.m-load2 .item:after {
  position: absolute;
  bottom: 0;
}
.m-load2 .item:nth-child(2) {
  transform: rotate(45deg);
}
.m-load2 .item:nth-child(3) {
  transform: rotate(90deg);
}
.m-load2 .item:nth-child(4) {
  transform: rotate(135deg);
}
/** 加载动画 **/
@keyframes load {
  0% {
    background-color: #252525;
    opacity: 1;
  }
  100% {
    background-color: #bbb;
    opacity: 0.4;
  }
}
.m-load2 .item:nth-child(1):before {
  animation: load 0.8s linear 0s infinite;
}
.m-load2 .item:nth-child(2):before {
  animation: load 0.8s linear 0.1s infinite;
}
.m-load2 .item:nth-child(3):before {
  animation: load 0.8s linear 0.2s infinite;
}
.m-load2 .item:nth-child(4):before {
  animation: load 0.8s linear 0.3s infinite;
}
.m-load2 .item:nth-child(1):after {
  animation: load 0.8s linear 0.4s infinite;
}
.m-load2 .item:nth-child(2):after {
  animation: load 0.8s linear 0.5s infinite;
}
.m-load2 .item:nth-child(3):after {
  animation: load 0.8s linear 0.6s infinite;
}
.m-load2 .item:nth-child(4):after {
  animation: load 0.8s linear 0.7s infinite;
}
</style>
