var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chatWindow",
    attrs: {
      "id": "chatWindow"
    },
    on: {
      "dragover": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDragHover,
      expression: "isDragHover"
    }],
    staticClass: "dragChatWindow"
  }), _vm._v(" "), _c('div', {
    staticClass: "chatMoudle windowHead"
  }, [_c('ChatHeader', {
    ref: "chatHeader",
    attrs: {
      "accInfo": _vm.accInfo,
      "showIAgree": _vm.showIAgree
    },
    on: {
      "initScroll": _vm.initScroll
    }
  })], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.$store.state.chatLog.pageLoading,
      expression: "$store.state.chatLog.pageLoading"
    }],
    staticClass: "windowContent",
    style: _vm.showChatList ? 'border-radius: 0 0 12px 12px' : ''
  }, [_c('ChatMainMessage', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showChatList,
      expression: "!showChatList"
    }],
    ref: "chatMainMessage",
    attrs: {
      "chatMessage": _vm.chatMessage,
      "accInfo": _vm.accInfo,
      "uploadFileList": _vm.uploadFileList
    },
    on: {
      "uploadRetry": _vm.uploadRetry,
      "deleteFile": _vm.deleteFile
    }
  }), _vm._v(" "), _c('ChatMessageList', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChatList,
      expression: "showChatList"
    }],
    ref: "chatMessageList",
    attrs: {
      "chatRoomData": _vm.chatRoomData
    },
    on: {
      "enterConver": _vm.enterConver,
      "anewGetChatList": _vm.resetList
    }
  })], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showChatList,
      expression: "!showChatList"
    }],
    staticClass: "chatMoudle windowFooter"
  }, [_c('ChatFooter', {
    ref: "chatFooter",
    on: {
      "updateUpload": _vm.updateUploadList
    }
  }), _vm._v(" "), _vm.showIAgree ? _c('div', {
    staticClass: "chatMoudle iAgree"
  }, [_c('div', [_vm._v("\n        This chat widget uses a cookie to interact with website visitors and to provide your chat history. To find out more about this cookie, see your \n        "), _c('span', {
    staticClass: "agreement",
    on: {
      "click": function ($event) {
        return _vm.getAgreement('cookiePolicy');
      }
    }
  }, [_vm._v("Cookie Policy")]), _vm._v(" and "), _c('span', {
    staticClass: "agreement",
    on: {
      "click": function ($event) {
        return _vm.getAgreement('privacyPolicy');
      }
    }
  }, [_vm._v("Privacy Policy")]), _vm._v(".\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "iagreeBtn",
    on: {
      "click": _vm.agree
    }
  }, [_vm._v("I agree")])]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }