<template>
  <div class="messageHeader">
    <div class="conversationTxt" v-show="showChatList">Conversation history</div>
    <div class="headCon" v-show="!showChatList">
      <div class="leftIcon" title="Back to conversation history list" v-if="!showIAgree" @click="backChatList"><i class="el-icon-arrow-left"></i></div>
      <div class="headUser" v-if="!is_work_time">
        <span class="userImg">
          <span class="headImgSpan">
            <img :style="imgStyle" :src="imgUrl + accInfo.headImg" v-if="accInfo.headImg">
            <img class="headImg" src='~assets/images/defaultHead.png' v-if="!accInfo.headImg">
          </span>
          <span class="statusDot" :style="{background: statusDotCorlor}"></span>
          <span class="unreadNum" v-show="unReadMsgNum">{{unReadMsgNum > 99 ? '99+' : unReadMsgNum}}</span>
        </span>
        <div>
          <div class="nickname">{{accInfo.nickname}}</div>
          <div class="next_work_time" v-if="next_work_time">{{next_work_time ? `We'll return on ${next_work_time}` : ''}}</div>
        </div>
      </div>
      <div class="headUser2" v-else>
        <span class="userImg">
          <span class="headImgSpan">
            <img :style="imgStyle" :src="imgUrl + accInfo.headImg" v-if="accInfo.headImg">
            <img class="headImg" src='~assets/images/defaultHead.png' v-if="!accInfo.headImg">
          </span>
          <span class="statusDot" :style="{background: statusDotCorlor}"></span>
          <span class="unreadNum" v-show="unReadMsgNum">{{unReadMsgNum > 99 ? '99+' : unReadMsgNum}}</span>
        </span>
        <span class="userName">
          {{accInfo.nickname}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
  export default {
    props: {
      accInfo: {
        type: Object,
        default: {},
      },
      showIAgree: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        imgUrl: null,
        imgStyle: {width: '100%',height: 'auto'},
        is_work_time: false,
        statusDotCorlor: '#15a759',
        next_work_time: undefined,
        unReadMsgNum: 0,
        onlineTimer: null,
      }
    },
    computed: {
      // 是否展示聊天列表(聊天列表、聊天展示窗口)
      showChatList: function() {
        return this.$store.state.chatLog.showChatListWindow;
      },
      chatMessage: function() {
        return this.$store.state.chatLog.updateUnreadNum;
      },
      checkOnlineStatus: function() {
        return this.$store.state.chatLog.checkOnlineStatus
      }
    },
    watch: {
      accInfo(val) {
        if(!val) {
          return;
        };
        // 在线状态
        if(val.is_work_time && val.im_online_status == 1) {
          // 工作时间在线
          this.statusDotCorlor = '#15a759';
          this.is_work_time = true;
        } else if(val.is_work_time && val.im_online_status != 1) {
          // 工作时间不在线
          this.statusDotCorlor = '#d0d0d0';
          this.is_work_time = true;
          this.next_work_time = null;
        } else {
          this.statusDotCorlor = '#d0d0d0';
          this.is_work_time = false;
          // 回复时间
          let nextDate = val.next_work_time ? dayjs(val.next_work_time * 1000).tz('Asia/Shanghai').format('MMM D [at] HH[:]mm A') + ' (GMT+8)' : '';
          this.next_work_time = nextDate;
        };
        if(val.expire_time) {
          // 非工作日后台回应消息切换在线状态
          this.onCheckOnline(Number(val.expire_time) * 1000)
        }
        // 头像样式处理
        let img = new Image();
        img.src = this.imgUrl + val.headImg;
        let style
        if(img.width >= img.height) {
          style = {width: 'auto', height: '100%'};
        } else {
          style = {width: '100%', height: 'auto'};
        }
        this.imgStyle = style;
      },
      checkOnlineStatus(val) {
        if(val) {
          this.onCheckOnline(600000)
          this.$store.commit('chatLog/setCheckOnlineStatus',false);
        }
      },
      chatMessage(val) {
        const room = this.$store.state.chatLog.roomData;
        if(val && !val.isCurrentRoom) {
          // 获取未读数量
          this.getUnreadMsgNum();
          this.$store.commit('chatLog/setUpdateUnreadNum',null);
          // this.getChatList()
        } else if(val && val.isCurrentRoom) {
          // 清除未读消息
          this.$clearUnreadMsg({read_type:1,room_id:room.id}).then(res => {
            if(res.code == 1) {
              console.log(`清除未读消息,房间号：${room.id}`);
            }
          });
        }
      },
    },
    mounted() {
      this.getImgUrl();
      // 获取未读数量
      this.getUnreadMsgNum()
    },
    destroyed() {
      this.onlineTimer = null;
    },
    methods: {
      // 拼接图片Url
      getImgUrl() {
        this.imgUrl = process.env.baseOss;
      },
      // 非工作时间切换成在线状态
      onCheckOnline(time) {
        if(this.onlineTimer) {
          clearTimeout(this.onlineTimer)
        };
        this.statusDotCorlor = '#15a759';
        this.is_work_time = true;
        this.next_work_time = null;
        let t = time || 600000;
        this.onlineTimer = setTimeout(() => {
          this.statusDotCorlor = '#d0d0d0';
          this.is_work_time = false;
          // 回复时间
          let nextDate = this.accInfo.next_work_time ? dayjs(this.accInfo.next_work_time * 1000).tz('Asia/Shanghai').format('MMM D [at] HH[:]mm A') + ' (GMT+8)' : '';
          this.next_work_time = nextDate;
          clearTimeout(this.onlineTimer)
        },t)
      },
      // 返回聊天列表
      backChatList() {
        this.$emit('initScroll')
        if(this.showChatList) {
          this.$store.commit('chatLog/setShowChatListWindow',false)
        } else {
          this.$store.commit('chatLog/setShowChatListWindow',true)
        }
      },
      getUnreadMsgNum() {
        this.$getUnreadMsg().then(res => {
          if(res.code == 1) {
            this.unReadMsgNum = res.data.num || 0;
            this.$store.commit('chatLog/setUnreadNum',res.data.num)
          } else {
            this.$message.error(res.msg);
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .messageHeader {
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;

    .el-badge {
      border-radius: 50%;
    }

    .conversationTxt {
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
    }

    .headCon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .leftIcon {
        cursor: pointer;
        font-size: 20px;
        margin-right: 15px;
      }

      .headUser {
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
      .headUser2 {
        display: flex;
        width: 100%;
        align-items: center;
        padding-left: 20px;
      }
      .headImgSpan {
        width: 36px;
        height: 36px;
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
      }
      .userImg {
        display: inline-block;
        position: relative;
        width: 36px;
        height: 36px;
        margin-left: -16px;
        margin-right: 20px;
        border-radius: 50%;
        .headImg {
          width: 36px;
          height: auto;
        }

        .statusDot {
          display: inline-block;
          width: 10px;
          height: 10px;
          position: absolute;
          bottom: 1px;
          right: 0;
          border-radius: 50%;
        }
        .unreadNum {
          min-width: 20px;
          text-align: center;
          display: block;
          padding:0 6px;
          border: 1px solid #FFFFFF;
          position: absolute;
          top: -6px;
          left: 20px;
          font-size: 12px;
          background: #EA543F;
          border-radius: 14px;
          box-sizing: border-box;
          transform: scale(0.85);
          -webkit-transform: scale(0.85);
        }
      }

      .userName {
        font-family: 'Poppins';
        font-size: 16px;
        line-height: 60px;
        color: #fff;
      }

    }
    .next_work_time {
      font-size: 12px;
      zoom: 0.85;
    }
    .nickname{
      font-size: 14px;
      font-weight: 600;
    }

  }
</style>