var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userHeader align-center",
    attrs: {
      "id": "nav"
    }
  }, [_c('div', {
    class: {
      'userHeader-l': true,
      'userHeader-l-hide': !_vm.$store.state.aside
    }
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Go back to RapidDirect main site",
      "placement": "bottom"
    }
  }, [_c('div', {
    staticClass: "aside-header cursor-hand",
    on: {
      "click": _vm.gowebsite
    }
  }, [_vm.$store.state.aside ? _c('img', {
    staticClass: "applogo",
    attrs: {
      "src": require("../assets/img/applogo.svg"),
      "alt": ""
    }
  }) : _c('img', {
    staticClass: "applogo-min",
    attrs: {
      "src": require("../assets/img/logo_min.svg"),
      "alt": ""
    }
  })])])], 1), _vm._v(" "), _vm.$route.path != '/help-center/specifications-policy' ? [_vm.$store.state.auth ? _c('div', {
    staticClass: "user-info"
  }, [_vm.$store.state.auth ? _c('el-badge', {
    staticClass: "mgr10 badge cursor-hand",
    attrs: {
      "value": _vm.UnreadNum === 0 ? null : _vm.UnreadNum
    }
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Message",
      "placement": "bottom"
    }
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "msg",
      "width": _vm.px2rem('25px'),
      "height": _vm.px2rem('25px')
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.openMess.apply(null, arguments);
      }
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-dropdown', {
    attrs: {
      "trigger": "click"
    },
    on: {
      "visible-change": _vm.visibleChange,
      "command": _vm.commandSelect
    }
  }, [_c('div', {
    staticClass: "user cursor-hand"
  }, [_c('div', {
    staticClass: "aside-card-user"
  }, [_vm.userData.imageUrl ? _c('img', {
    attrs: {
      "src": _vm.userData.imageUrl
    }
  }) : _c('div', {
    staticClass: "aside-card-user-name"
  }, [_vm._v(_vm._s(_vm.icon_name))])]), _vm._v(" "), !_vm.dropShow ? _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  }) : _c('i', {
    staticClass: "el-icon-arrow-up el-icon--right"
  })]), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "command": "myaccount"
    }
  }, [_vm._v("My Account")]), _vm._v(" "), _c('el-dropdown-item', {
    attrs: {
      "command": "logout"
    }
  }, [_vm._v("Log out")])], 1)], 1)], 1) : _c('div', {
    class: {
      'userHeader-login': true,
      'userHeader-login-hide': !_vm.$store.state.aside
    }
  }, [_c('span', {
    staticClass: "aside-card-login",
    on: {
      "click": _vm.toRegister
    }
  }, [_vm._v("Sign Up")]), _c('span', {
    staticClass: "aside-line"
  }, [_vm._v("|")]), _c('span', {
    staticClass: "aside-card-login",
    on: {
      "click": _vm.Login
    }
  }, [_vm._v("Log In")])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }