import { ChannelClientImpl } from './impl/ChannelClientImpl';
import { ChannelServiceImpl } from './impl/ChannelServiceImpl';
class ChannelFactory {
    newChannelClient(clientWindow, serviceWindow, origin) {
        return new ChannelClientImpl(clientWindow, serviceWindow, origin);
    }
    newChannelService(serviceWindow) {
        return new ChannelServiceImpl(serviceWindow);
    }
}
export const ChannelFactoryInstance = new ChannelFactory();
