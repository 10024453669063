export default {
  methods: {
    //获取团队列表
    getCreateTeamList(bool) {
      let data = {
        page: 1,
        size: 10000
      }
      this.$getCreateTeamList(data).then(res => {
        if (res.code === 1) {
          let list = JSON.stringify(res.data.list)
          this.$store.commit('createTeam/setCreateTeamListData', list)
          localStorage.setItem('createTeam/setCreateTeamListData', list)
          const isfirstTeamUser = this.$store.state.createTeam.isfirstTeamUser //判断是创建团队或者加入团队
          const createTeamDetailId = localStorage.getItem(
            'createTeam/createTeamDetailId'
          )
          if (createTeamDetailId && isfirstTeamUser) {
            this.$router.push({
              path: '/connorsTeam',
              query: {
                createTeamDetailId: localStorage.getItem(
                  'createTeam/createTeamDetailId'
                ),
                is_first: bool ? bool : '' //第一次加入成功开启弹窗引导
              }
            })
          }
        }
      })
    },

    //加入团队
    async joinCreateTeam() {
      if (
        !localStorage.getItem('createTeam/invitation_code') ||
        !localStorage.getItem('createTeam/createTeamDetailId')
      ) {
        this.getCreateTeamList()
        return
      }
      let data = {
        invitation_code:
          localStorage.getItem('createTeam/invitation_code') || '',
        id: localStorage.getItem('createTeam/createTeamDetailId') || ''
      }
      let error = null
      //获取邀请着信息
      const InvitationCodeDataRes = await this.$getInvitationCodeData(
        data
      ).catch(() => {})
      const joinRes = await this.$joinCreateTeam(data).catch(
        err => (error = err)
      )
      if (joinRes?.code === 1) {
        let is_first = joinRes.data.is_first || ''
        let is_repeat = joinRes.data.is_repeat || '' //is_repeat = true重复加入
        this.$store.commit('createTeam/setIsfirstTeamUser', is_first) //判断是第一次创建团队或者加入团队 (弹出引导)
        //判断邀请的邮箱和当前登录的邮箱是否一致
        const current_email = localStorage.getItem('mail')
        //要加入团队的邮箱

        const join_email = InvitationCodeDataRes?.data?.user_email
        if (current_email && current_email != join_email) {
          localStorage.setItem('createTeam/createTeamDetailId', '')
          this.getCreateTeamList()
          return this.$router.replace('/')
        }
        localStorage.removeItem('createTeam/inviterEmail')
        localStorage.removeItem('createTeam/invitation_code')
        if (!is_repeat) {
          this.$message({
            message: 'Join team successfully!',
            type: 'success'
          })
          //加入成功自动更新邮箱验证
          localStorage.setItem('emailValidated', 1)
          this.$store.commit('setShowVerify', false)
        }
        this.getCreateTeamList(is_first)
      } else {
        localStorage.setItem('createTeam/createTeamDetailId', '')
        // this.$message.error(joinRes?.msg)
        this.getCreateTeamList()
      }
    }
  }
}
