var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$store.state.message ? _c('div', {
    class: {
      'el-drawer__open': _vm.$store.state.message
    }
  }, [_c('div', {
    staticClass: "dialog",
    on: {
      "click": _vm.close
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "dialog-win el-drawer rtl"
  }, [_c('div', {
    staticClass: "login-title"
  }, [_c('div', [_vm._v("Notifications")]), _vm._v(" "), _c('div', {
    staticClass: "dialog-close",
    on: {
      "click": _vm.close
    }
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "width": _vm.px2rem('20px'),
      "height": _vm.px2rem('20px'),
      "color": "#fff"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "messbox"
  }, [_c('div', {
    staticClass: "mass-box-top"
  }, [!_vm.selectVisible ? _c('div', {
    staticClass: "space-between align-center"
  }, [_c('el-select', {
    style: {
      width: _vm.px2rem(_vm.tabName == 'all' ? '157px' : _vm.tabName == 'unread' ? '190px' : '174px')
    },
    attrs: {
      "popper-class": "message-select-down"
    },
    on: {
      "change": _vm.onTabCange
    },
    model: {
      value: _vm.tabName,
      callback: function ($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, _vm._l(_vm.tabs, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.value,
        "value": item.id
      }
    });
  }), 1), _vm._v(" "), _vm.msg.length ? _c('el-button', {
    staticClass: "btn_default btn_select",
    on: {
      "click": function ($event) {
        _vm.selectVisible = true;
      }
    }
  }, [_vm._v("Select")]) : _vm._e()], 1) : _c('div', {
    staticClass: "setsDiv"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('el-checkbox', {
    attrs: {
      "value": _vm.allChecked,
      "indeterminate": _vm.isIndeterminate,
      "disabled": !_vm.msg.length
    },
    on: {
      "change": _vm.changeCheckBox
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "right"
  }, [_c('el-dropdown', {
    class: _vm.disabledBtns ? 'set-as-dropdown-diabled' : 'set-as-dropdown',
    attrs: {
      "trigger": "click",
      "disabled": _vm.disabledBtns,
      "placement": "bottom-start"
    },
    on: {
      "visible-change": function ($event) {
        _vm.dropShow = !_vm.dropShow;
      },
      "command": function (val) {
        return _vm.setMsg(val, 'read');
      }
    }
  }, [_c('div', {
    staticClass: "set-as cursor-hand"
  }, [_c('div', {
    staticClass: "aside-card-user"
  }, [_vm._v("Set as")]), _vm._v(" "), !_vm.dropShow ? _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  }) : _c('i', {
    staticClass: "el-icon-arrow-up el-icon--right"
  })]), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "command": "1"
    }
  }, [_vm._v("Read")]), _vm._v(" "), _c('el-dropdown-item', {
    attrs: {
      "command": "0"
    }
  }, [_vm._v("UnRead")])], 1)], 1), _vm._v(" "), _c('el-button', {
    staticClass: "btn_primary delete",
    style: _vm.disabledBtns ? {
      color: '#B8B8B8'
    } : {},
    attrs: {
      "disabled": _vm.disabledBtns
    },
    on: {
      "click": function ($event) {
        return _vm.deleteMsg('all');
      }
    }
  }, [_vm._v("Delete")]), _vm._v(" "), _c('el-button', {
    staticClass: "btn_default cancel",
    on: {
      "click": _vm.cancelMultiple
    }
  }, [_vm._v("Cancel")])], 1)])]), _vm._v(" "), !_vm.msg.length && !_vm.pageLoading ? _c('div', {
    staticClass: "messbox messbox-default"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "messageDefaultPage",
      "width": _vm.px2rem('240px'),
      "height": _vm.px2rem('146px')
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "font16 fw5 c252"
  }, [_vm._v("No message yet")])], 1) : _c('ul', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.changePage,
      expression: "changePage"
    }],
    staticClass: "messList",
    attrs: {
      "infinite-scroll-disabled": _vm.finished,
      "infinite-scroll-distance": 10
    }
  }, _vm._l(_vm.msg, function (item, index) {
    return _c('li', {
      key: 'msg-' + item.id,
      class: item.checked ? 'item activeItem' : 'item',
      on: {
        "click": function ($event) {
          return _vm.handleCurrentChange(item, index);
        }
      }
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.selectVisible,
        expression: "selectVisible"
      }],
      staticClass: "check-all mgr20",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('el-checkbox', {
      on: {
        "change": function ($event) {
          return _vm.checkChange.call(null, arguments[0], index);
        }
      },
      model: {
        value: item.checked,
        callback: function ($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "listLog"
    }, [item.status == 1 ? _c('svg-icon', {
      attrs: {
        "iconClass": "msgread",
        "height": _vm.px2rem('44px'),
        "width": _vm.px2rem('44px')
      }
    }) : _c('svg-icon', {
      attrs: {
        "iconClass": "msgUnread",
        "height": _vm.px2rem('44px'),
        "width": _vm.px2rem('44px')
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "msg-content"
    }, [_c('div', {
      staticClass: "font14 msg-p"
    }, [_c('p', {
      staticClass: "title"
    }, [_vm._v(_vm._s(_vm._f("showContent")(item)))]), _vm._v(" "), _c('div', {
      staticClass: "handle",
      class: {
        'handle-active': _vm.selectVisible
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('span', {
      staticClass: "handle-icon",
      attrs: {
        "title": "Select"
      },
      on: {
        "click": function ($event) {
          return _vm.checkChange(!item.checked, index);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "iconClass": "select",
        "height": _vm.px2rem('24px'),
        "width": _vm.px2rem('24px'),
        "color": item.checked ? '#31D0AA' : '#252525'
      }
    })], 1), _vm._v(" "), _c('span', {
      staticClass: "handle-icon",
      attrs: {
        "title": "Delete"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteMsg('only', item);
        }
      }
    }, [_c('svg-icon', {
      attrs: {
        "iconClass": "msgdelete",
        "height": _vm.px2rem('24px'),
        "width": _vm.px2rem('24px'),
        "color": "#252525"
      }
    })], 1)])]), _vm._v(" "), _c('div', {
      staticClass: "font12 msg-t"
    }, [_vm._v(_vm._s(_vm._f("format_time")(item.create_time)))])])]);
  }), 0), _vm._v(" "), _vm.pageLoading ? _c('div', {
    staticClass: "liLoading"
  }, [_c('Loading', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pageLoading,
      expression: "pageLoading"
    }],
    attrs: {
      "content": ""
    }
  })], 1) : _vm._e()])]), _vm._v(" "), _c('el-dialog', {
    staticClass: "delete-box",
    attrs: {
      "visible": _vm.delVisible,
      "show-close": false,
      "modal": "",
      "width": "30%",
      "center": true
    },
    on: {
      "update:visible": function ($event) {
        _vm.delVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', {
    staticClass: "el-icon-warning"
  }), _vm._v(" "), _c('span', [_vm._v("Select notification")])]), _vm._v(" "), _c('div', {
    staticClass: "delete-items"
  }, [_c('span', [_vm._v("\n        Which notification do you want to select?\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "tr mgr30",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.allSelect(0);
      }
    }
  }, [_vm._v("Current page")]), _vm._v(" "), _c('el-button', {
    staticClass: "move",
    on: {
      "click": function ($event) {
        return _vm.allSelect(1);
      }
    }
  }, [_vm._v("ALL notification")])], 1)]), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.delWrangVisible,
      "width": "558px",
      "modal-append-to-body": true,
      "append-to-body": true,
      "show-close": false,
      "z-index": 9999
    },
    on: {
      "update:visible": function ($event) {
        _vm.delWrangVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "delDialogTitle"
  }, [_c('i', {
    staticClass: "el-icon-warning warningLog"
  }), _vm._v(" "), _c('span', [_vm._v("Delete message")])]), _vm._v(" "), _c('div', {
    staticClass: "delDialogTxt"
  }, [_vm._v("Are you sure to delete message? You will not be able to undo this action.")]), _vm._v(" "), _c('div', {
    staticClass: "select-dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    staticClass: "btn-current",
    on: {
      "click": _vm.delCancle
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c('el-button', {
    staticClass: "btn-all",
    on: {
      "click": _vm.delSure
    }
  }, [_vm._v("Delete")])], 1)])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }