
export default ({ app, store }, inject) => {
  if(process.client) {
    // 创建一个全局的 iframe 容器
    const iframeContainer = document.createElement('div');
    iframeContainer.id = 'global-iframe-container';
    iframeContainer.style.width = '100%';
    iframeContainer.style.height = '100%';
    iframeContainer.style.display = 'none';
    document.body.appendChild(iframeContainer);
    
    // 创建并挂载一个全局的 iframe 元素
    const globalIframe = document.createElement('iframe');
    globalIframe.id = 'global-iframe';
    globalIframe.style.width = '100%';
    globalIframe.style.height = '100%';
    globalIframe.scrolling = 'none';
    globalIframe.frameBorder = '0';  // 注意这里的属性名大小写
    iframeContainer.appendChild(globalIframe);

    if (typeof window !== 'undefined' && 'Worker' in window) {
      // 检查是否已经存在 worker 实例，防止重复加载
      console.log('==== 创建一个新的 Web Worker 实例 ====');
      // 创建一个新的 Web Worker 实例
      const myWorker = new Worker('/occt_worker-bundle.js');
      window.myWorker = myWorker;

      window.myWorker.postMessage({ command: 'initOpenCascade' });
      window.myWorker.onmessage = (event) => {
        console.log('Received message from Web Worker:', event.data);
        const { status } = event.data;
        if (status === 'OpenCascade initialized') {
          store.commit('setGraphicsLibraryLoaded', true);
        }
        // 将 Worker 的消息转发给 iframe
        if (globalIframe.contentWindow) {
          globalIframe.contentWindow.postMessage({ type: 'fromWorker', payload: event.data }, '*');
        }
      }
      window.addEventListener('message', (event) => {
        // 接收来自 iframe 的消息并转发给 Worker
        if (event.data.type === 'fromIframe') {
          window.myWorker.postMessage(event.data.payload);
        }
      });
    }
    
    // 将全局 iframe 元素挂载到 Vue 原型链上，供所有组件使用
    app.$globalIframe = globalIframe;
  
    // 将全局 iframe 元素注入到 Vue 实例中
    inject('globalIframe', globalIframe);
  }
}

