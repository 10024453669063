var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.error.statusCode == 404 ? _c('div', {
    staticClass: "box-404"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": '404',
      "width": _vm.px2rem('400px'),
      "height": _vm.px2rem('244px')
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "font24 c252 fw5 tip"
  }, [_vm._v("Apologies, it seems that the page couldn't be found.")]), _vm._v(" "), _c('p', {
    staticClass: "font24 c252 fw5"
  }, [_vm._v("Please try again later.")]), _vm._v(" "), _c('el-button', {
    staticClass: "btn_primary",
    on: {
      "click": _vm.goHome
    }
  }, [_vm._v("Go Back Home")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }