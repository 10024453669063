<script>
export default {
  name: 'arrowRight',
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    fill: {
      type: String,
      default: '#252525'
    },
    activeColor: {
      type: String,
      default: '#252525'
    },
    disabledColor: {
      type: String,
      default: '#DEDEDE'
    },
    hover: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      fillColor: ''
    }
  },
  watch: {
    disabled: {
      handler(val) {
        if (val) {
          this.fillColor = this.disabledColor
        } else {
          this.fillColor = this.fill
        }
      },
      immediate: true
    }
  },
  render() {
    return (
      <svg
        width={this.width * this.$store.state.scale}
        height={this.height * this.$store.state.scale}
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        onClick={$event => this.onClick($event)}
        onMouseenter={e => this.onMouseenter(e)}
        onMouseleave={e => this.onMouseLeave(e)}
      >
        <path
          d="M11.0066 0C13.181 -4.24921e-09 15.3065 0.644764 17.1144 1.85276C18.9222 3.06075 20.3313 4.77772 21.1634 6.78654C21.9955 8.79536 22.2132 11.0058 21.789 13.1384C21.3648 15.2709 20.3178 17.2298 18.7803 18.7673C17.2428 20.3047 15.2839 21.3518 13.1514 21.776C11.0188 22.2002 8.80838 21.9825 6.79956 21.1504C4.79074 20.3183 3.07377 18.9092 1.86578 17.1013C0.657784 15.2934 0.0130215 13.1679 0.0130215 10.9936C0.0130215 8.07792 1.17127 5.28166 3.23297 3.21995C5.29468 1.15825 8.09094 5.69801e-09 11.0066 0V0ZM8.70564 15.2888C8.51517 15.4804 8.40826 15.7396 8.40826 16.0098C8.40826 16.2799 8.51517 16.5391 8.70564 16.7307C8.7997 16.8254 8.91167 16.9004 9.03501 16.9513C9.15836 17.0022 9.29062 17.0281 9.42406 17.0273C9.55901 17.0274 9.69264 17.0008 9.81726 16.949C9.94188 16.8973 10.055 16.8213 10.1502 16.7256L15.1203 11.7402C15.296 11.5463 15.3908 11.2925 15.3851 11.031C15.3794 10.7694 15.2736 10.52 15.0896 10.334L10.0428 5.27182C9.95038 5.16681 9.83743 5.08186 9.7109 5.02224C9.58438 4.96261 9.44696 4.92958 9.30716 4.92518C9.16736 4.92077 9.02814 4.94509 8.89811 4.99664C8.76809 5.04818 8.65001 5.12585 8.5512 5.22484C8.45238 5.32383 8.37492 5.44204 8.32361 5.57216C8.2723 5.70228 8.24822 5.84154 8.25287 5.98134C8.25752 6.12113 8.2908 6.25848 8.35065 6.3849C8.4105 6.51132 8.49564 6.62412 8.60082 6.71633L12.9318 10.9936L8.70564 15.2888Z"
          fill={this.fillColor}
        />
      </svg>
    )
  },
  methods: {
    onClick(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      this.$emit('on-click', e)
    },
    onMouseLeave(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      if (!this.hover) return
      this.fillColor = this.fill
    },
    onMouseenter(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      if (!this.hover) return
      this.fillColor = this.activeColor
    }
  }
}
</script>

<style></style>
