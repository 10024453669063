<template>
  <div class="address-win">
    <el-dialog 
      :visible.sync="$store.state.resetPassword" 
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
     <div class="dialog-win">
        <div class="dialog-left">
          <img :style="{width: '100%',height: '100%'}"  src="@/assets/images/forgotPassword_bg.svg" />
        </div>
        <div class="dialog-right">
          <div class="dialog-close" @click="close">
            <i class="el-icon-close"></i>
          </div>
        <div class="align-center" style="height:100%">
        <div class="login-content" v-if="!showLoginVisible">
          <div class="reset-title">
            <div>Reset password</div>
          </div>
        <el-form
          ref="ruleForm" 
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          label-position="left"
          :hide-required-asterisk="true"
        >
          <el-form-item prop="password" autocomplete="off" label="Password">
            <el-input
              v-model="ruleForm.password"
              ref="password1"
              :type="pwdType_password1"
              placeholder="Enter your password"
            >
            <template slot="suffix">
              <span class="showPassWord" @click.stop="changePwdType('password1')">
                <img class="eyeIcon" v-show="pwdType_password1=='text'" src="~assets/images/openEyes.svg">
                <img class="eyeIcon" v-show="pwdType_password1!=='text'" src="~assets/images/closeEyes.svg">
              </span>
            </template>
            </el-input>
            <template slot="error" slot-scope="scope">
              <span class="form__error_icon_tip">
                <i class="el-icon-error"></i>
                <span >{{ scope.error }}</span>
              </span>
            </template>
          </el-form-item>
          <div class="mgb20 passwordHint" v-show="!ruleForm.password || ruleForm.password.length == 0">
            <div class="passHint">
              <span class="redioSpan"></span>
              <span>At least 1 letter</span>
            </div>
            <div class="passHint">
              <span class="redioSpan"></span>
              <span>At least 1 number</span>
            </div>
            <div class="passHint">
              <span class="redioSpan"></span>
              <span>At least 8 characters</span>
            </div>
          </div>
          <div class="mgb20 passwordHint" v-show="ruleForm.password && ruleForm.password.length > 0">
            <div :class="passHint.hint1 ? 'passHint passHint-g' : 'passHint passHint-r'">
              <i v-if="passHint.hint1" class="el-icon-success"></i>
              <i v-else class="el-icon-error"></i>
              <span class="passHint-txt">At least 1 letter</span>
            </div>
            <div :class="passHint.hint2 ? 'passHint passHint-g' : 'passHint passHint-r'">
              <i v-if="passHint.hint2" class="el-icon-success"></i>
              <i v-else class="el-icon-error"></i>
              <span class="passHint-txt">At least 1 number</span>
            </div>
            <div :class="passHint.hint3 ? 'passHint passHint-g' : 'passHint passHint-r'">
              <i v-if="passHint.hint3" class="el-icon-success"></i>
              <i v-else class="el-icon-error"></i>
              <span class="passHint-txt">At least 8 characters</span>
            </div>
          </div>
          <el-form-item prop="rpassword" autocomplete="off" label="Confirm password">
            <el-input
              v-model="ruleForm.rpassword"
               ref="password2"
              :type="pwdType_password2"
              placeholder="Enter your password again"
            >
            <template slot="suffix">
              <span class="showPassWord" @click.stop="changePwdType('password2')">
                <img class="eyeIcon" v-show="pwdType_password2=='text'" src="~assets/images/openEyes.svg">
                <img class="eyeIcon" v-show="pwdType_password2!=='text'" src="~assets/images/closeEyes.svg">
              </span>
            </template>
            </el-input>
            <template slot="error" slot-scope="scope">
              <span class="form__error_icon_tip">
                <i class="el-icon-error"></i>
                <span >{{ scope.error }}</span>
              </span>
            </template>
          </el-form-item>
          <el-form-item>
            <div class="subBox">
              <el-button
                class="loginBtn"
                type="primary"
                size="large"
                :loading="reserLoading"
                @click="reserPassword('ruleForm')"
              >Submit</el-button>
            </div>
          </el-form-item>
        </el-form>
        </div>
        <div class="login-content" v-else>
            <div class="dialog-lo">
            <div class="login-title">
                Reset password
            </div>
            <div class="dialog-log-txt">
                <i class="el-icon-success" size="18"></i>
                 Your account password has been reset successfully.
            </div>
            <div class="footerBtnBox">
              <el-button
                class="homeBtn"
                @click="anewToHome"
              >Home</el-button>
              <el-button
                class="homeBtn"
                type="primary"
                @click="anewLogin"
              >Log In</el-button>
            </div>
          </div>
        </div>
        </div> 
      </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { px2rem } from '~/libs/tool'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  name: 'ResetPassword',
  data() {
    const validatePass = (rule, value, callback) => {
      const include = /^(?![^a-zA-Z]+$)(?!\D+$)/.test(value) // 至少一个数字和一个字母
      if (value === '') {
        callback(new Error('Please enter your password'))
      } else if (!include) {
        callback(
          new Error(
            'A minimum 8 characters is required and they should be a mixture of letters and numbers'
          )
        )
      } else if (value.length < 8) {
        callback(
          new Error(
            'A minimum 8 characters is required and they should be a mixture of letters and numbers'
          )
        )
      } else {
        if (this.ruleForm.rpassword !== '') {
          this.$refs.ruleForm.validateField('rpassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your password again'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('Your new and confirm password are different!'))
      } else {
        callback()
      }
    }
    return {
      reserLoading: false,
      ruleForm: {
        type: 'e',
        password: '',
        rpassword: '',
        user: ''
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        rpassword: [{ validator: validatePass2, trigger: 'blur' }]
      },
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0
      },
      passHint: {
        hint1: false,
        hint2: false,
        hint3: false
      },
      showLoginVisible: false,
      pwdType_password1:'password',
      pwdType_password2:'password',
    }
  },
  watch: {
    'ruleForm.password': function(val) {
      if (val) {
        this.passHint.hint1 = /^(?![^a-zA-Z]+$)/.test(val)
        this.passHint.hint2 = /\d+/.test(val)
        this.passHint.hint3 = val.length > 7
      } else {
        this.passHint = {
          hint1: false,
          hint2: false,
          hint3: false
        }
      }
    },
     '$store.state.resetPassword': function (val) {
        if(!val){
           this.$refs.ruleForm.resetFields()
           this.pwdType_password1 = 'password'
           this.pwdType_password2 = 'password'
        }
    },
  },
  methods: {
    px2rem,
     changePwdType(refName){
        this['pwdType_'+ refName] = this['pwdType_'+ refName] =='text'?'password':'text';
        this.$nextTick(()=>{
          this.$refs[refName].focus();
        })
    },
    reserPassword() {
      const that = this
      that.ruleForm.user = that.$store.state.resetPass.email
      if (that.ruleForm.user === '') {
        that.$notify.error({
          title: 'Error',
          customClass: 'notify-error',
          message: 'Please verify your account first'
        })
        return false
      }
      that.reserLoading = true
      that.$forgetPwd(that.ruleForm).then(res => {
        that.reserLoading = false
        if (res.code === 1) {
          that.showLoginVisible = true;
        } else {
          that.$notify.error({
            title: 'Error',
            customClass: 'notify-error',
            message: 'Your new and confirm password are different!'
          })
        }
      })
    },
    anewLogin() {
      let that = this;
      const data = {
        user: that.ruleForm.user,
        password: that.ruleForm.password
      }
      that.$login(data).then(res => {
        if (res.code === 1) {
          that.ruleForm.password = ''
          that.ruleForm.rpassword = ''
          that.$notify({
            title: 'Success',
            customClass: 'notify-success',
            type: 'success',
            message: 'Login successfully'
          })
          const auth = res.data.token
          const isAdd = res.data.address_id
          that.$store.commit('msg/setTeamName', res.data.team)
          that.$store.commit('setAuth', auth) // 存储在vuex中用来进行客户端渲染
          localStorage.setItem(
            'emailValidated',
            res.data.email_validated
          )
          const expires = new Date(new Date() * 1 + 86400000 * 30) // 有效期一个月
          Cookie.set('auth', auth, { expires: expires }) // 在cookie中保存token用来进行服务器端渲染
          Cookie.set('isadd', isAdd, { expires: expires })
          const checkQuote = res.data.check_quote_status
          that.$store.commit('checkQuote', checkQuote)
          Cookie.set('checkQuote', checkQuote, { expires: expires })
          that.getMsg()
          that.$store.commit('loginOk', '1') // 登录成功返回状态
          that.$getUser().then(res => {
            if (res.code === 1) {
              localStorage.setItem('userId', res.data.id)
              localStorage.setItem('mail', res.data.email)
              localStorage.setItem('email', res.data.email)
              localStorage.setItem('userName', res.data.userName)
              that.$store.commit('setAvatar', res.data.imageUrl)
              that.$store.commit('msg/setUserName', res.data.userName)
              that.$store.commit('msg/setEmail', res.data.email)
              that.$store.commit('msg/setFirstName', res.data.firstName)
              that.$store.commit('msg/setLastName', res.data.lastName)
              that.$store.dispatch('msg/getUrl')
              Cookie.set('avatar', res.data.imageUrl, {
                expires: expires,
                domain: that.$store.state.msg.url
              })
              Cookie.set('userName', res.data.userName, {
                expires: expires,
                domain: that.$store.state.msg.url
              });
            }
          })
          that.$router.push('/')
          window.setLayer({
            event: 'RDLoginSuccess',
            dimension1: 'login'
          })
          that.showLoginVisible = false;
          this.$store.commit('openResetPassword', false)
        } else {
          that.$notify.error({
            title: 'Error',
            customClass: 'notify-error',
            message: 'Incorrect user name or password'
          })
          that.showLoginVisible = false;
          this.$store.commit('openResetPassword', false)
        }
      });
    },
    anewToHome() {
      this.ruleForm.password = ''
      this.ruleForm.rpassword = ''
      this.$router.push('/')
      this.showLoginVisible = false;
      this.$store.commit('openResetPassword', false)
    },
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10
      }
      this.$getMsg(data).then(res => {
        this.$store.commit('msg/setUnreadNum', res.data.unread)
      })
    },
    close() {
      this.showLoginVisible = false;
      this.$store.commit('openResetPassword', false)
      let home = false
      const rt = this.$route.path
      rt === '/' ||
      rt === '/member/findPassword' ||
      rt === '/member/resetPassword'
        ? (home = false)
        : (home = true)
      if (home) {
        this.$router.push('/')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.address-win /deep/ .el-dialog__wrapper .el-dialog {
   width: 1196px;
   height: 720px;
   font-weight: 400;
   font-family: Poppins;
   font-style: normal;
}
.dialog-win {
  min-width: 1100px;
  background: #ffffff;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1013;
  display: flex;
  align-items: center;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  text-align: center;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.dialog-left{
  width: 576px;
  height: 720px;
}
.dialog-right{
   width: 620px;
   display: flex;
   align-items: center;
   justify-content: center;
  .login-content{
    width: 500px;
    margin: 0 60px;
  }
  .reset-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  color: #252525;
  text-align: center;
  margin: 60px 0 40px;
  padding: 0 40px;
}
}
.demo-ruleForm {
  overflow: hidden;
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #252525;
    padding-bottom: 8px;
    &:after {
          content: '*';
          color: #F56C6C;
          margin-left: 0.02083rem;
      }
  }
  /deep/ .el-form-item__content{
    line-height: normal;
  }
  .el-form-item {
    // margin-bottom: 8px;
  }
  /deep/ .el-input__inner {
    height: 38px;
    line-height: 38px;
    color: #252525 !important;
  }
}
.loginBtn {
  font-size: 14px;
  width: 182px;
  height: 39px;
  font-weight: bold;
}

.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
  color: #EA543F;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.passwordHint {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 22px;
  color: #878787;
  .passHint {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  .passHint-g {
    color: #00A870;
  }
  .passHint-r {
    color: #EA543F;
  }
  .passHint-txt {
    margin-left: 6px;
  }
  .redioSpan {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #D0D0D0;
    border-radius: 50% ;
    margin-right: 6px;
  }
}
.subBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
.dialog-lo {
  .login-title {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    color: #252525;
    margin-bottom: 40px;
  }
  .dialog-log-txt {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #252525;
    margin-bottom: 40px;
    color: #00A870;
  }
  .footerBtnBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .homeBtn {
    display: inline-flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
.showPassWord{
  line-height: 38px;
  padding:0 3px;
  width: 18px;
  height: 20px;
  cursor: pointer;
  .eyeIcon{
   width: 18px;
   height: 20px; 
  }
}
</style>
