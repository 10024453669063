<script>
export default {
  name:'arrowLeft',
  props:{
    width:{
      type:Number,
      default:0
    },
    height:{
      type:Number,
      default:0
    },
    fill:{
      type:String,
      default:'#252525'
    },
    activeColor:{
      type:String,
      default:'#252525'
    },
    disabledColor:{
      type:String,
      default:'#DEDEDE'
    },
    hover:Boolean,
    disabled:Boolean
  },
  data(){
    return {
      fillColor:''
    }
  },
  watch:{
    disabled:{
      handler(val){
        if(val){
          this.fillColor = this.disabledColor
        }else{
          this.fillColor = this.fill
        }
      },
      immediate:true
    }
  },
  render(){
    return (
      <svg 
        width={this.width * this.$store.state.scale} 
        height={this.height * this.$store.state.scale} 
        viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"
        onClick={($event)=>this.onClick($event)} onMouseenter={(e)=>this.onMouseenter(e)} onMouseleave={(e)=>this.onMouseLeave(e)}
        >
        <path
          d="M10.9934 0C8.81904 -4.24921e-09 6.69354 0.644764 4.88565 1.85276C3.07776 3.06075 1.66868 4.77772 0.836599 6.78654C0.00451925 8.79536 -0.213191 11.0058 0.211 13.1384C0.635191 15.2709 1.68223 17.2298 3.21971 18.7673C4.7572 20.3047 6.71607 21.3518 8.84862 21.776C10.9812 22.2002 13.1916 21.9825 15.2004 21.1504C17.2093 20.3183 18.9262 18.9092 20.1342 17.1013C21.3422 15.2934 21.987 13.1679 21.987 10.9936C21.987 8.07792 20.8287 5.28166 18.767 3.21995C16.7053 1.15825 13.9091 5.69801e-09 10.9934 0V0ZM13.2944 15.2888C13.4848 15.4804 13.5917 15.7396 13.5917 16.0098C13.5917 16.2799 13.4848 16.5391 13.2944 16.7307C13.2003 16.8254 13.0883 16.9004 12.965 16.9513C12.8416 17.0022 12.7094 17.0281 12.5759 17.0273C12.441 17.0274 12.3074 17.0008 12.1827 16.949C12.0581 16.8973 11.945 16.8213 11.8498 16.7256L6.87971 11.7402C6.70399 11.5463 6.60924 11.2925 6.61494 11.031C6.62065 10.7694 6.72638 10.52 6.9104 10.334L11.9572 5.27182C12.0496 5.16681 12.1626 5.08186 12.2891 5.02224C12.4156 4.96261 12.553 4.92958 12.6928 4.92518C12.8326 4.92077 12.9719 4.94509 13.1019 4.99664C13.2319 5.04818 13.35 5.12585 13.4488 5.22484C13.5476 5.32383 13.6251 5.44204 13.6764 5.57216C13.7277 5.70228 13.7518 5.84154 13.7471 5.98134C13.7425 6.12113 13.7092 6.25848 13.6494 6.3849C13.5895 6.51132 13.5044 6.62412 13.3992 6.71633L9.06821 10.9936L13.2944 15.2888Z" fill={this.fillColor} />
        </svg>
    )
  },
  methods:{
    onClick(e){
      if(this.disabled) return (this.fillColor = this.disabledColor)
      this.$emit('on-click',e)
    },
    onMouseLeave(e){
      if(this.disabled) return (this.fillColor = this.disabledColor)
      if(!this.hover) return
      this.fillColor = this.fill
    },
    onMouseenter(e){
      if(this.disabled) return (this.fillColor = this.disabledColor)
      if(!this.hover) return
      this.fillColor = this.activeColor
    }
  }
}
</script>

<style>

</style>