export default function({ isHMR, app, store, route, redirect }) {
  if (route.path == '/') {
    let path = store.state.indexRoute
    if (!store.state.indexRouters.includes(route.path)) {
      path = '/'
    }
    if (path != '/') {
      return redirect(path)
    }
  }
  if (route.path === '/a' || route.path === '/quote/rfq-2') {
    redirect('/404')
  }
}
