var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "createTeam-win"
  }, [_c('el-dialog', {
    attrs: {
      "close-on-click-modal": false,
      "visible": _vm.createTeamDialogVisible,
      "title": "Create a team",
      "width": _vm.px2rem('580px')
    },
    on: {
      "update:visible": function ($event) {
        _vm.createTeamDialogVisible = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "dialog-win"
  }, [_c('el-form', {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules,
      "hide-required-asterisk": true,
      "label-position": "top"
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "team_name",
      "label": "Create a team to share quotes & orders with your colleagues"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Enter your team name"
    },
    model: {
      value: _vm.ruleForm.team_name,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "team_name", $$v);
      },
      expression: "ruleForm.team_name"
    }
  })], 1), _vm._v(" "), _c('el-form-item', [_c('div', {
    staticClass: "l_h22 fwn"
  }, [_vm._v("Note: You can always change your team name later.")])]), _vm._v(" "), _c('el-form-item', {
    staticClass: "footerFormItem"
  }, [_c('div', {
    staticClass: "loginBtnBox"
  }, [_c('el-button', {
    staticClass: "btn_style",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c('el-button', {
    staticClass: "btn_style",
    attrs: {
      "type": "primary",
      "loading": _vm.loading,
      "disabled": !_vm.ruleForm.team_name.trim()
    },
    on: {
      "click": function ($event) {
        return _vm.Submit('ruleForm');
      }
    }
  }, [_vm._v("Create team")])], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }