import jsPDF from "jspdf";
// import logo from "./logo-img.png";
import logo from "./logo.png";
import home from "./DFM_home.png"
import end from "./DFM_end.png"
import centerBJ from "./DFM-center.png"
import dayjs from "dayjs";
// import font from '@/assets/fonts/SourceHanSans-Normal.ttf'
import baseBoldFont from "./chinaFont/baseBoldFont";
// import baseFont from "./chinaFont/baseFont";
import fangzhengfangsong from "./chinaFont/fangzhengfangsong-normal";
// import heiti from "./chinaFont/heiti-normal";
import simhei from "./chinaFont/SIMHEI-normal";
import arial from "./chinaFont/Arial-normal"
import arialBlack from "./chinaFont/ArialBlack-normal"
import { multilineTexts, format, generateRandomString, splitTextToFit } from "./utils";
class PI {
  constructor({
    request,
    ossUrl,
    saveType = "document",
    language = "en",
    quote_no = "",
    dfm_version = "",
    dfm_send_time = ""
  }) {
    this.request = request;
    this.ossUrl = ossUrl;
    // 当前页面位置
    this.currentPage = 1;
    // 总页面
    this.total = 1;
    // 开头x轴
    this.baseX = 10;
    // 记录页面所在Y轴
    this.currentY = 0;
    //
    this.maxPageY = 280;
    this.doc = new jsPDF('l');
    this.saveType = saveType || "document";
    this.language = language;
    this.quote_no = quote_no;
    this.dfm_version = dfm_version;
    this.dfm_send_time = dfm_send_time?dayjs.unix(dfm_send_time).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY');
    this.logoImgData = null
    this.centerImgData = null
  }
  // 字符串分割指定长度的数组
  splitStr(str, length) {
    const arr = [];

    let index = 0;
    while (index < str.length) {
      arr.push(str.slice(index, (index += length)));
    }

    return arr;
  }

  getImgCanvas(url, bg = "#ffffff") {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.style.backgroundColor = bg;

      img.onload = (e) => {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        ctx.fillStyle = bg;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
        resolve({
          img: canvas.toDataURL("image/jpeg"),
          msg: {
            ratio: imgWidth / imgHeight,
            imgWidth,
            imgHeight
          }
        });
      };
    });
  }
  // 画框
  async writeRect(part,text) {
    const pageWidth = this.doc.internal.pageSize.getWidth();
    // 左右边距和固定高度
    const leftMargin = 10;
    const rightMargin = 10;

    const firstLineLeftMargin = this.language == 'ch'? 40 : 35; // 第一行前面留出的固定宽度
    const fixedHeight = 50; // 固定高度
    // 计算可用宽度
    const contentWidth = pageWidth - leftMargin - rightMargin;

    // 计算框的坐标
    // const x = leftMargin;
    // const y = this.currentY; // 垂直居中
    const boxHeight = fixedHeight;
    const boxWidth = contentWidth;

    // 分割文字，使其适合每页显示
    this.language == 'ch'? this.doc.setFont("simhei"): this.doc.setFont("arial");
    const textPages = splitTextToFit(this.doc, text, boxWidth, boxHeight, this.language);
    const imgPath = await fetch(this.ossUrl + part.file_url).then((res) => {
      if (res.status === 200) {
        return res.blob();
      } else {
        return Promise.reject(res);
      }
    });
    const imgBase64 = await this.blobToDataURI(imgPath);
    const imgUrl = await this.getImgCanvas(imgBase64);
    // 设置背景图
    textPages.forEach(async (boxText, boxIndex) => {
      if (boxIndex > 0) {
        this.appendNewPage()
      }
      this.writePartName(part)
      // 绘制方框
      this.doc.rect(leftMargin, this.currentY + 10, boxWidth, boxHeight);
  
      // 设置文字样式和内容
      this.doc.setTextColor(0, 0, 0);
      this.language == 'ch'? this.doc.setFont("simhei"): this.doc.setFont("arial");
      const lines = boxText.split('\n');
      lines.forEach((line, index) => {
        const y = boxIndex? this.currentY + leftMargin + 8 + index * this.doc.internal.getLineHeight() / this.doc.internal.scaleFactor : this.currentY + leftMargin + 8 + index * this.doc.internal.getLineHeight() / this.doc.internal.scaleFactor;
        const x = index === 0 ? firstLineLeftMargin : leftMargin + 5;
        this.doc.text(line, x, y);
      });
      
      this.writePartImg(imgUrl)
    });
  }
  //编写首页
  async writeHome() {
    try {
      //获取文档的宽度
      const docWidth = this.doc.internal.pageSize.getWidth();
      const docHieght = this.doc.internal.pageSize.getHeight()
      // 设置背景图
      const { img } = await this.getImgCanvas(home)
      this.doc.addImage(img, 0, 0, docWidth, docHieght, "home", "NONE");
      // const { img } = await this.getImgCanvas(logo);
      // this.doc.addImage(img, (docWidth - 60) / 2, 100, 60, 18, "logo", "NONE");
      // this.currentY += 100;
      this.currentY = 22;
      this.doc.setTextColor(234, 84, 63);
      this.doc.setFont("BaseBoldFont", "", 400);
      this.doc.setFontSize(28);

      const text_en = "Manufacturability Analysis Report";
      this.doc.text(text_en, this.baseX + 10, (this.currentY += 30), {
        align: "left"
      });

      this.doc.setFont("fangzhengfangsong", "", 400);
      this.doc.setFontSize(18);
      this.doc.setTextColor(0, 0, 0);
      this.doc.text('■', this.baseX + 10, (this.currentY + 15), {
        align: "left"
      });
      this.doc.text('■', this.baseX + 10, (this.currentY + 25), {
        align: "left"
      });

      this.doc.setFont("BaseBoldFont", "", 400);
      this.doc.text(this.quote_no, this.baseX + 18, (this.currentY += 15), {
        align: "left"
      });
      let reportDate = this.dfm_send_time + ' V' + this.dfm_version;
      this.doc.text(reportDate, this.baseX + 18, (this.currentY += 10), {
        align: "left"
      });

      this.doc.setFont("arial");
      this.doc.setFontSize(16);
      this.doc.setTextColor(18, 35, 65);
      const linkText = "www.rapiddirect.com";
      const textWidth = this.doc.getStringUnitWidth(linkText) * this.doc.internal.getFontSize() / this.doc.internal.scaleFactor;
      let rightX = this.doc.internal.pageSize.getWidth() - textWidth -10;
      let rightY = this.doc.internal.pageSize.getHeight() - 5;
      this.doc.setFontSize(16);
      this.doc.textWithLink(
        linkText,
        rightX,
        rightY,
        {
          align: "left"
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  // 写顶部相关信息
  async writeTop(isPosition) {
    try {
      const { img } = this.logoImgData;
      let imgX = isPosition == 'left'? this.baseX : this.doc.internal.pageSize.getWidth() - 40 - this.baseX
      this.doc.addImage(img, imgX, 3, 40, 14, "logo", "NONE");
      // this.doc.setTextColor(0, 0, 0);
      // this.doc.setFont("BaseBoldFont", "", 400);

      // this.doc.setFontSize(16);
      // this.doc.text("Design for Manufacturability Report", 75, 15, {
      //   align: "left"
      // });
      // this.doc.setDrawColor(255, 0, 0);
      // //获取文档的宽度
      // const docWidth = this.doc.internal.pageSize.getWidth();
      // this.doc.line(0, 22, docWidth, 22);
      return Promise.resolve();
    } catch (error) {
      console.log(error);
    }
  }
  async writePartName(part) {
    const docWidth = this.doc.internal.pageSize.getWidth();
    this.doc.setFontSize(14);
    let labelName = ''
    if (this.language == "ch") {
      this.doc.setFont("simhei", "simhei", 400);
      labelName = '零件名称：'
    } else {
      this.doc.setFont("arialBlack");
      labelName = 'Part Name: '
    }
    const contentWidth = docWidth - this.baseX * 2;
    this.doc.rect(this.baseX, this.currentY += 10, contentWidth, 15);
    this.doc.setTextColor(234, 84, 63)
    this.doc.text(
      labelName,
      this.baseX + 5,
      this.currentY += 10
    );
    //获取零件标题宽度
    const nameLable = this.doc.getTextWidth(
      labelName
    );
    //设置零件名称
    this.doc.setFontSize(14);
    this.doc.setTextColor(0, 0, 0)
    if (this.language == "ch") {
      this.doc.setFont("simhei", "simhei", 400);
    } else {
      this.doc.setFont("arial");
    }
    const lineHeigh = 3; //多行文本的行高
    this.doc.text(
      part.part_name,
      this.baseX + 5 + nameLable,
      this.currentY,
    )
    let detailName = '';
    if (this.language == "ch") {
      this.doc.setFont("simhei", "simhei", 400);
      detailName = '反馈细节:'
    } else {
      this.doc.setFont("arialBlack");
      detailName = 'Detail:'
    }
    this.doc.setTextColor(234, 84, 63)
    this.doc.text(
      detailName,
      this.baseX + 5,
      this.currentY + 18
    );
  }
  async writePartImg(imgUrl) {
    const docWidth = this.doc.internal.pageSize.getWidth();
    // 设置背景色
    const backgroundColor = '#EBEBEB'; // 背景色，示例为浅橙色
    // 设置透明度
    this.doc.setGState(new this.doc.GState({opacity: 0.6}));
    // 设置长方形的尺寸和位置
    const paddingWidth = 10;
    const rectWidth = docWidth - paddingWidth*2;
    const rectHeight = 100;
    const rectX = paddingWidth;
    const rectY = this.currentY + 65;

    // 设置背景色并绘制长方形
    this.doc.setFillColor(backgroundColor);
    this.doc.rect(rectX, rectY, rectWidth, rectHeight, 'F'); // 'F' 表示填充
    // 重置透明度
    this.doc.setGState(new this.doc.GState({opacity: 1}));
    
    this.doc.setFontSize(14);
    // this.doc.setFont("arialBlack");
    this.doc.setTextColor(234, 84, 63)
    this.language == 'ch'? this.doc.setFont('simhei') : this.doc.setFont('arialBlack')
    this.doc.text('Image:', rectX + 5, rectY + 8);
    this.doc.setTextColor(0, 0, 0);

    // 图片在长方形中的高度为长方形高度，宽度按比例计算
    let imgDisplayHeight = rectHeight;
    let imgDisplayWidth = imgDisplayHeight * imgUrl.msg.ratio;
    let imgY = rectY;
    // 如果图片宽度超过长方形宽度，则按长方形宽度调整
    if (imgDisplayWidth > rectWidth) {
      imgDisplayWidth = rectWidth * 0.85; // 将图片宽度调整为长方形宽度的80%
      imgDisplayHeight = imgDisplayWidth / imgUrl.msg.ratio; // 按比例调整高度
      imgY = rectY + 11;
    }

    // 计算图片在长方形中的位置，居中对齐
    const imgX = rectX + (rectWidth - imgDisplayWidth) / 2;

    // 绘制图片
    this.doc.addImage(imgUrl.img, 'JPEG', imgX, imgY, imgDisplayWidth, imgDisplayHeight);
  }
  async writePart(index, part, isLast) {
    const docWidth = this.doc.internal.pageSize.getWidth();
    //设置注释说明
    this.doc.setFontSize(14);
    if (this.language == "ch") {
      this.doc.setFont("fangzhengfangsong", "fangzhengfangsong", 400);
    } else {
      this.doc.setFont("arial");
    }
    this.doc.setTextColor(0,0,0)
    const detailText = this.language == "ch" ? part.content_ch : part.content_en;
    await this.writeRect(part,detailText)
    if (!isLast) await this.appendNewPage();
  }
  // 写底部页脚
  async writeFooter(cb) {
    cb();
  }
  async writeData() {
    const res = await this.request().catch(() => {});
    if(!res.data.length) Promise.reject();
    let make_dfm_content = [];
    for (let index = 0; index < res.data.length; index++) {
      const part = res.data[index];
      if (part.make_dfm_content && part.make_dfm_content.length) {
        const arr = part.make_dfm_content.map((item) => {
          return {
            ...item,
            part_name: part.part_name,
            en_dfm: false
          };
        });
        make_dfm_content = make_dfm_content.concat(arr);
      }
    }
    for (let index = 0; index < make_dfm_content.length; index++) {
      const content = make_dfm_content[index];
      await this.writePart(
        index,
        content,
        make_dfm_content.length - 1 == index
      );
    }
    return Promise.resolve(res);
  }
  async writeEnd() {
    this.doc.addPage("a4", "l");
    this.currentY = 10;
    this.currentPage += 1;
    this.total += 1;
    try {
      //获取文档的宽度
      const docWidth = this.doc.internal.pageSize.getWidth();
      const docHieght = this.doc.internal.pageSize.getHeight()
      // 设置背景图
      const { img } = await this.getImgCanvas(end)
      this.doc.addImage(img, 0, 0, docWidth, docHieght, "end", "NONE");
      // 设置右侧logo
      await this.writeTop('right')
      this.currentY += 60;
      this.doc.setTextColor(18, 35, 65);
      this.doc.setFont("arialBlack");
      this.doc.setFontSize(50);
      
      const text_en = "End.";
      this.doc.text(text_en, docWidth/2 + 30, this.currentY + 6, {
        align: "left"
      });

      this.doc.setFont("arial");
      const linkText = "www.rapiddirect.com";
      let rightY = docHieght - 4;
      this.doc.setFontSize(16);
      this.doc.textWithLink(
        linkText,
        this.baseX,
        rightY,
        {
          align: "left"
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  async renderData(res) {}
  // 添加新页面，并把y轴位置初始化
  async appendNewPage() {
    const { img: center, msg } = this.centerImgData;
    this.doc.addPage("a4", "l");
    this.currentY = 10;
    this.currentPage += 1;
    this.total += 1;

    const docWidth = this.doc.internal.pageSize.getWidth();
    const docHieght = this.doc.internal.pageSize.getHeight();
    const imgDisplayHeight = docHieght;
    const imgDisplayWidth = imgDisplayHeight * msg.ratio;
    const imgX = (docWidth - imgDisplayWidth) / 2
    this.doc.addImage(center, 'JPEG', imgX, 0, imgDisplayWidth, imgDisplayHeight);
    
    const logoImgDisplayHeight = 5;
    const logoImgDisplayWidth = logoImgDisplayHeight * this.logoImgData.msg.ratio;
    this.doc.addImage(this.logoImgData.img, docWidth - 25, docHieght - 8, logoImgDisplayWidth, logoImgDisplayHeight);

    this.doc.setFont("arial");
    this.doc.setFontSize(24);
    // 计算文本的宽度
    this.doc.setTextColor(31,47,76)
    const text_en = "Manufacturability Analysis Report";
    this.doc.text(text_en, this.baseX, this.currentY, {
      align: "left"
    });

    this.doc.setDrawColor(207,207,207)
    this.doc.line(this.baseX, this.currentY + 4, 110, this.currentY + 4);
    this.doc.line(this.baseX, docHieght - 10, docWidth - 10, docHieght - 10);
    this.doc.setDrawColor(0,0,0)

    this.doc.setTextColor(9,26,58)
    const companyInfor = `Copyright © ${dayjs().year()} Shenzhen Rapid Direct Co.，Ltd. All rights reserved.`
    this.doc.setFontSize(10);
    this.doc.text(companyInfor, this.baseX, docHieght - 4, {
      align: "left"
    });
    // await this.writeTop();

    return Promise.resolve();
  }
  //  二进制转base64
  blobToDataURI(blob) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = function (e) {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async run() {
    return new Promise(async (resolve, reject) => {
      //请求数据
      const { data } = await this.request();
      //设置字体
      this.doc.addFileToVFS("BaseBoldFont.ttf", baseBoldFont);
      this.doc.addFont("BaseBoldFont.ttf", "BaseBoldFont", "normal");

      // this.doc.addFileToVFS("BaseFont.ttf", baseFont);
      // this.doc.addFont("BaseFont.ttf", "BaseFont", "normal");

      this.doc.addFileToVFS("fangzhengfangsong", fangzhengfangsong);
      this.doc.addFont("fangzhengfangsong", "fangzhengfangsong", "normal");
      this.doc.addFileToVFS("simhei", simhei);
      this.doc.addFont("simhei", "simhei", "normal");

      this.doc.addFileToVFS("arial", arial);
      this.doc.addFont("arial", "arial", "normal");

      this.doc.addFileToVFS("arialBlack", arialBlack);
      this.doc.addFont("arialBlack", "arialBlack", "normal");

      this.data = data;

      this.centerImgData = await this.getImgCanvas(centerBJ)
      this.logoImgData = await this.getImgCanvas(logo);

      await this.writeHome();
      await this.writeTop('left');
      this.appendNewPage();
      await this.writeData();
      await this.writeEnd();
      this.currentY += 30;
      this.writeFooter(() => {
        if (this.saveType == "dataUrl") {
          const pdfBlob = this.doc.output("blob");
          resolve(pdfBlob);
        } else {
          this.doc.save(`dfm.pdf`);
        }
      });
    });
  }
}

export default PI;
