<template>
  <div class="bigBox" v-loading="$store.state.chatLog.pageLoading">
    <div class="relationDiv" v-if="roomType && roomType.room_type!= 4" @click="openNewDetailPage(roomType)">
      <img  class="relationImg" v-show=" roomType && roomType.room_type == 1" src="~assets/images/quoteSvg.svg" alt="">
      <img  class="relationImg" v-show=" roomType && roomType.room_type == 2" src="~assets/images/orderSvg.svg" alt="">
      <span v-show="roomType.room_type == 1">{{roomType.relation_name ? `Quote No: ${roomType.relation_name}` : ''}}</span>
      <span v-show="roomType.room_type == 2">{{roomType.relation_name ? `Order No: ${roomType.relation_name}` : ''}}</span>
    </div>
    <div class="chatMain" id="chatMain" @scroll="scrollChange">
      <div class="chatMain-Div" id="chatMainDiv">
        <div
          v-for="(item) in theMessageList"
          :key="item.id"
          class="msgDiv"
          :style="item.is_sender === 1 ? {display: 'flex',justifyContent: 'flex-end'} : {}"
        >
          <div class="listDateTitle" v-if="item.showDateTiele">
            {{ item.showDateTiele }}
          </div>
          <!-- 对方 -->
          <div class="senderBox" v-else-if="item.is_sender === 0">
            <div class="userImg">
              <img :style="imgStyle" :src="imgUrl + accInfo.headImg" v-if="accInfo.headImg">
              <img :style="imgStyle" src='~assets/images/defaultHead.png' v-else>
            </div>
            <div class="nameAndCon">
              <div class="nickname">{{item.nickname}}</div>
              <div class="msgContent">
                <p class="msgText" v-if="item.content">{{item.content | escape}}</p>
                <div class="selfMsgImg" v-else-if="item.talk_records_file.length && item.talk_records_file[0].file_type && item.talk_records_file[0].file_type == 6">
                  <img class="img" @click="onLookImg(item.talk_records_file)" :src="imgUrl + item.talk_records_file[0].file_url" @load="imageLoad">
                </div>
                <div 
                  class="msgText msgFile" 
                  v-else-if="item.talk_records_file.length && item.talk_records_file[0].file_type"
                  @click="dowloadFile(item)"
                >
                  <div><img src="~assets/images/sendFileLog.svg" ></div>
                  <div class="fileInfo">
                    <div class="fileName">{{item.talk_records_file[0].file_name}}</div>
                    <div class="fileSize">{{getSize(item.talk_records_file[0].file_size)}}</div>
                  </div>
                </div>
                <div class="msgFile msgText" v-else>
                  <div><img src="~assets/images/sendFileLog.svg"></div>
                  <div class="fileInfo">
                    <div class="fileName"></div>
                  </div>
                </div>
                <div class="createTime">{{transTimeType(item.create_time)}}</div>
              </div>
            </div>
          </div>
          <!-- 自己 -->
          <div class="selfBox" v-else-if="item.is_sender === 1">
            <div class="selfMsgBox">
              <div class="createTime">{{transTimeType(item.create_time)}}</div>
              <div class="selfContent">
                <p class="selfMsgTxt" v-if="item.content">{{item.content | escape}}</p>
                <div class="quoteInfo" v-else-if="item.extra_params && chatMessage.room_type == 1" @click="openNewDetailPage({relation_name:item.extra_params.no,room_type: 1})">
                  <div class="quoteTitle">Quote information:</div>
                  <div class="quoteNo">
                    <div>Quote No: {{item.extra_params.no}}</div>
                    <div>Create time: {{transTimeType(item.extra_params.create_time,'1')}}</div>
                  </div>
                </div>
                <div class="quoteInfo" v-else-if="item.extra_params && chatMessage.room_type == 2" @click="openNewDetailPage({relation_name:item.extra_params.no,room_type: 2})">
                  <div class="quoteTitle">Order information:</div>
                  <div class="quoteNo">
                    <div>Order No: {{item.extra_params.no}}</div>
                    <div>Create time: {{transTimeType(item.extra_params.create_time,'1')}}</div>
                  </div>
                </div>
                <div class="selfMsgImg" v-else-if="item.talk_records_file.length && item.talk_records_file[0].file_type && item.talk_records_file[0].file_type == 6">
                  <img class="img" @click="onLookImg(item.talk_records_file)" :src="imgUrl + item.talk_records_file[0].file_url"  @load="imageLoad">
                </div>
                <div class="msgFile" v-else-if="item.talk_records_file.length" @click="dowloadFile(item)">
                  <div><img src="~assets/images/sendFileLog.svg"></div>
                  <div class="fileInfo">
                    <div class="fileName">{{item.talk_records_file[0].file_name}}</div>
                    <div class="fileSize">{{getSize(item.talk_records_file[0].file_size)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="netErr" v-show="networkBreak">
      <div class="netError">
        <img src="~assets/images/networkWar.svg" alt="">
        <span>
          Network error, please check the connection and try again.
        </span>
      </div>
    </div>
    <!-- 文件列表 -->
    <div
      v-show="uploadFileList.length > 0" 
      class="fileList"
      :style="drop ? {background: 'opacity'} : {}"
    >
      <div class="fileListDiv" :style="drop ? {boxShadow: 'none',overflow: 'hidden'} : {}">
        <div :style="drop ? {borderRadius: '8px 8px 0 0'} : {borderRadius: '0 0 8px 8px'}" class="drop" @click="drop = !drop" v-show="['1','3','4'].includes(uploadStatus)">
          <i :class="drop ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
        </div>
        <div :style="drop ? {borderRadius: '8px 8px 0 0'} : {borderRadius: '0 0 8px 8px'}" class="drop dropHover dropLoading" v-show="uploadStatus == '2'" @click="drop = !drop">
          <i class="el-icon-loading hoverClose"></i>
          <i :class="drop ? 'el-icon-arrow-up hoverOpen' : 'el-icon-arrow-down hoverOpen'"></i>
        </div>
        <!-- <div :style="drop ? {borderRadius: '8px 8px 0 0'} : {borderRadius: '0 0 8px 8px'}" class="drop dropHover" v-show="uploadStatus == '3'" @click="drop = !drop">
          <img class="hoverClose" src="~assets/images/uploadFile.svg" alt="">
          <i :class="drop ? 'el-icon-arrow-up hoverOpen' : 'el-icon-arrow-down hoverOpen'"></i>
        </div>
        <div :style="drop ? {borderRadius: '8px 8px 0 0'} : {borderRadius: '0 0 8px 8px'}" class="drop dropHover" v-show="uploadStatus == '4'" @click="drop = !drop">
          <img class="hoverClose" src="~assets/images/uploadSuccess.svg" alt="">
          <i :class="drop ? 'el-icon-arrow-up hoverOpen' : 'el-icon-arrow-down hoverOpen'"></i>
        </div> -->
        <div
          class="file"
          :class="{'file-failed': item.status == 'failed'}"
          v-for="(item,index) in uploadFileList"
          v-show="!drop"
          :key="item.uid"
        >
          <div class="fileCon">
            <div class="top-left">
              <span class="top-left-span" v-if="item.coverFile">
                <img class="fileImg" :src="item.coverFile" :style="footerImgStyle(item.coverFile)" alt srcset />
              </span>
              <img class="fileImg" v-else style="border: 0" src="~assets/images/chatTxt.svg" alt />
              <div class="top-fileTex">
                <span :class="item.status !== 'failed' ? 'top-fileTex-text' : 'top-fileTex-text uploadFail'">{{ item.file.name }}</span>
                <span :class="item.status !== 'failed' ? 'size' : 'size uploadFail'">
                  {{getSize(item.file.size)}}
                </span>
              </div>
            </div>
            <div class="top-right">
              <span class="retry" @click="uploadRetry(item)">
                <svg-icon icon-class="reset" :width="px2rem('16px')" :height="px2rem('16px')" color="#878787" ></svg-icon>
              </span>
              <span v-if="item.status == 'ready'" class="pencent">{{ item.progess }}%</span>
              <span v-else-if="item.status == 'success'" class="pencent">
                <svg-icon icon-class="success" :width="px2rem('19px')" :height="px2rem('19px')" color="#15A759"></svg-icon>
              </span>
              <span v-else-if="item.status == 'failed'" class="pencent">
                <svg-icon icon-class="gantanhao" :width="px2rem('16px')" :height="px2rem('16px')" color="#ED7B2F"></svg-icon>
              </span>
              <span class="md-close" @click="deleteFile(item,index)">
                <svg-icon icon-class="delete" color="#878787" :width="px2rem('16px')" :height="px2rem('16px')"></svg-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)
import { downFile,debounce,px2rem } from '~/libs/tool'
  export default {
    props: {
      chatMessage: {
        type: Object,
        default: {list:[],count: 0,room_type: 4}
      },
      uploadFileList: {
        type: Array,
        default: []
      },
      accInfo: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        theMessageList: [],
        imgUrl: null,
        imgStyle: {width: '100%',height: 'auto'},
        drop: false,
        loading: false,
        scrollBottom: 0,
        uploadStatus: '1',
        fileType: {
          1: '3D模型',
          2: 'pdf',
          3: 'excel',
          4: 'word',
          5: 'ppt',
          6: '图片',
          7: '其它'
        }
      }
    },
    computed: {
      showChatWindow: function() {
        return this.$store.state.chatLog.showChatWindow;
      },
      roomType: function() {
        return this.$store.state.chatLog.chatRoomType;
      },
      // 网络
      networkBreak: function() {
        return this.$store.state.chatLog.networkBreak;
      }
    },
    watch: {
      '$store.state.chatLog.pageLoading': function (e) {
        if(e) {
          this.scrollBottom = 0;
        }
      },
      showChatWindow(val) {
        if(val) {
          this.toScrollHeight()
        }
      },
      chatMessage:{ 
        handler(val) {
          if(val && val.list){
            this.getMessageDayTitle(val)
          };
          if(val) {
            setTimeout(() => {
              this.toScrollHeight()
            },500)
            // this.$nextTick(() => {
            //   this.toScrollHeight()
            // })
          }
        },
        deep: true,
        immediate: true
      },
      accInfo(val) {
        let img = new Image();
        img.src = this.imgUrl + val.headImg;
        let style
        if(img.width >= img.height) {
          style = {width: 'auto', height: '100%'};
        } else {
          style = {width: '100%', height: 'auto'};
        }
        this.imgStyle = style;
      },
      uploadFileList: {
        handler(val) {
          if(val && val.length > 0) {
            let status = val.map(e => e.status);
            if(status.indexOf('ready') >= 0) {
              this.uploadStatus = '2'
            } else if(status.indexOf('ready') == -1 && status.indexOf('failed') >= 0) {
              this.uploadStatus = '3'
            } else {
              this.uploadStatus = '4'
            }
          } else {
            this.uploadStatus = '1'
          }
        },
        deep: true,
        immediate: true
      }
    },
    mounted() {
      this.getImgUrl();
      // this.toScrollHeight();
    },
    methods: {
      px2rem,
      footerImgStyle(imgSrc) {
        let img = new Image();
        img.src = imgSrc;
        let style
        if(img.width >= img.height) {
          style = {width: '30px', height: 'auto'};
        } else {
          style = {width: 'auto', height: '36px'};
        }
        return style;
      },
      // 滚动条
      toScrollHeight() {
        if(this.chatMessage.list && this.chatMessage.list.length > 0) {
          let div = document.getElementById('chatMain');
          let div2 = document.getElementById('chatMainDiv');
          if(this.scrollBottom > 0) {
            let conScrollHeight = div.scrollHeight   // 可以滚动区域的高度
            let conClientHeight = div.clientHeight   // 区域内容的高度
            div.scrollTop = conScrollHeight - this.scrollBottom - conClientHeight;
          } else {
            div.scrollTop = div2.scrollHeight;
          }
          // div.scrollTop = div2.scrollHeight;
        };
      },
      scrollChange:debounce(function(e) {
        let vm = this;
        let conScrollHeight = e.target.scrollHeight   // 可以滚动区域的高度
        let conClientHeight = e.target.clientHeight   // 区域内容的高度
        let conScrollTop = e.target.scrollTop        //  内容滚动了的高度
        
        // 滚动条距离底部距离
        vm.scrollBottom = conScrollHeight - conClientHeight - conScrollTop;
        if(vm.loading) {
          return;
        };
        if(vm.chatMessage.list.length != 0 && vm.chatMessage.list.length >= vm.chatMessage.count) {
          return;
        }
        if(conScrollTop === 0) {
          let size = vm.chatMessage.count
          if(size > vm.chatMessage.list.length) {
            vm.loading = true;
            let params = {...vm.$store.state.chatLog.chatMessageListParams};
            let delMessageNum = vm.chatMessage.list.length - params.page * params.size;
            params.page = params.page + 1;
            vm.$store.commit('chatLog/setChatMessageListParams',params);
            vm.$getChatHistory(params).then(res => {
              if(res.code == 1) {
                let arr = {...res.data};
                if(delMessageNum) {
                  let newList = JSON.parse(JSON.stringify(arr.list));
                  newList.splice(0,delMessageNum)
                  if(newList && newList.length > 0) {
                    arr.list = newList.reverse();
                  }
                } else {
                  if(arr.list && arr.list.lenth > 0) {
                    arr.list = arr.list.reverse();
                  }
                }
                let log = {...vm.$store.state.chatLog.chatHistory};
                let list = [...log.list];
                let newArr = arr.list.concat(list)
                arr.list = [...newArr];
                vm.$store.commit('chatLog/setChatHistory',arr);
                vm.loading = false;
              } else  {
                vm.loading = false;
                this.$message.error(res.msg);
              }
            });
          }
        }
      },200),
      // 聊天日期
      getMessageDayTitle(message) {
        let arr = [];
        let pre = undefined;
        message.list.forEach((item,index) => {
          let current = {...item};
          if(item.msg_type == 3) {
            // 询价单更改未自己发送
            current.is_sender = 1;
          }
          if(index == 0) {
            pre = {...item};
            arr.push({id: new Date().getTime() + Math.ceil(Math.random() * 10000),showDateTiele: this.getMessageListDate(item.create_time)});         
            arr.push({...current});
          } else {
            let preD = this.getMessageListDate(pre.create_time)
            let d = this.getMessageListDate(item.create_time)
            let isSame = dayjs(preD).isSame(d);
            if(preD == 'Invalid Date' || d == 'Invalid Date') {
              arr.push(current);
            } else if(!isSame) {
              arr.push({id: new Date().getTime() + Math.ceil(Math.random() * 10000),showDateTiele: d});
              pre = {...item}
              arr.push(current);
            } else {
              arr.push(current);
            }
          }
        });
        this.theMessageList = arr;
      },
      getMessageListDate(date) {
        let newDate = dayjs(date).subtract(8, 'hour').add(- new Date().getTimezoneOffset(),'minute').format("MMM DD YYYY");
        return newDate;
      },
      // 获取图片url
      getImgUrl() {
        this.imgUrl = process.env.baseOss;
      },
      imageLoad(){
        this.toScrollHeight()
      },
      //下载文件
      dowloadFile(item) {
        const file = item.talk_records_file[0];
        const url = this.imgUrl + file.file_url
        const name = file.file_name
        downFile(url,name)
      },
      // 打开新的询价单、订单详情页面
      openNewDetailPage(roomType) {
        if(roomType && roomType.room_type == 1) {
          this.$GetOfferDetail({quoteNo: roomType.relation_name}).then(res => {
            if(res.code == 1) {
              const data = this.$router.resolve({
                path: '/quote/detail3.0',
                query: {quote_no: roomType.relation_name, type: roomType.room_type}
              })
              window.open(data.href, '_blank')
            } else {
              this.$message.error(res.msg)
            }
          });
        } else if(roomType && roomType.room_type == 2) {
          this.$orderDetail({ order_no: roomType.relation_name }).then(res => {
            if(res.code == 1) {
              const data = this.$router.resolve({
                path: '/order/detail',
                query: { id: roomType.relation_name}
              })
              window.open(data.href, '_blank')
            } else {
              this.$message.error(res.msg)
            }
          });
        };
      },
      getFileType(type) {
        switch(type) {
          case 1: 
            return '';
          case 2:
            return '.pdf';
          case 3:
            return '.excel';
          case 4:
            return '.word';
          case 5:
            return '.ppt';
          case 6:
            return '';
          case 7:
            return '';
          default:
        }
      },
      // 时间格式化
      transTimeType(date,type) {
        if(date && date.length <= 6) {
          return date;
        }
        // let currentGuess = dayjs.tz.guess() //当前时区
        // 北京时间减去8小时，获取本机时间与格林威治时间时间差，再作加减法得到本机时间；
        let time
        if(type == 1) {
          time = dayjs(date).subtract(8, 'hour').add(- new Date().getTimezoneOffset(),'minute').format("YYYY-MM-DD HH:mm");
        } else {
          time = dayjs(date).subtract(8, 'hour').add(- new Date().getTimezoneOffset(),'minute').format("HH:mm");
        };
        return time;
      },
      //格式化文件大小
      getSize(size) {
        if (!size) return 0 + 'KB'
        const s = size / 1024 / 1024
        if (s < 1) {
          return (size / 1024).toFixed(2) + 'KB'
        }
        return s.toFixed(2) + 'M'
      },
      // 文件重发
      uploadRetry(item) {
        this.$emit('uploadRetry',item)
      },
      //删除文件
      deleteFile(item, index) {
        this.$emit('deleteFile',item,index);
      },
      // 图片在线预览
      onLookImg(arr) {
        this.$viewerApi({
          images: arr.map((item) => {
            return this.imgUrl + item.file_url
          })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bigBox {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .chatMain {
    height: 100%;
    padding: 20px 11px 0 20px;
    overflow-y: auto;
    scrollbar-gutter: stable;

    .msgDiv {
      margin-bottom: 20px;
    }
    .listDateTitle {
      width: 100%;
      text-align: center;
      color: #878787;
      line-height: 18px;
      font-size: 12px;
    }
    .headimg {
      width: 36px;
      height: 100%;
    }
    .img {
      max-width: 200px;
      height: auto;
    }

    .senderBox {
      // width: 100%;
      max-width: 360px;
      display: flex;
      flex-direction: row;
      .userImg {
        width: 36px;
        height: 36px; 
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
      }
      .nameAndCon {
        flex: 1;
        .nickname {
          font-family: 'Poppins';
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: #252525;
          margin-bottom: 6px;
        }
      }
      .msgContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #252525;
      }
    }
    .msgFile {
      width: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      .fileInfo {
        margin-left: 10px;
        .fileName {
          font-size: 10px;
          max-width: 140px;
          color: #252525;
          line-height: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fileSize {
          font-size: 10px;
          color: #878787;
          line-height: 15px;
        }
      }
    }
    .msgText {
      max-width: 360px;
      padding: 10px;
      font-size: 12px;
      line-height: 18px;
      background: #F6F8FA;
      border-radius: 8px; 
      overflow: hidden;
      // word-wrap: break-word;
      // word-break: break-all;
      // white-space: pre-wrap;
    }
    .selfMsgTxt {
      max-width: 360px;
      font-size: 12px;
      line-height: 18px;
      padding: 10px;
      word-break:break-word;
      white-space: pre-wrap;
    }
    .selfMsgImg {
      max-width: 360px;
      cursor: pointer;
      border: 1px solid #F6F8FA;
      border-radius: 8px;
      overflow: hidden;
    }
    .quoteInfo {
      padding: 10px;
      font-size: 12px;
      line-height: 18px;
      max-width: 360px;
      background: #FFF;
      border-radius: 8px; 
      overflow: hidden;
      word-wrap: break-word;
      word-break: break-all;
      color: #252525;
      cursor: pointer;

      .quoteTitle {
        font-weight: 600;
        margin-bottom: 6px;
      }
      .quoteNo {
        background: #F5F5F5;
        padding: 10px; 
        font-weight: 500;
        border-radius: 4px;
      }
    }
    .createTime {
      font-size: 12px;
      line-height: 18px;
      color: #878787;
      margin-left: 10px;
    }

    .selfBox {
      // width: 100%;
      max-width: 360px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .selfMsgBox {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #252525;
      }
      .selfContent {
        max-width: 360px;
        margin-left: 10px;
        border: 1px solid #EA543F;
        border-radius: 8px;
        overflow: hidden;
        word-wrap:break-word; 
        word-break:break-word;
      }
    }
  }
  .chatMain-Div {
    overflow-x: hidden;
  }
  .relationDiv {
    height: 24px;
    line-height: 24px;
    padding: 10px 20px 0;
    color: #EA543F;
    cursor: pointer;
    font-size: 12px;
    .relationImg {
      margin-right: 3px;
    }
  }
  .netErr {
    width: calc(100% - 20px);
    padding: 0 20px;
    font-size: 12px;
    img {
      width: 12px;
      margin-right: 6px;
    }
    .netError {
      padding: 0 12px;
      width: calc(100% - 40px);
      line-height: 24px;
      color: #252525;
      border-radius: 4px;
      border: 1px solid rgba(237, 123, 47, 0.45);
      background: #FEF9EE;
    }
  }
  .fileList {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    width: 100%;
    flex: 1;
    left: 20px;
    background: #fff;
    font-size: 10px;
    .fileListDiv {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      max-height: 160px;
      min-height: 15px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      margin:0 0 5px;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar{
        width: 6px;
      }
      .fileBtm {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
    .file {
      height: 50PX;
      box-sizing: border-box;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #D0D0D0;
      &:last-of-type{
        border: 0;
      }
      &:hover{
        background-color: #F5F5F5;
        .top-right{
          .pencent{
            display: none;
          }
          .md-close{
            display: inline-block;
          }
        }
      }
      .fileCon {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .top-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 36px;
        .top-left-span {
          display: flex;
          align-items: center;
          width: 30px;
          height: 36px;
          margin-right: 10px;
        }
        .fileImg {
          // width: 30px;
          // height: auto;
          margin-right: 10px;
        }
        .top-fileTex {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .top-fileTex-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 190px;
            color: #252525;
          }
          .size {
            color: #878787;
          }
          .uploadFail{
            color: #878787;
          }
        }
      }
      .top-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .pencent {
          display: inline-block;
          color: #EA543F;
          margin-left: 16px;
          width: 20px;
          text-align: center;
        }
        .md-close {
          display: none;
          margin-left: 16px;
          width: 20px;
          text-align: center;
          cursor: pointer;
           &:hover {
            .svg-icon{
              fill: #EA543F !important;
            }
          }
        }
        .retry {
          display: none;
          cursor: pointer;
          &:hover {
            .svg-icon{
              fill: #EA543F !important;
            }
          }
        }
      }
    }
    .file-failed{
       &:hover{
        .top-right{
          .retry{
            display: inline-block;
          }
        }
      }
    }
    .drop {
      width: 45px;
      height: 15px;
      display: flex;
      background: #fff;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -42px;
      cursor: pointer;
      z-index: 99;
      &:hover {
        color: #EA543F;
      }
    }
    .dropHover {
      .hoverOpen {
        display: none;
      }
      &:hover .hoverClose{
          display: none;
      } 
      &:hover .hoverOpen {
          display: inline-block;
        }
    }
    .dropLoading {
      color: #EA543F;
    }
  }
</style>