import dayjs from 'dayjs'
export function multilineText(
  doc,
  text,
  x,
  y,
  maxWidth,
  maxRowNum,
  lineHeigh = 5
) {
  const texts = handleText(text, maxWidth, doc)
  for (let i = 0; i < texts.slice(0, maxRowNum).length; i++) {
    let text = texts[i]
    if (
      i + 1 === texts.slice(0, maxRowNum).length &&
      texts.length > maxRowNum
    ) {
      doc.text(text + '...', x, y)
    } else {
      doc.text(text, x, y)
    }
    if (i !== texts.slice(0, maxRowNum).length - 1) {
      y += lineHeigh
    }
  }
  return {
    x,
    y,
    text,
    rowNum: texts.slice(0, maxRowNum).length,
    index: texts.slice(0, maxRowNum).join('').length - 1,
    isLast: texts.length <= maxRowNum
  }
}

export async function multilineTexts(text, x, y, maxWidth, lineHeigh = 5) {
  const maxY = this.maxPageY //剩余位置
  const texts = handleText(text, maxWidth, this.doc)
  for (let i = 0; i < texts.length; i++) {
    let text = texts[i]
    if (y >= maxY) {
      await this.appendNewPage()
      y = this.currentY
      this.doc.setFontSize(14)
      if (this.language == 'ch') {
        this.doc.setFont('fangzhengfangsong', 'fangzhengfangsong', 400)
      } else {
        this.doc.setFont('BaseFont', 400)
      }
    }
    this.doc.text(text, x + 1, y)
    if (i !== texts.length - 1) {
      y += lineHeigh
    }
  }
  return {
    x,
    y,
    texts
  }
}
//获取要绘制多行的文本
function handleText(text, maxWidth, doc) {
  let lines = text.split('\n').map(text => {
    return text.split(/(\s)/g) || [] // 按照空格进行切割，但是要保留空格 例：'s s s' 切割结果为 ['s', ' ', 's', ' ', 's']
    // return text.match(/([a-zA-Z0-9'"]+|.)/g) || [];//提取单词，例从"中国,Hello World!"可提取出['中','国',',','Hello',' ','World','!']
  })
  let texts = [] //最终要绘制的多行文本
  for (let words of lines) {
    let sumWidth = 0
    texts.push('')
    for (let word of words) {
      let width = doc.getTextWidth(word)
      if (width > maxWidth) {
        //该单词宽度超过最大宽度
        for (let c of word) {
          //对单词的每一个字符进行判断
          let mt = doc.getTextWidth(c)
          sumWidth += mt
          if (sumWidth < maxWidth) {
            //累加的宽度没有超过最大宽度
            texts[texts.length - 1] += c
          } else {
            //若累加宽度超过最大宽度，重新累加，当前字符另起新行
            sumWidth = mt
            texts.push(c)
          }
        }
        continue
      }
      sumWidth += width
      if (sumWidth < maxWidth) {
        //累加的宽度没有超过最大宽度
        texts[texts.length - 1] += word
      } else if (word != ' ') {
        //若累加宽度超过最大宽度，重新累加，当前单词另起新行，注意：空格字符串不用另起新行，忽略掉
        sumWidth = width
        texts.push(word)
      }
    }
  }
  return texts
}

export function generateRandomString(length) {
  let result = ''
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
    characters =
      characters.slice(0, randomIndex) + characters.slice(randomIndex + 1)
  }

  return result
}

export function format(value) {
  if (!value) return '\\'
  if (isNaN(value)) {
    return dayjs(value).format('YYYY/MM/DD')
  }
  return dayjs(value * 1000).format('YYYY/MM/DD')
}

// 分隔文字
export const splitTextToFit = (doc, text, boxWidth, boxHeight, language) => {
  const indent = 30;
  const result = [];
  const lineHeight = doc.internal.getLineHeight() / doc.internal.scaleFactor;
  const maxLineWidth = boxWidth - 7;
  const maxLinesPerBox = Math.floor(boxHeight / lineHeight);
  const ellipsis = '...';

  let currentBoxLines = [];
  let currentLine = '';
  const words = text.split(/(?=\b)|(?=\W)/); // 将文本按单词和标点符号分割
  let isFirstBox = true;
  let isFirstLineInBox = true;


  const splitLongWord = (word, maxWidth) => {
    let result = [];
    let start = 0;
    while (start < word.length) {
      let end = start + 1;
      while (end <= word.length && doc.getTextWidth(word.substring(start, end)) <= maxWidth) {
        end++;
      }
      result.push(word.substring(start, end - 1));
      start = end - 1;
    }
    return result;
  };

  words.forEach((word, wordIndex) => {
    if (word === '\n') {
      // 遇到换行符，直接将当前行添加到currentBoxLines，并开始新行
      currentBoxLines.push(currentLine.trim());
      currentLine = '';
      isFirstLineInBox = false;
    } else {
      let testLine = currentLine + word;
      let testLineWidth = doc.getTextWidth(testLine);
      const lineWidthWithIndent = isFirstLineInBox ? maxLineWidth - indent : maxLineWidth;
      // if (isFirstBox && currentBoxLines.length === 0) {
      //   testLineWidth += indent; // 考虑第一行的缩进
      // }else if (!isFirstBox && currentBoxLines.length === 0) {
      //   testLineWidth += indent; // 考虑第二页及后续页的第一行的缩进
      // }

      if (testLineWidth > lineWidthWithIndent) {
        if (doc.getTextWidth(word) > lineWidthWithIndent) {
          const splitWords = splitLongWord(word, lineWidthWithIndent - (currentLine ? doc.getTextWidth(currentLine) : 0));
          splitWords.forEach((splitWord, splitIndex) => {
            if (splitIndex === 0) {
              if (isFirstLineInBox) {
                splitWord = ' '.repeat(indent / doc.internal.getFontSize()) + splitWord;
                isFirstLineInBox = false;
              }
              currentBoxLines.push((currentLine + splitWord).trim());
            } else {
              currentBoxLines.push(splitWord.trim());
            }
            if (currentBoxLines.length === maxLinesPerBox) {
              result.push(currentBoxLines.join('\n'));
              currentBoxLines = [];
              currentLine = ellipsis + ' ';
              isFirstBox = false;
              isFirstLineInBox = true;
            }
          });
          currentLine = '';
        } else {
          if (isFirstLineInBox) {
            currentLine = ' '.repeat(indent / doc.internal.getFontSize()) + currentLine.trim();
            isFirstLineInBox = false;
          }
          currentBoxLines.push(currentLine.trim());
          currentLine = word;
        }
      } else {
        currentLine = testLine;
      }
    }
    if (currentBoxLines.length === maxLinesPerBox - 1) {
      if (isFirstLineInBox) {
        currentLine = ' '.repeat(indent / doc.internal.getFontSize()) + currentLine.trim();
        isFirstLineInBox = false;
      }
      currentBoxLines.push(currentLine.trim() + ellipsis);
      result.push(currentBoxLines.join('\n'));
      currentBoxLines = [];
      currentLine = ellipsis + ' ';
      isFirstBox = false;
      isFirstLineInBox = true;
    }

    // Handle last word
    if (wordIndex === words.length - 1 && currentLine) {
      if (isFirstLineInBox) {
        currentLine = ' '.repeat(indent / doc.internal.getFontSize()) + currentLine.trim();
      }
      currentBoxLines.push(currentLine.trim());
    }
  });

  // 最后一行处理，确保没有省略号
  if (currentBoxLines.length > 0) {
    if (result.length > 0 && currentBoxLines[0] === ellipsis) {
      currentBoxLines[0] = currentBoxLines[0].substring(ellipsis.length).trim();
    }
    result.push(currentBoxLines.join('\n'));
  }

  // 确保每个新框的第一行添加省略号，并且不是单行显示
  for (let i = 1; i < result.length; i++) {
    if (!result[i].startsWith(ellipsis)) {
      result[i] = result[i].replace(ellipsis, '').trim(); // 移除之前添加的省略号
      result[i] = ellipsis + ' ' + result[i];
    }
  }

  return result;
};
