const hasSaveAddres = {}
export const state = () => ({
  id: '',
  open: false,
  resId: '',
  accomplish: false,
  chooseId: '',
  chooseOk: false,
  addressType:''
})

export const mutations = {
  setAddress(state, data) {
    state.id = data.id
    state.open = data.open
  },
  getAddress(state, data) {
    state.resId = data.id
    state.accomplish = data.accomplish
  },
  chooseAddress(state, data) {
    state.chooseId = data.id
    state.chooseOk = data.chooseOk
  },
  address(state,data){
    hasSaveAddres.address = data
  },
  openAddressCopmonent(state,data){
    state.addressType = data
  }
}

export const actions = {
  wantAddAdress() {
    return new Promise((resolve, reject) => {
      Object.defineProperty(hasSaveAddres, 'address', {
        configurable: true,
        get: function() {
          return this.address
        },
        set: function(value) {
          resolve(value) // 发射回调
        }
      })
    })
  }
}
