<template>
  <div class="address-win">
    <el-dialog 
      :visible.sync="show" 
      :close-on-press-escape="false"
      width="580px"
      :show-close="false"
      @close="close"
    >
      <div class="dialog-win">
        <div class="dialog-close" @click="close">
          <i class="el-icon-close"></i>
        </div>
        <el-container>
          <div class="container-s">
            <div class="white-panel tc">
              <p><i class="el-icon-success cbfa mgr10"></i></p>
              <p class="font24 mgt30 fwb">
                Congratulations!
              </p>
              <p class="font16 mgt30 c999">
                Registration succeeded.&nbsp;Now you can continue <br />to experience
                our platform.
                <!-- <nuxt-link to="/" class="cfont">
                  instant quote now.
                </nuxt-link> -->
              </p>
              <p class="panel-bottom">
                If you have any questions, please contact
                <a href="mailto:support@rapiddirect.com" class="fwb"
                  >support@rapiddirect.com</a
                >
              </p>
            </div>
          </div>
        </el-container>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ThankYou',
  middleware: 'authenticated',
  data() {
    return {
      show: false,
    }
  },
  watch: {
    '$store.state.thankYou': function(val) {
      this.show = val;
    }
  },
  beforeUpdate() {
    setTimeout(() => {
      this.$store.commit('openThankYou', false)
    }, 3000)
  },
  methods: {
    close() {
      this.$store.commit('openThankYou', false)
      if (this.$route.path !== '/' && !this.$store.state.auth) {
        this.$router.push('/')
      }
    }
  }
}
</script>
<style scoped lang="scss">
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.dialog-win {
  background: $white;
  box-sizing: border-box;
  z-index: 2013;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  text-align: center;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}
.white-panel {
  padding: 60px 0 40px;
}
.panel-bottom {
  margin-top: 40px;
}
.el-icon-success {
  font-size: 80px;
}
.el-button {
  min-width: 180px;
}
.el-button--text {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
