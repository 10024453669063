<template>
  <div class="sendFooter">
    <div :class="isActive ? 'sendBox activeSendBox' :  'sendBox'" :style="networkBreak ? {background:'#F5F7FA'} : {}">
      <div class="sendInputBox">
        <el-input 
          id="sendInput"
          class="borderNone" 
          type="textarea"
          maxlength="2000"
          v-model.trim="messageTxt" 
          @input="onInputChange" 
          @focus="isActive = true"
          @blur="isActive = false"
          @keyup.native.enter="onSend"
          @keydown.native.enter="keydown"
          :disabled="networkBreak"
          placeholder="Write a message"
        ></el-input>
        <!-- @keyup.enter.native="onSend" -->
      </div>
      <div :class="networkBreak ? 'chatImgBox1'  :'chatImgBox'">
        <img class="chatImg" src="~assets/images/chatImg.svg" alt="">
        <img class="chatImg2" @click="onSelectFile('uploadFileImg')" src="~assets/images/chatImg2.svg" alt="">
        <input
          ref="uploadFileImg"
          type="file"
          hidden
          accept=".png,.jpg,.jpeg,.jpe,.jfif,.bmp,.gif,.svg,.webp,.tif,.tiff"
          multiple
          @change="uploadBeforce"
        />
      </div>
      <div :class="networkBreak ? 'chatFileBox1'  :'chatFileBox'">
        <img class="chatFile" src="~assets/images/chatFile.svg" alt="">
        <img class="chatFile2" @click="onSelectFile('uploadFile')" src="~assets/images/chatFile2.svg" alt="">
        <input
          ref="uploadFile"
          type="file"
          hidden
          accept=".png,.jpg,.jpeg,.jpe,.jfif,.bmp,.gif,.svg,.webp,.tif,.tiff,.pdf,.pptx,.doc,.docx,.xlsx,.xls,.stl,.stp,.step,.sldprt,.igs,.iges,.x_t,.x_b,.prt,.ipt,.CatPart,.sat,.3dxml,.eps,.ai,.psd,.dwg,.dws,.mp4,.mpeg,.wmv,.mov,.avi,.mp3,.wma,.wav,.acc,.ogg,.flac,.ape,.zip,.rar,.7z,.tgz,.gz"
          multiple
          @change="uploadBeforce"
        />
      </div>
    </div>
    <div class="sendBtn">
      <img 
        class="chatSend" 
        v-show="uploadFileList.some(item=> item.status == 'ready') || (uploadFileList.length == 0 && !showSubBtn) || sendFoldering ||  networkBreak" 
        src="~assets/images/chatSend.svg" 
      >
      <img 
        class="chatSend2" 
        v-show="(uploadFileList.length > 0 && !uploadFileList.some(item=> item.status == 'ready') && !sendFoldering && !networkBreak) || (showSubBtn && !networkBreak && !sendFoldering && !uploadFileList.some(item=> item.status == 'ready'))" 
        @click="onSend" 
        src="~assets/images/chatSend2.svg" 
      >
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)
import { getFileType, debounce } from '../../../libs/tool';
  export default {
    data () {
      return {
        messageTxt: '',
        isActive: false,
        showSubBtn: false,
        uploadFileList: [], //上传文件列表
        uploadReady: false,
        sendFoldering: false,
        uploadType: "uploadFileImg",
        uploadFileImgFormat: [
          "png",
          "jpg",
          "jpeg",
          "jpe",
          "jfif",
          "bmp",
          "gif",
          "svg",
          "webp",
          "tif",
          "tiff"
        ],
        uploadFileFormat: [
          "png",
          "jpg",
          "jpeg",
          "jpe",
          "jfif",
          "bmp",
          "gif",
          "svg",
          "webp",
          "tif",
          "tiff",
          "pdf",
          "pptx",
          "doc",
          "docx",
          "xlsx",
          "xls",
          "stl",
          "stp",
          "step",
          "sldprt",
          "igs",
          "iges",
          "x_t",
          "x_b",
          "prt",
          "ipt",
          "CatPart",
          "sat",
          "3dxml",
          "eps",
          "ai",
          "psd",
          "dwg",
          "dws",
          "mp4",
          "mpeg",
          "wmv",
          "mov",
          "avi",
          "mp3",
          "wma",
          "wav",
          "acc",
          "ogg",
          "flac",
          "ape",
          "zip",
          "rar",
          "7z",
          "tgz",
          "gz"
        ]
      }
    },
    computed: {
      // 网络
      networkBreak: function() {
        return this.$store.state.chatLog.networkBreak;
      }
    },
    watch: {
      uploadFileList: {
        handler(val) {
          if(val && val.length > 0) {
            let ready = val.some(item => item.status == 'ready');
            this.uploadReady = ready;
          } else {
            this.uploadReady = false;
          }
        },
        deep: true,
        immediate: true
      },
      //批注图片
      "$store.state.chatLog.partBatchFile":{
        handler(val){
          if(val&&this.$store.state.chatLog.chatAgree == 1){
            this.uploadBatchFile(val)
          }
        },
        deep:true
      },
      //隐私协议
      '$store.state.chatLog.chatAgree':{
        handler(val){
          const partBatchFile = this.$store.state.chatLog.partBatchFile
          const chatRoomParams = this.$store.state.chatLog.chatMessageListParams
          if(val==1 && partBatchFile && chatRoomParams?.room_id==partBatchFile.room_id){
            this.uploadBatchFile(partBatchFile)
            this.$store.commit('chatLog/setPartBatchFile',null)
          }
        }
      }
    },
    mounted() {
      let vm = this;
      // 监听复制黏贴文件  发送
      const sendInput = document.getElementById('sendInput');
      sendInput.addEventListener('paste', function(event) {
        vm.uploadType = 'uploadFile'
        const fileList = event.clipboardData.files;
        if(fileList.length > 0) {
          vm.uploadBeforce(fileList,2)
        };
      })
    },
    methods: {
      onInputChange(txt) {
        let vm = this;
        let con = txt.trim();
        if(con){
          vm.showSubBtn = true;
        } else {
          vm.showSubBtn = false;
        }
        vm.messageTxt = txt;
      },
      // 发送完之后push到聊天记录
      onPushMsglist(data) {
        let vm = this;
        let storeData = JSON.parse(JSON.stringify(vm.$store.state.chatLog.chatHistory));
        let obj = storeData.list.length > 0 ? storeData.list[storeData.list.length - 1] : {};
        // let currentGuess = dayjs.tz.guess() //当前时区
        let time = dayjs().utc().tz('Asia/Shanghai').format("YYYY-MM-DD HH:mm:ss");
        let newData = {
          id: new Date().getTime() + Math.ceil(Math.random() * 10000),
          nickname: obj.nickname ? obj.nickname : '',
          headImg: obj.headImg ? obj.headImg : '',
          content: data.content,
          extra_params: null,
          talk_records_file: data.record_file || [],
          msg_type: 1,
          is_sender: 1,
          create_time: time,
        };
        storeData.list.push(newData);
        vm.$store.commit('chatLog/setChatHistory',storeData)
      },
      // 组合键
      keydown(e) {
        if(e && !e.shiftKey && !e.ctrlKey) {
          e.stopPropagation();
          e.preventDefault();
        }
      },
      // 发送
      onSend: debounce(function(event) {
        const eventValid = event && (event.shiftKey || event.ctrlKey); //.Enter / Ctrl + Enter 发送切换；
        if(eventValid) return;
        if(this.uploadFileList.some(item=> item.status == 'ready') || (this.uploadFileList.length == 0 && !this.showSubBtn) || this.sendFoldering ||  this.networkBreak) {
          return;
        }
        let vm = this;
        let room_id = vm.$store.state.chatLog.roomData.id;
        let fd = vm.$store.state.chatLog.socketMsg.fd;
        if(vm.messageTxt) {
          let data = {room_id,fd};
          data.content = vm.messageTxt.trim();
          if(data.content) {
            vm.onSendMessage(data,'1');
          };
          
          if(vm.uploadFileList && vm.uploadFileList.length > 0) vm.onSend();
        } else if(vm.uploadFileList && vm.uploadFileList.length > 0) {
          vm.sendFoldering = true;
          vm.uploadFileList.forEach((item,i) => {
            let data = {room_id,fd};
            if(item['status'] == 'success') {
              let obj = {
                file_name: item.data.name,
                file_size: item.data.size,
                file_url: item.data.path,
              };
              const lIndex = item.data.name.lastIndexOf('.');
              if(lIndex > 0) {
                obj.file_type = getFileType(item.data.name.substring(lIndex + 1));
              } else {
                obj.file_type = 7;
              }
              data.record_file = [obj];
              data.extra_params = undefined,
              vm.onSendMessage(data,'2');
            }
          })
        };
      },100),
      onSendMessage(data,type) {
        let vm = this;
        vm.messageTxt = '';
        vm.showSubBtn = false;
        vm.onPushMsglist(data);
        vm.$sendChatMessage(data).then(res => {
          if(res.code == 1) {
            // vm.sendFoldering = false;
            if (type == 2) {
              let arr = vm.uploadFileList.filter(
                (item) => item['status'] !== 'success'
              )
              vm.uploadFileList = [...arr];
              vm.$emit('updateUpload',vm.uploadFileList)
              vm.sendFoldering = false;
            }
            
          } else {
            vm.$message.error(res.msg);
          }
        })
      },
      onSelectFile(type) {
        if(this.uploadFileList.length >= 10) {
          this.$notify({
            title: 'Warning',
            message: 'Up to 10 files can be uploaded, please delete some and try again.',
            type: 'warning',
            customClass: 'notify-warning',
          })
          return;
        }
        this.uploadType = type;
        this.$refs[type].click();
      },
      //设置图片
      getImg(fileObj) {
        let vm = this;
        let reader = new FileReader();
        reader.readAsDataURL(fileObj.file);
        reader.onload = function (result) {
          // 图片地址 Base64 格式的 可预览
          vm.$set(fileObj, "coverFile", result.target.result);
        };
      },
      //重新上传图片
      uploadRetry(fileObj) {
        fileObj.status = 'ready'
        fileObj.progess = 0
        this.uploadFileSubmit(fileObj)
      },
      // 删除上传文件
      deleteFile(item, index) {
        item.xmlHttp && item.xmlHttp.abort()
        this.uploadFileList.splice(index, 1)
        this.$emit('deleteFile',item,index);
      },
      //零件批注上传
      uploadBatchFile(data){
        if(!data || !data.file) return
        const type = (data.file.type || '').replace("image/","")
        const name = 'Screenshot_' + (dayjs(new Date()).format("YYYY-MM-DDHH:mm:ss")).replace(/-/g,'').replace(/:/g,'') + "." + type
        const file = new File([data.file],name)
        this.uploadBeforce([file],1)
      },
      //上传文件之前  isPartBatchFile = 1: 零件批注，立马发送  2：文件拖拽入框,不会立马发送
      uploadBeforce(event,isPartBatchFile) {
        if(this.networkBreak) {
          return;
        }
        let vm = this;
        const files = isPartBatchFile ? event : event.target.files;
        if(this.uploadFileList.length + files.length > 10) {
          this.$notify({
            title: 'Warning',
            message: 'Up to 10 files can be uploaded, please delete some and try again.',
            type: 'warning',
            customClass: 'notify-warning',
          })
          return;
        }
        const maxSize = 10; //文件最大值为10M
        let gHint = false;
        let g2Hint = false;
        let bHint = false
        for (let index = 0; index < files.length; index++) {
          const fileObj = {};
          let file = files[index];
          let i = file.name.lastIndexOf(".");
          const timeTemp = new Date().getTime() + Math.ceil(Math.random() * 10000);
          const file_name = file.name.substring(0,i);
          if(file_name == 'image') {
            const type = (file.type || '').replace("image/","")
            const name = 'Screenshot_' + (dayjs(new Date()).format("YYYY-MM-DDHH:mm:ss")).replace(/-/g,'').replace(/:/g,'') + "." + type
            file = new File([file],name)
          }
          if (i < 0) {
            if(!gHint) {
              this.$notify({
                title: 'Warning',
                message: 'Contain invalid file type.',
                type: 'warning',
                customClass: 'notify-warning',
              })
              gHint = true;
            }
            continue;
          }
          i = file.name.lastIndexOf(".");
          let suffix = file.name.substring(i + 1).toLowerCase();
          console.log('vm.uploadType + "Format"',vm.uploadType + "Format");
          if (!vm[vm.uploadType + "Format"].includes(suffix)) {
            if(!g2Hint) {
              this.$notify({
                title: 'Warning',
                message: 'Contain invalid file type.',
                type: 'warning',
                customClass: 'notify-warning',
              })
              g2Hint = true;
            }
            continue
          }
          if (file.size / 1024 / 1024 > maxSize) {
            if(!bHint) {
              this.$notify({
                title: 'Warning',
                message: 'Cannot upload file larger than 10MB.',
                type: 'warning',
                customClass: 'notify-warning',
              })
              bHint = true;
            }
            continue;
          }
          if (vm.uploadFileImgFormat.includes(suffix)) {
            fileObj.suffix = suffix;
          }
          vm.$set(fileObj, "uid", timeTemp);
          vm.$set(fileObj, "progess", 0);
          vm.$set(fileObj, "status", "ready");
          fileObj.file = file;
          vm.uploadFileImgFormat.includes(fileObj.suffix) && vm.getImg(fileObj);
          vm.uploadFileList.push(fileObj);
          vm.$emit('updateUpload',vm.uploadFileList)
          // vm.$store.commit('chatLog/setUploadFileList',[...vm.uploadFileList]);
          vm.uploadFileSubmit(fileObj, event,isPartBatchFile);
        }
      },
      // 上传文件提交
      uploadFileSubmit(fileObj, e,isPartBatchFile) {
        let vm = this
        const ApiAuth = localStorage.getItem('apiAuth')
        const uploadFileUrl = `${vm.$axios.defaults.baseURL}/5d15ed1791c1c`;
        const format = new FormData()
        format.append('file', fileObj.file)
        format.append('type', 'admin_upload')
        var xmlHttp = null
        if (XMLHttpRequest) {
          xmlHttp = new XMLHttpRequest() //服务器请求对象
        } else {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP') //兼容微软请求对象
        }
        fileObj.xmlHttp = xmlHttp
        xmlHttp.upload.onprogress = function (event) {
          vm.$set(
            fileObj,
            'progess',
            Number(((event.loaded / (event.total || 1)) * 100).toFixed(2))
          )
        }
        xmlHttp.open('post', uploadFileUrl, true)
        xmlHttp.setRequestHeader('ApiAuth', ApiAuth)
        xmlHttp.send(format)
        xmlHttp.onreadystatechange = function () {
          delete fileObj.xmlHttp
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            const response = JSON.parse(xmlHttp.response)
            if (e && e.target) {
              e.target.value = ''
            }
            if (response.code === 1) {
              fileObj.data = response.data
              vm.$set(fileObj, 'status', 'success')
              if(isPartBatchFile == 1){ //立即发送
                vm.onSend()
              }
            } else {
              vm.$set(fileObj, 'status', 'failed')
            }
          } else {
            vm.$set(fileObj, 'status', 'failed')
          }
        }
        xmlHttp.ontimeout = function () {
          if (e && e.target) {
            e.target.value = ''
          }
          delete fileObj.xmlHttp
          vm.$set(fileObj, 'status', 'failed')
          vm.$message.error('request timeout')
        }
      },
      // 文件、图片清除
      resetFile() {
        this.uploadFileList = []
        this.$emit('updateUpload',[])
      },
      // 聚焦
      onInputFocus() {
        const inputBox = document.getElementById('sendBox');
        inputBox.style.border = '1px solid #EA543F'
      },
      // 失焦
      onInputBlur() {
        const inputBox = document.getElementById('sendBox');
        inputBox.style.border = '1px solid #D0D0D0' 
      },
    }
  }
</script>

<style lang="scss" scoped>
  .sendFooter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .sendBox {
      width: 390px;
      height: 38px;
      border: 1px solid #D0D0D0;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #fff;
      padding-right: 5px;
      margin-right: 10px;
      box-sizing: border-box;
      &:hover {
        border: 1px solid #EA543F;
      }

      .sendInputBox {
        flex: 1;
        .borderNone /deep/ .el-textarea {
          border: none;
          width: 100%;
          box-sizing: border-box;
        }
        /deep/ .el-textarea {
          padding: 0 10px;
          width: 100%;
          box-sizing: border-box;
        }
        /deep/ .el-textarea__inner {
          resize: none;
          border: 0;
          box-shadow: none;
          padding: 0;
          height: 30px;
          font-size: 12px;
          line-height: 30px;
          min-height: 0!important;
        }
        /deep/ .el-textarea__inner::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        /deep/ .el-textarea__inner::-webkit-scrollbar-thumb {
          border-radius: 3px;
        }
        /deep/ .el-textarea__inner::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
      .chatImgBox1 {
        margin: 0 5px 0 0;
        .chatImg2 {
          display: none;
        }
      }
      .chatImgBox {
        cursor: pointer;
        margin: 0 5px 0 0;
        .chatImg2 {
          display: none;
        }
        &:hover {
          .chatImg2 {
            display: inline-block;
          }
          .chatImg {
            display: none;
          }
        }
      }
      .chatFileBox1 {
        margin: 0 5px;
        .chatFile2 {
          display: none;
        }
      }
      .chatFileBox {
        margin: 0 5px;
        cursor: pointer;
        .chatFile2 {
          display: none;
        }
        &:hover {
          .chatFile2 {
            display: inline-block;
          }
          .chatFile {
            display: none;
          }
        }
      }
    }
    .activeSendBox{
      border: 1px solid #EA543F;
    }

    .sendBtn {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
</style>