<script>
export default {
  name: 'jianTou',
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    fill: {
      type: String,
      default: '#252525'
    },
    activeColor: {
      type: String,
      default: '#252525'
    },
    disabledColor: {
      type: String,
      default: '#DEDEDE'
    },
    hover: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      fillColor: ''
    }
  },
  watch: {
    disabled: {
      handler(val) {
        if (val) {
          this.fillColor = this.disabledColor
        } else {
          this.fillColor = this.fill
        }
      },
      immediate: true
    }
  },
  render() {
    return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.width * this.$store.state.scale}
          height={this.height * this.$store.state.scale}
          viewBox="0 0 16 16"
          onClick={$event => this.onClick($event)}
          onMouseenter={e => this.onMouseenter(e)}
          onMouseleave={e => this.onMouseLeave(e)}
          class='svg-box'
        >
          <path
            d="M11.4 7H2V8.33333H11.4L9.33333 10.4L10.2667 11.3333L13.9333 7.66667L10.3333 4L9.4 4.93333L11.4 7Z"
            fill={this.fillColor}
          />
        </svg>
    )
  },
  methods: {
    onClick(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      this.$emit('on-click', e)
    },
    onMouseLeave(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      if (!this.hover) return
      this.fillColor = this.fill
    },
    onMouseenter(e) {
      if (this.disabled) return (this.fillColor = this.disabledColor)
      if (!this.hover) return
      this.fillColor = this.activeColor
    }
  }
}
</script>

<style>
</style>
