var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "messageHeader"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showChatList,
      expression: "showChatList"
    }],
    staticClass: "conversationTxt"
  }, [_vm._v("Conversation history")]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showChatList,
      expression: "!showChatList"
    }],
    staticClass: "headCon"
  }, [!_vm.showIAgree ? _c('div', {
    staticClass: "leftIcon",
    attrs: {
      "title": "Back to conversation history list"
    },
    on: {
      "click": _vm.backChatList
    }
  }, [_c('i', {
    staticClass: "el-icon-arrow-left"
  })]) : _vm._e(), _vm._v(" "), !_vm.is_work_time ? _c('div', {
    staticClass: "headUser"
  }, [_c('span', {
    staticClass: "userImg"
  }, [_c('span', {
    staticClass: "headImgSpan"
  }, [_vm.accInfo.headImg ? _c('img', {
    style: _vm.imgStyle,
    attrs: {
      "src": _vm.imgUrl + _vm.accInfo.headImg
    }
  }) : _vm._e(), _vm._v(" "), !_vm.accInfo.headImg ? _c('img', {
    staticClass: "headImg",
    attrs: {
      "src": require("assets/images/defaultHead.png")
    }
  }) : _vm._e()]), _vm._v(" "), _c('span', {
    staticClass: "statusDot",
    style: {
      background: _vm.statusDotCorlor
    }
  }), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.unReadMsgNum,
      expression: "unReadMsgNum"
    }],
    staticClass: "unreadNum"
  }, [_vm._v(_vm._s(_vm.unReadMsgNum > 99 ? '99+' : _vm.unReadMsgNum))])]), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "nickname"
  }, [_vm._v(_vm._s(_vm.accInfo.nickname))]), _vm._v(" "), _vm.next_work_time ? _c('div', {
    staticClass: "next_work_time"
  }, [_vm._v(_vm._s(_vm.next_work_time ? `We'll return on ${_vm.next_work_time}` : ''))]) : _vm._e()])]) : _c('div', {
    staticClass: "headUser2"
  }, [_c('span', {
    staticClass: "userImg"
  }, [_c('span', {
    staticClass: "headImgSpan"
  }, [_vm.accInfo.headImg ? _c('img', {
    style: _vm.imgStyle,
    attrs: {
      "src": _vm.imgUrl + _vm.accInfo.headImg
    }
  }) : _vm._e(), _vm._v(" "), !_vm.accInfo.headImg ? _c('img', {
    staticClass: "headImg",
    attrs: {
      "src": require("assets/images/defaultHead.png")
    }
  }) : _vm._e()]), _vm._v(" "), _c('span', {
    staticClass: "statusDot",
    style: {
      background: _vm.statusDotCorlor
    }
  }), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.unReadMsgNum,
      expression: "unReadMsgNum"
    }],
    staticClass: "unreadNum"
  }, [_vm._v(_vm._s(_vm.unReadMsgNum > 99 ? '99+' : _vm.unReadMsgNum))])]), _vm._v(" "), _c('span', {
    staticClass: "userName"
  }, [_vm._v("\n        " + _vm._s(_vm.accInfo.nickname) + "\n      ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }