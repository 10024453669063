var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-win"
  }, [_c('el-dialog', {
    attrs: {
      "visible": _vm.show,
      "close-on-press-escape": false,
      "width": "580px",
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.show = $event;
      },
      "close": _vm.close
    }
  }, [_c('div', {
    staticClass: "dialog-win"
  }, [_c('div', {
    staticClass: "dialog-close",
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "el-icon-close"
  })]), _vm._v(" "), _c('el-container', [_c('div', {
    staticClass: "container-s"
  }, [_c('div', {
    staticClass: "white-panel tc"
  }, [_c('p', [_c('i', {
    staticClass: "el-icon-success cbfa mgr10"
  })]), _vm._v(" "), _c('p', {
    staticClass: "font24 mgt30 fwb"
  }, [_vm._v("\n              Congratulations!\n            ")]), _vm._v(" "), _c('p', {
    staticClass: "font16 mgt30 c999"
  }, [_vm._v("\n              Registration succeeded. Now you can continue "), _c('br'), _vm._v("to experience\n              our platform.\n              ")]), _vm._v(" "), _c('p', {
    staticClass: "panel-bottom"
  }, [_vm._v("\n              If you have any questions, please contact\n              "), _c('a', {
    staticClass: "fwb",
    attrs: {
      "href": "mailto:support@rapiddirect.com"
    }
  }, [_vm._v("support@rapiddirect.com")])])])])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }