var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.storkColor ? _c('svg', {
    class: [_vm.svgClass, 'svg'],
    style: {
      width: _vm.width,
      height: _vm.height,
      fill: _vm.color,
      stroke: _vm.storkColor
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('use', {
    attrs: {
      "href": _vm.iconName
    }
  })]) : _c('svg', {
    class: [_vm.svgClass, 'svg'],
    style: {
      width: _vm.width,
      height: _vm.height,
      fill: _vm.color
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('use', {
    attrs: {
      "href": _vm.iconName
    }
  }), _vm._v(" "), _vm._t("text")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }