export const state = () => ({
  renderData: {},
  modelCheckersData: {},
  dmfAnalyseHinwall: {},
  dmfAnalyseNarrowslit: {},
  dmfAnalyseOther: {},
})

export const mutations = {
  receivePushRenderData(state, data) {
    const { serviceType, ReqType } = data.header;
    if(typeof data.attribute === 'object' || data.attribute === null) {
      state.modelCheckersData = data
    }else if (serviceType === 'parts_model_check') { // 上传零件渲染
      state.renderData = data;
    }else if (serviceType === 'parts_dmf') { // DMF解析
      const keyMap = { 1: 'dmfAnalyseHinwall', 2: 'dmfAnalyseNarrowslit', 3: 'dmfAnalyseOther' };
      state[keyMap[ReqType]] = data;
    }
  },
  changeModelCheckersData(state) {
    state.modelCheckersData = { ...state.modelCheckersData, changeTime: Date.now().toString() }
  },
  changeRenderData(state) {
    state.renderData = { ...state.renderData, changeTime: Date.now().toString() }
  }
}
