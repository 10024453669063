const Cookie = process.client ? require('js-cookie') : undefined
import { CacheUtils } from '~/libs/tool.js'
export default function(app) {
  const axios = app.$axios
  // 基本配置
  axios.defaults.timeout = 120000
  axios.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded'
  // 请求错误时重新发起请求
  axios.defaults.retry = 0 // 重试次数
  axios.defaults.retryDelay = 3000 // 重试延时
  axios.defaults.shouldRetry = error => {
    if (error) {
      return true
    }
  } // 重试条件

  // 请求回调
  axios.onRequest(config => {
    /** api/util.js 中的定义有 cancel 的接口都需要取消 */
    if (config.cancel) {
      let cacheKey = config.url + '_' + config.cacheKey
      // 每次发送请求之前将上一个未完成的相同请求进行中断
      if (CacheUtils.cache[cacheKey]) {
        CacheUtils.clearCache(cacheKey)
      }
      // 将当前请求所对应的取消函数存入缓存
      const source = axios.CancelToken.source()
      CacheUtils.cache[cacheKey] = source
      config.cancelToken = source.token
      // 临时保存 cacheKey，用于在响应拦截器中清除缓存
      config.cacheKey = cacheKey
    } else {
      delete config.cancel
    }
    if (!config.baseURL) {
      config.baseURL = '/api'
    }
    return config
  })

  // 返回回调
  axios.onResponse(res => {
    // 拦截器配置
    // 响应接收之后清除缓存
    const cacheKey = res.config.cacheKey
    delete CacheUtils.cache[cacheKey]
    // 用户未登录或登录已过期
    if (res.data.code === -23 || res.data.code === -996) {
      Cookie.remove('auth')
      app.store.commit('setAuth', null)
      app.store.commit('checkQuote', 0)
      // app.store.commit('setLoginType', 'aside')
      return Promise.reject(res)
    } else if (res.data.code === -19) {
      window.location.pathname = '/error'
    } else if (/\/64097ee2d10bf/g.test(res.config.url)) {
      return {
        headers: res.headers,
        data: res.data
      }
    } else {
      return res.data // data数据
    }
    // return res // 全部数据
  })

  // 错误回调
  axios.onError(error => {
    const config = error.config
    // 响应接收之后清除缓存
    config?.cacheKey && delete CacheUtils.cache[config.cacheKey]
    // 判断是否配置了重试
    if (!config || !config.retry) {
      return Promise.reject(error?.data?.msg)
    }
    if (!config.shouldRetry || typeof config.shouldRetry !== 'function') {
      return Promise.reject(error?.data?.msg)
    }

    // 判断是否满足重试条件
    if (!config.shouldRetry(error)) {
      return Promise.reject(error?.data?.msg)
    }

    // 设置重置次数，默认为0
    config.__retryCount = config.__retryCount || 0

    // 判断是否超过了重试次数
    if (config.__retryCount >= config.retry) {
      return Promise.reject(error?.data?.msg)
    }

    // 重试次数自增
    config.__retryCount += 1

    // 延时处理
    const backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve()
      }, config.retryDelay || 1)
    })

    // 重新发起axios请求
    return backoff.then(function() {
      return axios(config)
    })
  })
}
