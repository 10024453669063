import * as uuid from 'uuid'
export class ChannelClientImpl {
  constructor(clientWindow, serviceWindow, origin) {
    this.isRunning = true
    this.clientWindow = clientWindow
    this.serviceWindow = serviceWindow
    this.origin = origin
    this.requestCache = new Map()
    this.subscriptionCache = new Map()
    clientWindow.addEventListener(
      'message',
      this.receiveResponse.bind(this),
      false
    )
  }
  receiveResponse(ev) {
    if (this.origin !== '*' && ev.origin !== this.origin) {
      return
    }
    const response = ev.data
    if (
      (response.type === 'response' || response.type === 'ack') &&
      this.requestCache.has(response.requestId)
    ) {
      const callback = this.requestCache.get(response.requestId)
      if (response.status === 200) {
        callback.success(response.body)
      } else {
        callback.failed(response.body)
      }
      this.requestCache.delete(response.requestId)
    } else if (
      response.type === 'event' &&
      this.subscriptionCache.has(response.destination)
    ) {
      const subscribers = this.subscriptionCache.get(response.destination)
      subscribers.forEach(subscriber => {
        subscriber(response.body)
      })
    }
  }
  timeoutFailed(info) {
    if (this.requestCache.has(info.requestId)) {
      const callback = this.requestCache.get(info.requestId)
      this.requestCache.delete(info.requestId)
      callback.failed(info)
    }
  }
  actualSubscribe(destination, callback, timeout = 3000) {
    const request = {
      type: 'subscribe',
      requestId: uuid.v4(),
      destination: destination
    }
    this.serviceWindow.postMessage(request, this.origin)
    this.requestCache.set(request.requestId, callback)
    setTimeout(() => {
      this.timeoutFailed({requestId:request.requestId,data:destination})
    }, timeout)
  }
  request(destination, message, timeout = 10000) {
    if (!this.isRunning) {
      throw Error('This client has finished.')
    }
    const request = {
      type: 'request',
      requestId: uuid.v4(),
      destination: destination,
      body: message
    }
    return new Promise((resolve, reject) => {
      this.serviceWindow.postMessage(request, this.origin)
      this.requestCache.set(request.requestId, {
        success: resolve,
        failed: reject
      })
      setTimeout(() => {
        this.timeoutFailed({requestId:request.requestId,data:message})
      }, timeout)
    })
  }
  subscribe(destination, subscriber, timeout = 10000) {
    if (!this.isRunning) {
      throw Error('This client has finished.')
    }
    return new Promise((resolve, reject) => {
      if (!this.subscriptionCache.has(destination)) {
        this.subscriptionCache.set(destination, new Set())
        this.actualSubscribe(
          destination,
          {
            success: () => {
              this.subscriptionCache.get(destination).add(subscriber)
              resolve()
            },
            failed: () => {
              this.subscriptionCache.delete(destination)
              reject()
            }
          },
          timeout
        )
      } else {
        this.subscriptionCache.get(destination).add(subscriber)
        resolve()
      }
    })
  }
  unsubscribe(destination, timeout = 3000) {
    const request = {
      type: 'unsubscribe',
      requestId: uuid.v4(),
      destination: destination
    }
    this.serviceWindow.postMessage(request, this.origin)
    this.subscriptionCache.delete(destination)
  }
  finish() {
    this.isRunning = false
    this.clientWindow.removeEventListener(
      'message',
      this.receiveResponse,
      false
    )
  }
}
