var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "address-win"
  }, [_c('el-dialog', {
    attrs: {
      "visible": _vm.$store.state.resetPassword,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        return _vm.$set(_vm.$store.state, "resetPassword", $event);
      }
    }
  }, [_c('div', {
    staticClass: "dialog-win"
  }, [_c('div', {
    staticClass: "dialog-left"
  }, [_c('img', {
    style: {
      width: '100%',
      height: '100%'
    },
    attrs: {
      "src": require("@/assets/images/forgotPassword_bg.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "dialog-right"
  }, [_c('div', {
    staticClass: "dialog-close",
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "el-icon-close"
  })]), _vm._v(" "), _c('div', {
    staticClass: "align-center",
    staticStyle: {
      "height": "100%"
    }
  }, [!_vm.showLoginVisible ? _c('div', {
    staticClass: "login-content"
  }, [_c('div', {
    staticClass: "reset-title"
  }, [_c('div', [_vm._v("Reset password")])]), _vm._v(" "), _c('el-form', {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules,
      "label-position": "left",
      "hide-required-asterisk": true
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "password",
      "autocomplete": "off",
      "label": "Password"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }], null, false, 1466610396)
  }, [_c('el-input', {
    ref: "password1",
    attrs: {
      "type": _vm.pwdType_password1,
      "placeholder": "Enter your password"
    },
    model: {
      value: _vm.ruleForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "password", $$v);
      },
      expression: "ruleForm.password"
    }
  }, [_c('template', {
    slot: "suffix"
  }, [_c('span', {
    staticClass: "showPassWord",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changePwdType('password1');
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password1 == 'text',
      expression: "pwdType_password1=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/openEyes.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password1 !== 'text',
      expression: "pwdType_password1!=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/closeEyes.svg")
    }
  })])])], 2)], 1), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ruleForm.password || _vm.ruleForm.password.length == 0,
      expression: "!ruleForm.password || ruleForm.password.length == 0"
    }],
    staticClass: "mgb20 passwordHint"
  }, [_c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 1 letter")])]), _vm._v(" "), _c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 1 number")])]), _vm._v(" "), _c('div', {
    staticClass: "passHint"
  }, [_c('span', {
    staticClass: "redioSpan"
  }), _vm._v(" "), _c('span', [_vm._v("At least 8 characters")])])]), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ruleForm.password && _vm.ruleForm.password.length > 0,
      expression: "ruleForm.password && ruleForm.password.length > 0"
    }],
    staticClass: "mgb20 passwordHint"
  }, [_c('div', {
    class: _vm.passHint.hint1 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint1 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 1 letter")])]), _vm._v(" "), _c('div', {
    class: _vm.passHint.hint2 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint2 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 1 number")])]), _vm._v(" "), _c('div', {
    class: _vm.passHint.hint3 ? 'passHint passHint-g' : 'passHint passHint-r'
  }, [_vm.passHint.hint3 ? _c('i', {
    staticClass: "el-icon-success"
  }) : _c('i', {
    staticClass: "el-icon-error"
  }), _vm._v(" "), _c('span', {
    staticClass: "passHint-txt"
  }, [_vm._v("At least 8 characters")])])]), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "rpassword",
      "autocomplete": "off",
      "label": "Confirm password"
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "form__error_icon_tip"
        }, [_c('i', {
          staticClass: "el-icon-error"
        }), _vm._v(" "), _c('span', [_vm._v(_vm._s(scope.error))])])];
      }
    }], null, false, 1466610396)
  }, [_c('el-input', {
    ref: "password2",
    attrs: {
      "type": _vm.pwdType_password2,
      "placeholder": "Enter your password again"
    },
    model: {
      value: _vm.ruleForm.rpassword,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "rpassword", $$v);
      },
      expression: "ruleForm.rpassword"
    }
  }, [_c('template', {
    slot: "suffix"
  }, [_c('span', {
    staticClass: "showPassWord",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changePwdType('password2');
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password2 == 'text',
      expression: "pwdType_password2=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/openEyes.svg")
    }
  }), _vm._v(" "), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pwdType_password2 !== 'text',
      expression: "pwdType_password2!=='text'"
    }],
    staticClass: "eyeIcon",
    attrs: {
      "src": require("assets/images/closeEyes.svg")
    }
  })])])], 2)], 1), _vm._v(" "), _c('el-form-item', [_c('div', {
    staticClass: "subBox"
  }, [_c('el-button', {
    staticClass: "loginBtn",
    attrs: {
      "type": "primary",
      "size": "large",
      "loading": _vm.reserLoading
    },
    on: {
      "click": function ($event) {
        return _vm.reserPassword('ruleForm');
      }
    }
  }, [_vm._v("Submit")])], 1)])], 1)], 1) : _c('div', {
    staticClass: "login-content"
  }, [_c('div', {
    staticClass: "dialog-lo"
  }, [_c('div', {
    staticClass: "login-title"
  }, [_vm._v("\n              Reset password\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "dialog-log-txt"
  }, [_c('i', {
    staticClass: "el-icon-success",
    attrs: {
      "size": "18"
    }
  }), _vm._v("\n               Your account password has been reset successfully.\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "footerBtnBox"
  }, [_c('el-button', {
    staticClass: "homeBtn",
    on: {
      "click": _vm.anewToHome
    }
  }, [_vm._v("Home")]), _vm._v(" "), _c('el-button', {
    staticClass: "homeBtn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.anewLogin
    }
  }, [_vm._v("Log In")])], 1)])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }