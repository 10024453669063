import Vue from 'vue'
Vue.directive('move', (el, binding) => {
  let startX = 0
  let startY = 0
  let initialX, initialY
  let isDragging = false
  let threshold = 5 // 拖拽阈值，根据需求进行调整
  console.log(binding.value)
  if (binding.value) {
    el.removeEventListener('mousedown', startDrag)
    document.onmousemove = null
    document.onmouseup = null
  }
  el.addEventListener('mousedown', startDrag)
  function mousemove(event) {
    event.preventDefault()
    let deltaX = Math.abs(event.clientX - initialX)
    let deltaY = Math.abs(event.clientY - initialY)
    // 如果移动距离超过阈值，则为拖拽移动
    if (deltaX > threshold || deltaY > threshold) {
      isDragging = true
      const left = event.clientX - startX + 'px'
      const top = event.clientY - startY + 'px'
      el.style.left = left
      el.style.top = top
    } else {
      isDragging = false
    }
  }
  function mouseup(event) {
    if (isDragging) {
      ep.stopPropagation()
    }
    document.removeEventListener('mousemove', mousemove)
    document.removeEventListener('mouseup', mouseup)
  }
  function startDrag(event) {
    event.preventDefault()
    initialX = event.clientX
    initialY = event.clientY
    startX = event.clientX - el.offsetLeft
    startY = event.clientY - el.offsetTop
    document.addEventListener('mousemove', mousemove)
    document.addEventListener('mouseup', mouseup)
  }
})
