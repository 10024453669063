<template>
  <div class="createTeam-win">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="createTeamDialogVisible"
      title="Create a team"
      @close="close"
       :width="px2rem('580px')"
    >
      <div class="dialog-win">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          label-position="top"
          @submit.native.prevent
        >
          <el-form-item prop="team_name" label="Create a team to share quotes & orders with your colleagues">
            <el-input
              v-model="ruleForm.team_name"
              placeholder="Enter your team name"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="l_h22 fwn">Note: You can always change your team name later.</div>
          </el-form-item>
          <el-form-item class="footerFormItem">
            <div class="loginBtnBox">
              <el-button  class="btn_style" @click="close"
                >Cancel</el-button
              >
              <el-button
                type="primary"
                :loading="loading"
                class="btn_style"
                @click="Submit('ruleForm')"
                :disabled="!ruleForm.team_name.trim()"
                >Create team</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { px2rem } from '~/libs/tool'
// const Cookie = process.client ? require('js-cookie') : undefined
export default {
  name: 'createTeamDialog',
  data() {
    const validateTeamName = (rule, value, callback) => {
      if (value.trim() == '') {
        // callback(new Error('Please Enter your team name.'))
      } else if (value.length>20) {
        callback(new Error('Team name cannot exceed 20 characters.'))
      } else if(this.is_useTeamName){
        callback(new Error('A team with the same name has already been created.'))
      } else {
        callback()
      
      }
    }
    return {
      loading: false,
      ruleForm: {
        team_name: '',
      },
      team_name:'',
      rules: {
        team_name: [
          {
            required: true,
            validator: validateTeamName,
            trigger: 'blur',
          },
        ],
      },
      is_useTeamName:false,
      createTeamDialogVisible: false,
    }
  },
  watch: {
    '$store.state.createTeam.createTeamDialogVisible': function (val)  {
      this.createTeamDialogVisible = val
    },
  },
  methods: {
    px2rem,
    Submit(formName) {
      let is_use = false
      this.is_useTeamName = false
       //团队名称是否已使用
       this.$isTeamName({team_name:this.ruleForm.team_name}).then((res) => {
        if (res.code === 1) {
          is_use = res.data.is_use
          if(!is_use){
            this.submitValidator(formName)
          }else{
             this.is_useTeamName = res.data.is_use
             this.$refs[formName].validate((valid) => {
                if (!valid) {this.is_useTeamName = false}
             })
          }
        }
      })
    },
    submitValidator(formName){
       this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            team_name: this.ruleForm.team_name,
          }
          this.team_name = this.ruleForm.team_name
          this.$createTeamSubmit(data).then((res) => {
            this.loading = false
            if (res.code === 1) {
              localStorage.setItem('createTeam/createTeamDetailId', res.data.id)
              this.$store.commit('createTeam/setCreateTeamDetailId',res.data.id)
              let bool= res.data.is_first? true:''
              this.$store.commit('createTeam/setIsfirstTeamUser', bool) //判断是第一次创建团队或者加入团队 (弹出引导)
              this.close()
              this.getCreateTeamList()
              if(this.$store.state.createTeam.addTeamQuoteId) {
                 this.addQuoteToTeam(res.data.id)
              }else if(this.$store.state.createTeam.addTeamOrderId){
                this.addOrderToTeam(res.data.id)
              }else{
                this.$message({
                message: 'Create successfully!',
                type: 'success'
              })
              }
               this.$router.push({
                path: '/connorsTeam',
                query: {
                  createTeamDetailId: res.data.id,
                  is_first: bool
                },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    addQuoteToTeam(team_id){
      if(!team_id || !this.$store.state.createTeam.addTeamQuoteId) return 
      const data = {
        quote_id: this.$store.state.createTeam.addTeamQuoteId,
        team_id,
      }
      this.$addQuoteToTeam(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: `Add quote to ${this.team_name} successfully!`,
            type: 'success'
          })
        } else {
         return this.$message.error(res.msg)
        }
      })
    },
    addOrderToTeam(team_id) {
      if(!team_id || !this.$store.state.createTeam.addTeamOrderId) return 
      const data = {
        order_id: this.$store.state.createTeam.addTeamOrderId,
        team_id: team_id
      }
      this.$addOrderToTeam(data).then(res => {
        if (res.code === 1) {
          this.$message({
            message: `Add order to ${this.team_name} successfully!`,
            type: 'success'
          })
        } else {
         return this.$message.error(res.msg)
        }
      })
    },
    //团队列表
    getCreateTeamList(){
      let data ={
        page:1,
        size:10000
      }
    this.$getCreateTeamList(data).then((res) => {
       if (res.code === 1) {
        let list = JSON.stringify(res.data.list)
        this.$store.commit('createTeam/setCreateTeamListData', list)
        localStorage.setItem('createTeam/setCreateTeamListData',list)
       }
      })
    },
    close() {
      this.$refs['ruleForm'].resetFields();
      this.$store.commit('createTeam/openCreateTeam',false)
      setTimeout(()=>{
        this.$store.commit('createTeam/setTeamQuoteId',"")
        this.$store.commit('createTeam/setTeamOrderId',"")
      },300)
    },
  },
}
</script>
<style scoped lang="scss">
.createTeam-win /deep/ .el-dialog__wrapper .el-dialog {
  width: 580px;
  border-radius: 4px;
}
.createTeam-win /deep/ .el-dialog__header {
  padding: 20px 40px 20px 40px;
  font-size: 20px;
  display: block;
  line-height: 28px;
  .el-dialog__title{
    font-weight: 600;
  }
}
.createTeam-win /deep/ .el-dialog__headerbtn .el-dialog__close {
  padding-right: 20px;
  line-height: 28px;
}
// .dialog-win {
//   // width: 580px;
//   background: #ffffff;
//   box-sizing: border-box;
//   overflow: auto;
//   z-index: 10000;
// }
.demo-ruleForm {
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #252525;
    margin-bottom: 8px;
    padding: 0;
    &:after {
      content: '*';
      display: inline-block;
      margin-left: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }
  /deep/ input:-webkit-autofill {
    -webkit-text-fill-color: #252525;
  }
  /deep/ input::-webkit-input-placeholder {
    color: #878787;
  }
  /deep/ input::-moz-input-placeholder {
    color: #878787;
  }
  /deep/ input::-ms-input-placeholder {
    color: #878787;
  }
  /deep/ .el-form-item.is-error .el-input__inner {
    border-color: #ea543f;
  }
  /deep/ .el-form-item__error {
    padding-top: 8px;
    color: #ea543f;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  /deep/ .el-input__inner {
    height: 38px;
    line-height: 38px;
    border: 1px solid #d0d0d0;
    color: #252525 !important;
    font-weight: 400;
  }
  .footerFormItem {
    margin-bottom: 0;
  }
}
.l_h22{
  line-height: 22px;
  font-weight: 400;
}
.loginBtnBox {
  widows: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .btn_style{
    box-sizing: border-box;
    display: inline-flex!important;
    padding: 8px 24px!important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    font-size: 14px!important;
    font-style: normal;
    font-weight: 500;
    line-height: 22px!important;
  }
  /deep/.el-button.is-disabled{
    background: #f5f5f5!important;
    border: #f5f5f5!important;
    color: #B8B8B8!important;
  }
}

.footer {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  .forgot {
    color: #ea543f;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .margin-b-8 {
    margin-bottom: 8px;
  }
  .dialog-mess {
    color: #878787;
  }
}

.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
}
/deep/.el-dialog__body {
  padding: 20px 40px;
  color: #252525;
}
</style>
