import * as uuid from 'uuid';
export class ChannelServiceImpl {
    constructor(serviceWindow) {
        this.serviceWindow = serviceWindow;
        this.requestHandler = new Map();
        this.observeHandler = new Map();
        this.subscriptions = new Map();
        serviceWindow.addEventListener('message', this.receiveRequest.bind(this), false);
    }
    receiveRequest(ev) {
        const request = (ev.data);
        if (request.type === 'request' && this.requestHandler.has(request.destination)) {
            // @ts-ignore
            ev.source.postMessage(this.handleRequest(request), ev.origin);
        }
        if (request.type === 'subscribe' && this.observeHandler.has(request.destination)) {
            // @ts-ignore
            ev.source.postMessage(this.handleSubscribe(request, ev), ev.origin);
        }
        if (request.type === 'unsubscribe' && this.observeHandler.has(request.destination)) {
            // @ts-ignore
            ev.source.postMessage(this.handleUnSubscribe(request, ev), ev.origin);
        }
    }
    handleRequest(request) {
        const response = {
            type: 'response',
            requestId: request.requestId,
            destination: request.destination
        };
        try {
            let handler = this.requestHandler.get(request.destination);
            response.status = 200;
            response.body = handler(request.body);
        }
        catch (e) {
            response.status = 400;
            response.body = e;
            response.description = 'Channel service handle request failed';
        }
        return response;
    }
    handleSubscribe(request, ev) {
        const response = {
            type: 'ack',
            requestId: request.requestId,
            destination: request.destination
        };
        try {
            let handler = this.observeHandler.get(request.destination);
            if (handler) {
                handler();
            }
            if (this.subscriptions.has(request.destination)) {
                this.subscriptions.get(request.destination).add(ev);
            }
            else {
                this.subscriptions.set(request.destination, new Set().add(ev));
            }
            response.status = 200;
        }
        catch (e) {
            response.status = 400;
            response.body = e;
            response.description = 'Channel service handle subscribe failed';
        }
        return response;
    }
    handleUnSubscribe(request, ev) {
        const response = {
            type: 'ack',
            requestId: request.requestId,
            destination: request.destination,
            status: 200
        };
        if (this.subscriptions.has(request.destination)) {
            this.subscriptions.get(request.destination).delete(ev);
        }
        return response;
    }
    listen(destination, serve) {
        this.requestHandler.set(destination, serve);
    }
    observe(destination, serve) {
        this.observeHandler.set(destination, serve);
    }
    broadcast(destination, message) {
        const response = {
            type: 'event',
            requestId: uuid.v4(),
            destination: destination,
            status: 200,
            body: message
        };
        const subscript = this.subscriptions.get(destination)
        console.log(subscript);
        if(subscript){
          subscript.forEach((ev) => {
            // @ts-ignore
            ev.source.postMessage(JSON.parse(JSON.stringify(response)), ev.origin);
        });
        }
       
    }
    finish() {
        this.serviceWindow.removeEventListener('message', this.receiveRequest, false);
    }
}
