<template>
  <div class="address-win">
    <el-dialog 
      :visible.sync="dialogLogin"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
       @close="close"
    >
      <div class="dialog-win">
        <div class="dialog-left">
          <img :style="{width: '100%',height: '100%'}"  src="@/assets/images/login_leftbg.svg" />
        </div>
        <div class="dialog-right">
          <div class="dialog-close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="login-content">
          <div class="login-title">
            <span>Log in to your account</span>
          </div>
          <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
          label-position="top"
        >
          <el-form-item prop="email" label="Email address">
            <el-input v-model="ruleForm.email" placeholder="Enter your email address"></el-input>
            <template slot="error" slot-scope="scope">
              <span class="form__error_icon_tip">
                <i class="el-icon-error"></i>
                <span >{{ scope.error }}</span>
              </span>
            </template>
          </el-form-item>
          <el-form-item class="password" prop="password" label="Password">
            <el-input
              ref="password1"
              v-model="ruleForm.password"
              :type="pwdType"
              placeholder="Enter your password"
            >
            <template slot="suffix">
              <span class="showPassWord" @click="changePwdType('password1')">
                <img class="eyeIcon" v-show="pwdType=='text'" src="~assets/images/openEyes.svg">
                <img class="eyeIcon" v-show="pwdType!=='text'" src="~assets/images/closeEyes.svg">
              </span>
            </template>
            </el-input>
            <template slot="error" slot-scope="scope">
              <span class="form__error_icon_tip">
                <i class="el-icon-error"></i>
                <span >{{ scope.error }}</span>
              </span>
            </template>
          </el-form-item>
          <el-form-item class="footerFormItem">
            <div class="loginBtnBox">
              <el-button
                class="loginBtn"
                type="primary"
                size="large"
                :loading="loginLoading"
                @click="login('ruleForm')"
              >Log In</el-button>
            </div>
          </el-form-item>
          <div class="footer">
            <div class="forgot margin-b-8 fwn" @click="findPass">Forgot your password?</div>
            <div class="dialog-mess fwn">
              <span>Don't have an account?</span>
              <span id="AB_SignUpTextLink" class="forgot" @click="signUp">Sign Up</span>
            </div>
          </div>
        </el-form>
        </div> 
        </div>
      </div> 
    </el-dialog>
  </div>
</template>

<script>
import { px2rem } from '~/libs/tool'
import teamQuoteMixin from '~/libs/teamQuoteMixin.js'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  name: 'DialogLogin',
  middleware: 'authenticated',
  mixins:[teamQuoteMixin],
  components:{
  },
  data() {
    return {
      loginLoading: false,
      ruleForm: {
        email: '',
        password: '',
      },
      tableShow: {
        currentPage: 1,
        pageSize: 10,
        listCount: 0,
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Please enter your email address',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Please enter the correct email address',
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: 'Please enter your password',
            trigger: 'blur',
          },
        ],
      },
      dialogLogin: false,
      pwdType:'password',
    }
  },
  watch: {
    '$store.state.dialogLogin': function (val) {
      this.dialogLogin = val
      if(this.$route.query.team_id){
        let inviterEmail = localStorage.getItem('createTeam/inviterEmail')||''
        this.ruleForm.email = inviterEmail?inviterEmail:(localStorage.getItem('mail') || '')
      }else{
        this.ruleForm.email = '';
        this.pwdType = 'password'
      }
    },
  },
  methods: {
    px2rem,
    changePwdType(refName){
        this.pwdType = this.pwdType=='text'?'password':'text';
        this.$nextTick(()=>{
          this.$refs[refName].focus();
        })
    },
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true
          const data = {
            user: this.ruleForm.email,
            password: this.ruleForm.password,
          }
          this.$login(data).then((res) => {
            this.loginLoading = false
            if (res.code === 1) {
              this.$notify({
                type: 'success',
                title: 'Success',
                customClass: 'notify-success',
                message: 'Login successfully',
              })
              const auth = res.data.token
              const isAdd = res.data.address_id
              this.$store.commit('msg/setTeamName', res.data.team)
              this.$store.commit('setAuth', auth) // 存储在vuex中用来进行客户端渲染
              localStorage.setItem('emailValidated', res.data.email_validated)
              localStorage.setItem('accessDomain', res.data.accessDomain)
              const expires = new Date(new Date() * 1 + 86400000 * 30) // 有效期一个月
              Cookie.set('auth', auth, { expires: expires }) // 在cookie中保存token用来进行服务器端渲染
              Cookie.set('isadd', isAdd, { expires: expires })
              const checkQuote = res.data.check_quote_status
              this.$store.commit('checkQuote', checkQuote)
              Cookie.set('checkQuote', checkQuote, { expires: expires })
              this.getMsg()
              // 验证邮箱
              if(this.$store.state.emailVerify) {
                this.$verifyMailUrl({encryptionString:this.$store.state.emailVerify}).then(res => {});
                this.$store.commit('setEmailVerify', undefined);
              }
              // 获取个人信息
              this.$getUser().then(async (res) => {
                if (res.code === 1) {
                  localStorage.setItem('userId', res.data.id)
                  localStorage.setItem('home_guide_steps', res.data.home_guide_steps)
                  localStorage.setItem('quote_guide_steps', res.data.quote_guide_steps)
                  localStorage.setItem('team_guide_steps', res.data.team_guide_steps)
                  localStorage.setItem('mail', res.data.email)
                  localStorage.setItem('email', res.data.email)
                  localStorage.setItem('userName', res.data.userName)
                  this.$store.commit('setAvatar', res.data.imageUrl)
                  this.$store.commit('msg/setUserName', res.data.userName)
                  this.$store.commit('msg/setEmail', res.data.email)
                  this.$store.commit('msg/setFirstName', res.data.firstName)
                  this.$store.commit('msg/setLastName', res.data.lastName)
                  const loginType = this.$store.state.loginType
                  this.$store.commit('openLogin', false) // 登录成功关闭弹窗
                  this.$store.commit('loginOk', loginType) // 登录成功返回状态
                  //获取团队数据
                  this.joinCreateTeam()
                  Cookie.set('avatar', res.data.imageUrl, {
                    expires: expires,
                    domain: this.$store.state.msg.url,
                  })
                  Cookie.set('userName', res.data.userName, {
                    expires: expires,
                    domain: this.$store.state.msg.url,
                  })
                  //插件引导 0需要显示引导 1不显示
                  let VisibleSolidWorksGuideObj = JSON.parse(localStorage.getItem('VisibleSolidWorksGuideObj'))
                  //对象无数据是登录加入数据 置为0需要显示引导 1不显示
                  if(!VisibleSolidWorksGuideObj || VisibleSolidWorksGuideObj=='null'){
                    const obj = {}
                    obj[localStorage.getItem('mail')] = 0
                    VisibleSolidWorksGuideObj= obj
                  }
                  //判断对象有数据时中是否存在这个邮箱
                  if(!VisibleSolidWorksGuideObj.hasOwnProperty(localStorage.getItem('mail'))){
                    VisibleSolidWorksGuideObj[localStorage.getItem('mail')] = 0
                    }
                  localStorage.setItem('VisibleSolidWorksGuideObj',JSON.stringify(VisibleSolidWorksGuideObj))
                  // location.reload()
                }
              })
               //未登录的情况下 点击创建团队必须先登录之后弹出创建团队
              // if(this.$store.state.createTeam.loginCreateTeamVisible){
              //    this.$store.commit('createTeam/openCreateTeam',true)
              // }
              window.setLayer({ event: 'RDLoginSuccess', dimension1: 'login' });
              // 跳转页面
              let path = this.$route.path;
              if(path == '/member/register' || path == '/member/login' || path == '/member/findPassword') {
                this.$router.push('/')
              }
            } else {
              this.$notify.error({
                title: 'Error',
                customClass: 'notify-error',
                message: 'Incorrect user name or password',
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取信息角标
    getMsg() {
      const vm = this.tableShow
      const data = {
        size: vm.pageSize,
        page: vm.currentPage,
        status: -10,
      }
      this.$getMsg(data).then((res) => {
        this.$store.commit('msg/setUnreadNum', res.data.unread)
      })
    },
    close() {
      // if (this.$refs.ruleForm) {
      //   this.$refs.ruleForm.resetFields()
      // }
       this.$store.commit('openLogin', false)
    },
    signUp() {
      this.$store.commit('openLogin', false)
      this.$store.commit('openRegister', true)
    },
    findPass() {
      this.$store.commit('openLogin', false)
      this.$store.commit('openFindPassword', true)
    },
  },
}
</script>
<style scoped lang="scss">
.address-win /deep/ .el-dialog__wrapper .el-dialog {
  width: 1196px;
  height: 720px;
  font-weight: 400;
  font-family: Poppins;
  font-style: normal;
}
.dialog-win {
  min-width: 1100px;
  background: #ffffff;
  box-sizing: border-box;
  overflow: auto;
  z-index: 10000;
  display: flex;
  align-items: center;
  // max-height: 90vh;
}
.dialog-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  text-align: center;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}
.address-win /deep/ .el-dialog__header,
.address-win /deep/ .el-dialog__body {
  border: none;
  padding: 0;
}
.dialog-left{
  width: 576px;
  height: 720px;
}
.dialog-right{
   width: 620px;
   display: flex;
   align-items: center;
   justify-content: center;
  .login-content{
    width: 500px;
    margin: 0 60px;
  }
  .login-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: #252525;
  text-align: center;
  margin: 0px 0 40px;
}
}


.demo-ruleForm {
  overflow: hidden;
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #252525;
    margin-bottom: 8px;
    padding: 0;
        &:after {
          content: '*';
          color: #F56C6C;
          margin-left: 0.02083rem;
      }
  }
  /deep/ input:-webkit-autofill {
    -webkit-text-fill-color: #252525;
  }
  /deep/ input::-webkit-input-placeholder {
    color: #878787;
  }
  /deep/ input::-moz-input-placeholder {
    color: #878787;
  }
  /deep/ input::-ms-input-placeholder {
    color: #878787;
  }
  /deep/ .el-form-item.is-error .el-input__inner {
    border-color: #EA543F;
  }
  /deep/ .el-form-item__content{
    line-height: normal;
  }
  /deep/ .el-form-item__error {
    padding-top: 8px;
    color: #EA543F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  /deep/ .el-input__inner {
    height: 38px;
    line-height: 38px;
    border: 1px solid #D0D0D0;
    color: #252525!important;
  }
  .footerFormItem {
    margin-bottom: 16px;
  }
}
.loginBtnBox {
  widows: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .loginBtn {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    padding: 8px 64px;
    border: 0!important;
  }
}

.footer {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  .forgot {
    color: #EA543F;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .margin-b-8 {
    margin-bottom: 8px;
  }
  .dialog-mess {
    color: #878787;
  }
}

.demo-ruleForm /deep/ .el-form-item__error {
  position: relative;
  top: 0;
}
.showPassWord{
  line-height: 38px;
  padding:0 3px;
  width: 18px;
  height: 20px;
  cursor: pointer;
  .eyeIcon{
   width: 18px;
   height: 20px; 
  }
}
</style>
