<template>
  <div id="chatBox">
  <div :class="{'chatLogBox':true,'chatLogBoxZindex':showChatWindow}" id="home-guide-step5">
    <div class="logHouver" >
      <!-- 在登陆、注册、忘记密码时候屏蔽，避免遮挡 -->
      <div class="chat-icon" @click="openChatWindow" v-show="!$store.state.register && !$store.state.dialogLogin && !$store.state.findPassword &&$route.path!='/specificationsPolicy'">
        <img src="~assets/images/chatLog.svg" class="logImg" v-show="!showChatWindowCss">
        <img src="~assets/images/chatClose.svg" :class="showChatWindow ? 'logImg logDiv': 'displayNone'" v-show="showChatWindow || showChatWindowCss">
        <img src="~assets/images/chatClose.svg" :class="!showChatWindow ? 'logDiv2 logImg': 'displayNone'" v-show="showChatWindow || showChatWindowCss">
        <span class="numSpan" v-show="!showChatWindow && unreadNum && loginToken">
          {{ unreadNum ? unreadNum : '' }}
        </span>
      </div>
      <ChatWindow v-show="showChatWindowCss" @showIAgree="updateShowIAgree" :class="showChatWindow ? 'cssShow' : 'cssClose'" />
    </div>
  </div>
</div>
</template>

<script>
import ChatWindow from './chatWindow';
import { debounce } from '~/libs/tool.js'
export default {
  components: {
    ChatWindow,
  },
  data() {
    return {
      showChatWindowCss: false,
      showIAgree: false,
    }
  },
  computed:{
    showChatWindow: function() {
      return this.$store.state.chatLog.showChatWindow;
    },
    loginToken: function() {
      return this.$store.state.auth;
    },
    unreadNum: function() {
      let num = this.$store.state.chatLog.unreadNum;
      let newNum
      if(num && num > 99) {
        newNum = '99+'
      } else if(num) {
        newNum = num;
      } else {
        newNum = ''
      };
      return newNum;
    }
  },
  watch: {
    loginToken(val) {
      if(val && !this.$socket.socket_open) {
        // 连接websocket
        this.$socket.init();
      } else {
        this.$store.commit('chatLog/setShowChatWindow',false);
      }
    },
    showChatWindow(val) {
      if(val) {
        this.showChatWindowCss = val
      } else {
        setTimeout(() => {
          this.showChatWindowCss = val;
        },260)
      }
    }
  },
  mounted() {
    this.$socket.init();
    //邮件模板跳转首页通过字段判断显示聊天窗口
    // let query = this.$route.query;
    // let path = this.$route.path;
    // if(path=='/'&&query.showChatWindow == "all"){
    //     this.$router.replace(`/?rdtm_from=${query.rdtm_from}`)  //重置跳转地址 防止刷新浏览器再次开启聊天窗口 
    //     this.openChatWindow()
    // }
  },
  methods: {
    updateShowIAgree(bol) {
      this.showIAgree = bol;
    },
    openChatWindow: debounce(function() {
      let vm = this;
      if(!vm.loginToken) {
        // if (path == '/a') {
        // }
        this.$store.commit('openRegister', true) 
        return;
      };
      if(vm.showChatWindow) {
        vm.$store.commit('chatLog/setShowChatWindow',false);
      } else if(!vm.showChatWindow && (this.$route.path == '/quote/detail3.0' || this.$route.path == '/quote/checkout')) {
        vm.$store.commit('chatLog/setShowQuoteChat',true);
      } else if(!vm.showChatWindow && this.$route.path == '/order/detail') {
        vm.$store.commit('chatLog/setShowOrderChat',true);
      } else {
        vm.$store.commit('chatLog/setShowChatWindow',true);
      }
    },200)
  }
}
</script>

<style lang="scss" scoped>
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(45deg);
    }
    50% {
      transform: rotate(90deg);
    }
    75% {
      transform: rotate(135deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes show {
    from {opacity: 0; top: -600px;}
    to {opacity: 1; top: -620px;}
  }
  @keyframes closeShow {
    from {opacity: 1; top: -620px;}
    to {opacity: 0; top: -600px;}
  }
  .cssShow {
    animation: show 0.3s ;
  }
  .cssClose {
    animation: closeShow 0.3s ;
  }
  .chatLogBox {
    position: fixed;
    bottom: 4px;
    right: 16px;
    width: 50px;
    height: 50px;
    z-index: 2010;
    padding: 16px;
    .logHouver {
      position: relative;
      .logImg {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
      }
      .displayNone {
        display: none;
      }
    }
    .logDiv {
      animation: spin 0.3s linear;
    }
    .logDiv2 {
      animation: spin 0.3s reverse;
    }
    .numSpan {
      position: absolute;
      min-width: 7px;
      // line-height: 22px;
      text-align: center;
      padding: 0 6px;
      font-size: 12px;
      border: 1px solid #fff;
      background: #EA543F;
      top: -6px;
      left: 30px;
      color: #fff;
      border-radius: 14px;
      transform: scale(0.85);
      -webkit-transform: scale(0.85);
      // transform: scale(0.85);
      // -webkit-transform: scale(0.85);
    }
  }
  .chatLogBoxZindex{
    z-index: 99999;
  }
</style>