var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showPreview ? _c('el-image-viewer', {
    ref: "preview",
    attrs: {
      "url-list": _vm.previewImages,
      "on-close": _vm.closeViewer,
      "initial-index": _vm.currentIndex
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }