import Vue from 'vue'
import SvgIcon from '~/components/SvgIcon'

// 注册组件
Vue.component('SvgIcon', SvgIcon)
const req = require.context('~/assets/svg', true, /.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

//动态注册svg组件
const req_1 = require.context('~/components/svg', true, /.vue$/)
const files = req_1.keys()
for (let index = 0; index < files.length; index++) {
  const path = files[index];
  import('../components/svg/'+path.replace('./','')).then(component=>{
    // 注册组件
    Vue.component(component.default.name, component.default)
  }).catch((err)=>{
    console.error(err);
  });
}
