<template>
  <el-skeleton v-if="loading" :loading="loading" :rows="6" animated />
  <el-container v-else>
    <div v-if="showTip&&showLeftSidebarAndHeader" class="lead-time-tip">
      <svg-icon iconClass="Subtract" :width="px2rem('20px')" :height="px2rem('20px')"></svg-icon>
       <span class="mgl10">
        We will be closed on Sept. 16-17 for the Mid-Autumn Festival and from Oct. 1-5 for National Day. Anticipate extended lead times and consider ordering early.
      </span>
      <span class="el-icon-close mgl10 cursor-hand" @click="close"></span>
    </div>
    <el-header height="auto" v-show="showLeftSidebarAndHeader">
      <header-home />
    </el-header>
    <el-container class="layout-container-box" id="el-container" :class="{'layout-container-box-tip':showTip}" v-if="showLeftSidebarAndHeader">
      <el-aside v-show="auth&&$route.path!='/help-center/specifications-policy'" :key="$route.path" :class="[openAside ? 'aside-open' : 'aside-close']" >
        <aside-home/>
      </el-aside>
      <div class="layout-container" id="layout-container">
        <nuxt />
      </div>
      <OnlineChat v-show="showTeamChat"/>
    </el-container>
    <el-container class="layout-container-box noSidebar" id="el-container" v-if="!showLeftSidebarAndHeader">
      <div class="layout-container" id="layout-container">
        <nuxt />
      </div>
    </el-container>
    <!-- <footer-home /> -->
    <back-to-top />
    <find-password />
    <reset-password />
    <dialog-login />
    <register />
    <thankYou />
    <message />
    <CreateTeamDialog/>
  </el-container>
</template>

<script>
const Cookie = process.client ? require('js-cookie') : undefined
import { px2rem } from '~/libs/tool'
import headerHome from '@/components/userHeader'
// import footerHome from '@/components/Footer'
import asideHome from '@/components/aside'
import backToTop from '@/components/BackToTop'
import findPassword from '~/components/forgotPassword'
import resetPassword from '~/components/newResetPassword'
import dialogLogin from '~/components/newLoginModal'
import register from '~/components/newRegister'
import thankYou from '~/components/thankYou'
import message from '~/components/message'
import OnlineChat from '~/components/onlineChat'
import CreateTeamDialog from '~/components/createTeamDialog'
export default {
  components: {
    headerHome,
    // footerHome,
    asideHome,
    backToTop,
    dialogLogin,
    register,
    thankYou,
    findPassword,
    resetPassword,
    message,
    OnlineChat,
    CreateTeamDialog
  },
  data() {
    return {
      loading: true,
      showTeamChat: true,
      showTip:false
    }
  },
  watch: {
    '$store.state.resize': function (e) {
      if (e < 1361) {
        this.$store.commit('setAside', false)
      }
    },
    isShowTeamChat(val){
      this.showTeamChat = val
    },
    '$store.state.auth': function(e) {
      if(e) {
        let emailValidated = localStorage.getItem('emailValidated')
        if(emailValidated == '0') {
          this.$store.commit('setShowVerify', true);
        } else {
          this.$store.commit('setShowVerify', false);
        }
      }
    },
  },
  computed: {
    isShowTeamChat: function() {
      return this.$store.state.createTeam.isShowTeamChat;
    },
    openAside() {
      return this.$store.state.aside
    },
    auth() {
      return this.$store.state.auth
    },
    //根据路由隐藏左侧栏以及顶部栏
    showLeftSidebarAndHeader(){
      let path = this.$route.path && this.$route.path.toLowerCase()
      const excudeRoute = ['/member/findpassword','/member/resetpassword','/member/login','/member/register','/help-center/specifications-policy','/landing-page/cnc-machining']
      return !excudeRoute.includes(path)
    }
  },
  onMounted() {
    let emailValidated = localStorage.getItem('emailValidated')
    if(emailValidated == '0') {
      this.emailValChange()
    };
  },
  beforeUpdate() {
    let emailValidated = localStorage.getItem('emailValidated')
    if(emailValidated == '0') {
      this.emailValChange()
    };
  },
  created(){
    this.isShowTip()
  },
  mounted() {
    // 邮箱验证
    let query = this.$route.query;
    if(query.encryptionString && !this.$store.state.auth) {
      const url = window.location.search
      const zurl = decodeURIComponent(url.replace(/%20/g, '%2B'))
      const encry = zurl.slice(18);
      this.$store.commit('setEmailVerify', encry);
    }
    // 验证邮箱提示
    let emailValidated = localStorage.getItem('emailValidated')
    if(emailValidated == '0') {
      this.$store.commit('setShowVerify', true);
    } else {
      this.$store.commit('setShowVerify', false);
    };
    this.loading = false
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    ) {
      return window.location.replace('https://m.rapiddirect.com/')
    }
    this.resize()
    this.$store.state.auth&&this.$store.commit('openLogin', false) // 登录成功关闭弹窗
    window.addEventListener('resize', this.resize);
    window.addEventListener('mousedown', this.monitorCloseChat)
    // 监测网络
    window.addEventListener('offline',function() {
      window.$nuxt.$store.commit('chatLog/setNetworkBreak',true);
      window.$nuxt.$socket.close();
    });
    window.addEventListener('online', function() {
      window.$nuxt.$store.commit('chatLog/setNetworkBreak',false);
      window.$nuxt.$socket.init();
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('mousedown', this.monitorCloseChat);
  },
  methods: {
    px2rem,
    // downloadPlugin(){
    //    window.open(process.env.pluginsUrl)
    // },
    // 邮箱验证自动获取
    emailValChange() {
      let t = localStorage.getItem('emailCountDown')
      if(t) {
        this.$store.dispatch('updateVerify');
      }
    },
    //横幅展示
    isShowTip(){
      const hideBannerTip = Cookie?.get('hideBannerTip2') ? Cookie.get('hideBannerTip2') : false
      const dateTime = new Date("2024-10-06 00:00:00");
      const currentTime = new Date()
      this.showTip = (currentTime < dateTime) && !hideBannerTip
      this.$store.commit('setShowHolidayTip',currentTime < dateTime)
      this.$store.commit('setShowTip',this.showTip)

    },
    // 监听宽度
    resize() {
      let docwidth = window.document.body.getBoundingClientRect().width
      const scale = Math.max(0.75, docwidth / 1920)
      const right = (420 * (scale - 1)) / 2 + 'px'
      const bottom = (674 * (scale - 1)) / 2 + 'px'
      const right_i = (100 * (scale - 1)) / 2 + 'px'
      const bottom_i = (96 * (scale - 1)) / 2 + 'px'
      document.body.style.setProperty('--scale', scale)
      document.body.style.setProperty('--right', right)
      document.body.style.setProperty('--bottom', bottom)
      document.body.style.setProperty('--right_i', right_i)
      document.body.style.setProperty('--bottom_i', bottom_i)
      this.$store.commit('setScale', scale)
      this.$store.commit('setResize', document.body.offsetWidth)
    },
    // 监听点击时间关闭聊天窗
    monitorCloseChat(e) {
      const chatBox = document.getElementById('chatBox')
      const chatBox1 = document.getElementById('openChatDiv')
      const chatBox2 = document.getElementById('openChatDiv2')
      const chatBox3 = document.getElementById('sendImg')
      if(e.target.parentElement.id.indexOf('listToChatLog') > -1) return; // 列表状态打开聊天框 
      if(chatBox3&&chatBox3.contains(e.target)) return
      // 图片预览不关闭聊天对话框
      let isViewerTag = false, element = e.target;
      while (element) {
        if(element.id.indexOf('viewer') > -1) {
          isViewerTag = true;
          break;
        }
        element = element.parentElement;
      }
      if (isViewerTag) return;
      if(chatBox && chatBox1 && chatBox2) {
        if(!chatBox.contains(e.target) && !chatBox1.contains(e.target) && !chatBox2.contains(e.target)) {
          this.$store.commit('chatLog/setShowChatWindow',false)
        }
      } else if(chatBox && chatBox1) {
        if(!chatBox.contains(e.target) && !chatBox1.contains(e.target)) {
          this.$store.commit('chatLog/setShowChatWindow',false)
        }
      } else if(chatBox) {
        if(!chatBox.contains(e.target)) {
          this.$store.commit('chatLog/setShowChatWindow',false)
        }
      }
    },
     close() {
      this.showTip = false
      this.$store.commit('setShowTip',this.showTip)
      const expires = new Date(new Date() * 1 + 60000 * 60 * 24 * 30) // 有效期30天
      Cookie.set('hideBannerTip2', true, { expires: expires })
    },
    learnMore() {
      window.open(
        'https://www.rapiddirect.com/news/rapiddirect-2023-chinese-new-year-holiday-notice/'
      )
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-skeleton {
  box-sizing: border-box;
  padding: 50px;
}
/deep/ .el-aside {
  transition: width 0.3s;
}
.aside-open {
  width: 220px !important;
}
.aside-close {
  width: 90px !important;
}
/deep/ .el-header {
  padding: 0;
}
.layout-container-box {
  width: 100%;
  display: flex;
  height: calc(100vh - 67px);
  .layout-container {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: $GLOBAL_BACKGROUND_COLOE;
  }
}
.noSidebar{
   height: 100vh;
}
.layout-container-box-tip{
  height: calc(100vh - 105px);
}
.layout-container-box-tip-noSidebar{
  height: calc(100vh - 38px);
}
.lead-time-tip {
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  background-color: #ea543f;
  display: flex;
  align-items: center;
  justify-content: center;
  .learn {
    text-decoration: underline;
    cursor: pointer;
    color: #fff !important;
  }
  .el-icon-close{
    font-size: 20px;
  }
}
.downloadPlugin{
  color: #fff;
  font-size: 14px;
  text-decoration:underline;
  cursor: pointer;
}
</style>
<style>
/**
  聊天工具弹框
*/
.el-message {
  z-index: 9999!important; /* 设置一个较高的层级值 */
}
#hubspot-messages-iframe-container.widget-align-right[class='widget-align-right'] {
  display: none !important;
  transform: scale(var(--scale));
  z-index: 8888 !important;
}
#hubspot-messages-iframe-container.widget-align-right[class='widget-align-right']:has(
    .active
  ) {
  right: var(--right) !important;
  bottom: var(--bottom) !important;
}
#hubspot-messages-iframe-container.widget-align-right[class='widget-align-right'] {
  right: var(--right_i) !important;
  bottom: var(--bottom_i) !important;
}
</style>
