export default {
     "list": [
          {
               "short": "AF",
               "name": "阿富汗",
               "en": "Afghanistan",
               "tel": "+93",
               "flag": require("/assets/flag/af.png")
          },
          {
               "short": "AL",
               "name": "阿尔巴尼亚",
               "en": "Albania",
               "tel": "+355",
               "flag": require("/assets/flag/al.png")
          },
          {
               "short": "DZ",
               "name": "阿尔及利亚",
               "en": "Algeria",
               "tel": "+213",
               "flag": require("/assets/flag/dz.png")
          },
          {
               "short": "AS",
               "name": "东萨摩亚(美)",
               "en": "American Samoa",
               "tel": "+1",
               "flag": require("/assets/flag/as.png")
          },
          {
               "short": "AD",
               "name": "安道尔共和国",
               "en": "Andorra",
               "tel": "+376",
               "flag": require("/assets/flag/ad.png")
          },
          {
               "short": "AO",
               "name": "安哥拉",
               "en": "Angola",
               "tel": "+244",
               "flag": require("/assets/flag/ao.png")
          },
          {
               "short": "AI",
               "name": "安圭拉岛",
               "en": "Anguilla",
               "tel": "+1",
               "flag": require("/assets/flag/ai.png")
          },
          {
               "short": "AG",
               "name": "安提瓜和巴布达",
               "en": "Antiguaand Barbuda",
               "tel": "+1",
               "flag": require("/assets/flag/ag.png")
          },
          {
               "short": "AR",
               "name": "阿根廷",
               "en": "Argentina",
               "tel": "+54",
               "flag": require("/assets/flag/ar.png")
          },
          {
               "short": "AM",
               "name": "阿美尼亚",
               "en": "Armenia",
               "tel": "+374",
               "flag": require("/assets/flag/am.png")
          },
          {
               "short": "AW",
               "en": "Aruba",
               "tel": "+297",
               "name": "阿鲁巴",
               "flag": require("/assets/flag/aw.png")
          },
          {
               "short": "AC",
               "name": "阿森松岛",
               "en": "Ascension Island",
               "tel": "+247",
               "flag": require("/assets/flag/sh.png")
          },
          {
               "short": "AU",
               "name": "澳大利亚",
               "en": "Australia",
               "tel": "+61",
               "flag": require("/assets/flag/au.png")
          },
          {
               "short": "AT",
               "name": "奥地利",
               "en": "Austria",
               "tel": "+43",
               "flag": require("/assets/flag/at.png")
          },
          {
               "short": "AZ",
               "name": "阿塞拜疆",
               "en": "Azerbaijan",
               "tel": "+994",
               "flag": require("/assets/flag/az.png")
          },
          {
               "short": "BS",
               "name": "巴哈马",
               "en": "Bahamas",
               "tel": "+1242",
               "flag": require("/assets/flag/bs.png")
          },
          {
               "short": "BH",
               "name": "巴林",
               "en": "Bahrain",
               "tel": "+973",
               "flag": require("/assets/flag/bh.png")
          },
          {
               "short": "BD",
               "name": "孟加拉国",
               "en": "Bangladesh",
               "tel": "+880",
               "flag": require("/assets/flag/bd.png")
          },
          {
               "short": "BB",
               "name": "巴巴多斯",
               "en": "Barbados",
               "tel": "+1246",
               "flag": require("/assets/flag/bb.png")
          },
          {
               "short": "BY",
               "name": "白俄罗斯",
               "en": "Belarus",
               "tel": "+375",
               "flag": require("/assets/flag/by.png")
          },
          {
               "short": "BE",
               "name": "比利时",
               "en": "Belgium",
               "tel": "+32",
               "flag": require("/assets/flag/be.png")
          },
          {
               "short": "BZ",
               "name": "伯利兹",
               "en": "Belize",
               "tel": "+501",
               "flag": require("/assets/flag/bz.png")
          },
          {
               "short": "BJ",
               "name": "贝宁",
               "en": "Benin",
               "tel": "+229",
               "flag": require("/assets/flag/bj.png")
          },
          {
               "short": "BM",
               "name": "百慕大",
               "en": "Bermuda",
               "tel": "+1",
               "flag": require("/assets/flag/bm.png")
          },
          {
               "short": "BT",
               "en": "Bhutan",
               "tel": "+975",
               "name": "不丹",
               "flag": require("/assets/flag/bt.png")
          },
          {
               "short": "BO",
               "name": "玻利维亚",
               "en": "Bolivia",
               "tel": "+591",
               "flag": require("/assets/flag/bo.png")
          },
          {
               "short": "BA",
               "en": "Bosnia & Herzegovina",
               "tel": "+387",
               "name": "波斯尼亚和黑塞哥维那",
               "flag": require("/assets/flag/ba.png")
          },
          {
               "short": "BW",
               "name": "博茨瓦纳",
               "en": "Botswana",
               "tel": "+267",
               "flag": require("/assets/flag/bw.png")
          },
          {
               "short": "BR",
               "name": "巴西",
               "en": "Brazil",
               "tel": "+55",
               "flag": require("/assets/flag/br.png")
          },
          {
               "short": "IO",
               "en": "British Indian Ocean Territory",
               "tel": "+246",
               "name": "英属印度洋领地",
               "flag": require("/assets/flag/io.png")
          },
          {
               "short": "VG",
               "en": "British Virgin Islands",
               "tel": "+1",
               "name": "英属维尔京群岛",
               "flag": require("/assets/flag/vg.png")
          },
          {
               "short": "BN",
               "name": "文莱",
               "en": "Brunei",
               "tel": "+673",
               "flag": require("/assets/flag/bn.png")
          },
          {
               "short": "BG",
               "name": "保加利亚",
               "en": "Bulgaria",
               "tel": "+359",
               "flag": require("/assets/flag/bg.png")
          },
          {
               "short": "BF",
               "name": "布基纳法索",
               "en": "Burkina-faso",
               "tel": "+226",
               "flag": require("/assets/flag/bf.png")
          },
          {
               "short": "BI",
               "name": "布隆迪",
               "en": "Burundi",
               "tel": "+257",
               "flag": require("/assets/flag/bi.png")
          },
          {
               "short": "KH",
               "name": "柬埔寨",
               "en": "Cambodia(Kampuchea)",
               "tel": "+855",
               "flag": require("/assets/flag/kh.png")
          },
          {
               "short": "CM",
               "name": "喀麦隆",
               "en": "Cameroon",
               "tel": "+237",
               "flag": require("/assets/flag/cm.png")
          },
          {
               "short": "CA",
               "name": "加拿大",
               "en": "Canada",
               "tel": "+1",
               "flag": require("/assets/flag/ca.png")
          },
          {
               "short": "CV",
               "en": "Cape Verde",
               "tel": "+238",
               "name": "佛得角",
               "flag": require("/assets/flag/cv.png")
          },
          {
               "short": "bq",
               "en": "Caribbean Netherlands",
               "tel": "+599",
               "name": "荷属加勒比区",
               "flag": require("/assets/flag/nl.png")
          },
          {
               "short": "KY",
               "name": "开曼群岛",
               "en": "Cayman Islands",
               "tel": "+1",
               "flag": require("/assets/flag/ky.png") 
          },
          {
               "short": "CF",
               "name": "中非共和国",
               "en": "Central African Republic",
               "tel": "+236",
               "flag": require("/assets/flag/cf.png")
          },
          {
               "short": "TD",
               "name": "乍得",
               "en": "Chad",
               "tel": "+235",
               "flag": require("/assets/flag/td.png")
          },
          {
               "short": "CL",
               "name": "智利",
               "en": "Chile",
               "tel": "+56",
               "flag": require("/assets/flag/cl.png")
          },
          {
               "short": "CN",
               "name": "中国",
               "en": "China",
               "tel": "+86",
               "flag": require("/assets/flag/cn.png")
          },
          {
               "short": "CO",
               "name": "哥伦比亚",
               "en": "Colombia",
               "tel": "+57",
               "flag": require("/assets/flag/co.png")
          },
          {
               "short": "KM",
               "en": "Comoros",
               "tel": "+269",
               "name": "科摩罗",
               "flag": require("/assets/flag/km.png")
          },
          {
               "short": "CG",
               "en": "Congo-Brazzaville",
               "tel": "+242",
               "name": "刚果（布）",
               "flag": require("/assets/flag/cg.png")
          },
          {
               "short": "CD",
               "en": "Congo-Kinshasa",
               "tel": "+243",
               "name": "刚果（金）",
               "flag": require("/assets/flag/cd.png")
          },
          {
               "short": "CK",
               "name": "库克群岛",
               "en": "CookIs.",
               "tel": "+682",
               "flag": require("/assets/flag/ck.png")
          },
          {
               "short": "CR",
               "name": "哥斯达黎加",
               "en": "Costa Rica",
               "tel": "+506",
               "flag": require("/assets/flag/cr.png")
          },
          {
               "short": "CI",
               "name": "科特迪瓦",
               "en": "Ivory Coast",
               "tel": "+225",
               "flag": require("/assets/flag/ci.png")
          },
          {
               "short": "HR",
               "en": "Croatia",
               "tel": "+385",
               "name": "克罗地亚",
               "flag": require("/assets/flag/hr.png")
          },
          {
               "short": "CU",
               "name": "古巴",
               "en": "Cuba",
               "tel": "+53",
               "flag": require("/assets/flag/cu.png")
          },
          {
               "short": "CW",
               "en": "Curaçao",
               "tel": "+599",
               "name": "库拉索",
               "flag": require("/assets/flag/cw.png")
          },
          {
               "short": "CY",
               "name": "塞浦路斯",
               "en": "Cyprus",
               "tel": "+357",
               "flag": require("/assets/flag/cy.png")
          },
          {
               "short": "CZ",
               "name": "捷克",
               "en": "Czech Republic",
               "tel": "+420",
               "flag": require("/assets/flag/cz.png")
          },
          {
               "short": "DK",
               "name": "丹麦",
               "en": "Denmark",
               "tel": "+45",
               "flag": require("/assets/flag/dk.png")
          },
          {
               "short": "DJ",
               "name": "吉布提",
               "en": "Djibouti",
               "tel": "+253",
               "flag": require("/assets/flag/dj.png")
          },
          {
               "short": "DM",
               "en": "Dominica",
               "tel": "+1",
               "name": "多米尼克",
               "flag": require("/assets/flag/dm.png")
          },
          {
               "short": "DO",
               "name": "多米尼加共和国",
               "en": "Dominica Rep.",
               "tel": "+1890",
               "flag": require("/assets/flag/do.png")
          },
          {
               "short": "EC",
               "name": "厄瓜多尔",
               "en": "Ecuador",
               "tel": "+593",
               "flag": require("/assets/flag/ec.png")
          },
          {
               "short": "EG",
               "name": "埃及",
               "en": "Egypt",
               "tel": "+20",
               "flag": require("/assets/flag/eg.png")
          },
          {
               "short": "SV",
               "name": "萨尔瓦多",
               "en": "EISalvador",
               "tel": "+503",
               "flag": require("/assets/flag/sv.png")
          },
          {
               "short": "GQ",
               "en": "Equatorial Guinea",
               "tel": "+240",
               "name": "赤道几内亚",
               "flag": require("/assets/flag/gq.png")
          },
          {
               "short": "ER",
               "en": "Eritrea",
               "tel": "+291",
               "name": "厄立特里亚",
               "flag": require("/assets/flag/er.png")
          },
          {
               "short": "EE",
               "name": "爱沙尼亚",
               "en": "Estonia",
               "tel": "+372",
               "flag": require("/assets/flag/ee.png")
          },
          {
               "short": "SZ",
               "name": "斯威士兰",
               "en": "Swaziland",
               "tel": "+268",
               "flag": require("/assets/flag/sz.png")
          },
          {
               "short": "ET",
               "name": "埃塞俄比亚",
               "en": "Ethiopia",
               "tel": "+251",
               "flag": require("/assets/flag/et.png")
          },
          {
               "short": "FK",
               "en": "Falkland Islands(Islas Malvinas)",
               "tel": "+500",
               "name": "福克兰群岛（马尔维纳斯群岛）",
               "flag": require("/assets/flag/fk.png")
          },
          {
               "short": "FO",
               "en": "Faroe Islands",
               "tel": "+298",
               "name": "法罗群岛",
               "flag": require("/assets/flag/fo.png")
          },
          {
               "short": "FJ",
               "name": "斐济",
               "en": "Fiji",
               "tel": "+679",
               "flag": require("/assets/flag/fj.png")
          },
          {
               "short": "FI",
               "name": "芬兰",
               "en": "Finland",
               "tel": "+358",
               "flag": require("/assets/flag/fi.png")
          },
          {
               "short": "FR",
               "name": "法国",
               "en": "France",
               "tel": "+33",
               "flag": require("/assets/flag/fr.png")
          },
          {
               "short": "GF",
               "name": "法属圭亚那",
               "en": "French Guiana",
               "tel": "+594",
               "flag": require("/assets/flag/gf.png")
          },
          {
               "short": "PF",
               "name": "法属波利尼西亚",
               "en": "French Polynesia",
               "tel": "+689",
               "flag": require("/assets/flag/pf.png")
          },
          {
               "short": "GA",
               "name": "加蓬",
               "en": "Gabon",
               "tel": "+241",
               "flag": require("/assets/flag/ga.png")
          },
          {
               "short": "GM",
               "name": "冈比亚",
               "en": "Gambia",
               "tel": "+220",
               "flag": require("/assets/flag/gm.png")
          },
          {
               "short": "GE",
               "name": "格鲁吉亚",
               "en": "Georgia",
               "tel": "+995",
               "flag": require("/assets/flag/ge.png")
          },
          {
               "short": "DE",
               "name": "德国",
               "en": "Germany",
               "tel": "+49",
               "flag": require("/assets/flag/de.png")
          },
          {
               "short": "GH",
               "name": "加纳",
               "en": "Ghana",
               "tel": "+233",
               "flag": require("/assets/flag/gh.png")
          },
          {
               "short": "GI",
               "name": "直布罗陀",
               "en": "Gibraltar",
               "tel": "+350",
               "flag": require("/assets/flag/gi.png")
          },
          {
               "short": "GR",
               "name": "希腊",
               "en": "Greece",
               "tel": "+30",
               "flag": require("/assets/flag/gr.png")
          },
          {
               "short": "GL",
               "en": "Greenland",
               "tel": "+299",
               "name": "格陵兰",
               "flag": require("/assets/flag/gl.png")
           },
           {
               "short": "GD",
               "name": "格林纳达",
               "en": "Grenada",
               "tel": "+1809",
               "flag": require("/assets/flag/gd.png")
          },
          {
               "short": "GP",
               "en": "Guadeloupe",
               "tel": "+590",
               "name": "瓜德罗普",
               "flag": require("/assets/flag/gp.png")
          },
          {
               "short": "GU",
               "name": "关岛",
               "en": "Guam",
               "tel": "+1",
               "flag": require("/assets/flag/gu.png")
          },
          {
               "short": "GT",
               "name": "危地马拉",
               "en": "Guatemala",
               "tel": "+502",
               "flag": require("/assets/flag/gt.png")
          },
          {
               "short": "GN",
               "name": "几内亚",
               "en": "Guinea",
               "tel": "+224",
               "flag": require("/assets/flag/gn.png")
          },
          {
               "short": "GW",
               "en": "Guinea-Bissau",
               "tel": "+245",
               "name": "几内亚比绍",
               "flag": require("/assets/flag/gw.png")
          },
          {
               "short": "GY",
               "name": "圭亚那",
               "en": "Guyana",
               "tel": "+592",
               "flag": require("/assets/flag/gy.png")
          },
          {
               "short": "HT",
               "name": "海地",
               "en": "Haiti",
               "tel": "+509",
               "flag": require("/assets/flag/ht.png")
          },
          {
               "short": "HN",
               "name": "洪都拉斯",
               "en": "Honduras",
               "tel": "+504",
               "flag": require("/assets/flag/hn.png")
          },
          {
               "short": "HK",
               "name": "香港(中国)",
               "en": "Hong kong",
               "tel": "+852",
               "flag": require("/assets/flag/hk.png")
          },
          {
               "short": "HU",
               "name": "匈牙利",
               "en": "Hungary",
               "tel": "+36",
               "flag": require("/assets/flag/hu.png")
          },
          {
               "short": "IS",
               "name": "冰岛",
               "en": "Iceland",
               "tel": "+354",
               "flag": require("/assets/flag/is.png")
          },
          {
               "short": "IN",
               "name": "印度",
               "en": "India",
               "tel": "+91",
               "flag": require("/assets/flag/in.png")
          },
          {
               "short": "ID",
               "name": "印度尼西亚",
               "en": "Indonesia",
               "tel": "+62",
               "flag": require("/assets/flag/id.png")
          },
          {
               "short": "IR",
               "name": "伊朗",
               "en": "Iran",
               "tel": "+98",
               "flag": require("/assets/flag/ir.png")
          },
          {
               "short": "IQ",
               "name": "伊拉克",
               "en": "Iraq",
               "tel": "+964",
               "flag": require("/assets/flag/iq.png")
          },
          {
               "short": "IE",
               "name": "爱尔兰",
               "en": "Ireland",
               "tel": "+353",
               "flag": require("/assets/flag/ie.png")
          },
          {
               "short": "IL",
               "name": "以色列",
               "en": "Israel",
               "tel": "+972",
               "flag": require("/assets/flag/il.png")
          },
          {
               "short": "IT",
               "name": "意大利",
               "en": "Italy",
               "tel": "+39",
               "flag": require("/assets/flag/it.png")
          },
          {
               "short": "JM",
               "name": "牙买加",
               "en": "Jamaica",
               "tel": "+1876",
               "flag": require("/assets/flag/jm.png")
          },
          {
               "short": "JP",
               "name": "日本",
               "en": "Japan",
               "tel": "+81",
               "flag": require("/assets/flag/jp.png")
          },
          {
               "short": "JO",
               "name": "约旦",
               "en": "Jordan",
               "tel": "+962",
               "flag": require("/assets/flag/jo.png")
          },
          {
               "short": "KZ",
               "name": "哈萨克斯坦",
               "en": "Kazakstan",
               "tel": "+327",
               "flag": require("/assets/flag/kz.png")
          },
          {
               "short": "KE",
               "name": "肯尼亚",
               "en": "Kenya",
               "tel": "+254",
               "flag": require("/assets/flag/ke.png")
          },
          {
               "short": "KI",
               "en": "Kiribati",
               "tel": "+686",
               "name": "基里巴斯",
               "flag": require("/assets/flag/ki.png")
          },
          {
               "short": "XK",
               "en": "Kosovo",
               "tel": "+383",
               "name": "科索沃",
               "flag": require("/assets/flag/xk.png")
          },
          {
               "short": "KW",
               "name": "科威特",
               "en": "Kuwait",
               "tel": "+965",
               "flag": require("/assets/flag/kw.png")
          },
          {
               "short": "KG",
               "name": "吉尔吉斯斯坦",
               "en": "Kyrgyzstan",
               "tel": "+996",
               "flag": require("/assets/flag/kg.png")
          },
          {
               "short": "LA",
               "name": "老挝",
               "en": "Laos",
               "tel": "+856",
               "flag": require("/assets/flag/la.png")
          },
          {
               "short": "LV",
               "name": "拉脱维亚",
               "en": "Latvia",
               "tel": "+371",
               "flag": require("/assets/flag/lv.png")
          },
          {
               "short": "LB",
               "name": "黎巴嫩",
               "en": "Lebanon",
               "tel": "+961",
               "flag": require("/assets/flag/lb.png")
          },
          {
               "short": "LS",
               "name": "莱索托",
               "en": "Lesotho",
               "tel": "+266",
               "flag": require("/assets/flag/ls.png")
          },
          {
               "short": "LR",
               "name": "利比里亚",
               "en": "Liberia",
               "tel": "+231",
               "flag": require("/assets/flag/lr.png")
          },
          {
               "short": "LY",
               "name": "利比亚",
               "en": "Libya",
               "tel": "+218",
               "flag": require("/assets/flag/ly.png")
          },
          {
               "short": "LI",
               "name": "列支敦士登",
               "en": "Liechtenstein",
               "tel": "+423",
               "flag": require("/assets/flag/li.png")
          },
          {
               "short": "LT",
               "name": "立陶宛",
               "en": "Lithuania",
               "tel": "+370",
               "flag": require("/assets/flag/lt.png")
          },
          {
               "short": "LU",
               "name": "卢森堡",
               "en": "Luxembourg",
               "tel": "+352",
               "flag": require("/assets/flag/lu.png")
          },
          {
               "short": "MO",
               "en": "Macao (China)",
               "tel": "+853",
               "name": "澳门（中国）",
               "flag": require("/assets/flag/mo.png")
          },
          {
               "short": "MG",
               "name": "马达加斯加",
               "en": "Madagascar",
               "tel": "+261",
               "flag": require("/assets/flag/mg.png")
          },
          {
               "short": "MW",
               "name": "马拉维",
               "en": "Malawi",
               "tel": "+265",
               "flag": require("/assets/flag/mw.png")
          },
          {
               "short": "MY",
               "name": "马来西亚",
               "en": "Malaysia",
               "tel": "+60",
               "flag": require("/assets/flag/my.png")
          },
          {
               "short": "MV",
               "name": "马尔代夫",
               "en": "Maldives",
               "tel": "+960",
               "flag": require("/assets/flag/mv.png")
          },
          {
               "short": "ML",
               "name": "马里",
               "en": "Mali",
               "tel": "+223",
               "flag": require("/assets/flag/ml.png")
          },
          {
               "short": "MT",
               "name": "马耳他",
               "en": "Malta",
               "tel": "+356",
               "flag": require("/assets/flag/mt.png")
          },
          {
               "short": "MH",
               "en": "Marshall Islands",
               "tel": "+692",
               "name": "马绍尔群岛",
               "flag": require("/assets/flag/mh.png")
          },
          {
               "short": "MQ",
               "name": "马提尼克",
               "en": "Martinique",
               "tel": "+596",
               "flag": require("/assets/flag/mq.png")
          },
          {
               "short": "MR",
               "en": "Mauritania",
               "tel": "+222",
               "name": "毛里塔尼亚",
               "flag": require("/assets/flag/mr.png")
          },
          {
               "short": "MU",
               "name": "毛里求斯",
               "en": "Mauritius",
               "tel": "+230",
               "flag": require("/assets/flag/mu.png")
          },
          {
               "short": "MX",
               "name": "墨西哥",
               "en": "Mexico",
               "tel": "+52",
               "flag": require("/assets/flag/mx.png")
          },
          {
               "short": "FM",
               "en": "Micronesia",
               "tel": "+691",
               "name": "密克罗尼西亚",
               "flag": require("/assets/flag/fm.png")
          },
          {
               "short": "MD",
               "name": "摩尔多瓦",
               "en": "Moldova,Republicof",
               "tel": "+373",
               "flag": require("/assets/flag/md.png")
          },
          {
               "short": "MC",
               "name": "摩纳哥",
               "en": "Monaco",
               "tel": "+377",
               "flag": require("/assets/flag/mc.png")
          },
          {
               "short": "MN",
               "name": "蒙古",
               "en": "Mongolia",
               "tel": "+976",
               "flag": require("/assets/flag/mn.png")
          },
          {
               "short": "ME",
               "en": "Montenegro",
               "tel": "+382",
               "name": "黑山",
               "flag": require("/assets/flag/me.png")
           },
           {
               "short": "MS",
               "en": "Montserrat",
               "tel": "+1",
               "name": "蒙特塞拉特",
               "flag": require("/assets/flag/ms.png")
           },
           {
               "short": "MA",
               "name": "摩洛哥",
               "en": "Morocco",
               "tel": "+212",
               "flag": require("/assets/flag/ma.png")
          },
          {
               "short": "MZ",
               "name": "莫桑比克",
               "en": "Mozambique",
               "tel": "+258",
               "flag": require("/assets/flag/mz.png")
          },
          {
               "short": "MM",
               "name": "缅甸",
               "en": "Burma",
               "tel": "+95",
               "flag": require("/assets/flag/mm.png")
          },
          {
               "short": "NA",
               "name": "纳米比亚",
               "en": "Namibia",
               "tel": "+264",
               "flag": require("/assets/flag/na.png")
          },
          {
               "short": "NR",
               "name": "瑙鲁",
               "en": "Nauru",
               "tel": "+674",
               "flag": require("/assets/flag/nr.png")
          },
          {
               "short": "NP",
               "name": "尼泊尔",
               "en": "Nepal",
               "tel": "+977",
               "flag": require("/assets/flag/np.png")
          },
          {
               "short": "NL",
               "name": "荷兰",
               "en": "Netherlands",
               "tel": "+31",
               "flag": require("/assets/flag/nl.png")
          },
          {
               "short": "NC",
               "en": "New Caledonia",
               "tel": "+687",
               "name": "新喀里多尼亚",
               "flag": require("/assets/flag/nc.png")
          },
          {
               "short": "NZ",
               "name": "新西兰",
               "en": "New Zealand",
               "tel": "+64",
               "flag": require("/assets/flag/nz.png")
          },
          {
               "short": "NI",
               "name": "尼加拉瓜",
               "en": "Nicaragua",
               "tel": "+505",
               "flag": require("/assets/flag/ni.png")
          },
          {
               "short": "NE",
               "name": "尼日尔",
               "en": "Niger",
               "tel": "+977",
               "flag": require("/assets/flag/ne.png")
          },
          {
               "short": "NG",
               "name": "尼日利亚",
               "en": "Nigeria",
               "tel": "+234",
               "flag": require("/assets/flag/ng.png")
          },
          {
               "short": "NU",
               "en": "Niue",
               "tel": "+683",
               "name": "纽埃",
               "flag": require("/assets/flag/nu.png")
          },
          {
               "short": "NF",
               "en": "Norfolk Island",
               "tel": "+672",
               "name": "诺福克岛",
               "flag": require("/assets/flag/nf.png"),
          },
          {
               "short": "KP",
               "name": "朝鲜",
               "en": "North Korea",
               "tel": "+850",
               "flag": require("/assets/flag/kp.png")
          },
          {
               "short": "MK",
               "en": "North Macedonia",
               "tel": "+389",
               "name": "北马其顿",
               "flag": require("/assets/flag/mk.png")
          },
          {
               "short": "MP",
               "en": "Northern Mariana Islands",
               "tel": "+1",
               "name": "北马里亚纳群岛",
               "flag": require("/assets/flag/mp.png")
          },
          {
               "short": "NO",
               "name": "挪威",
               "en": "Norway",
               "tel": "+47",
               "flag": require("/assets/flag/no.png")
          },
          {
               "short": "OM",
               "name": "阿曼",
               "en": "Oman",
               "tel": "+968",
               "flag": require("/assets/flag/om.png")
          },
          {
               "short": "PK",
               "name": "巴基斯坦",
               "en": "Pakistan",
               "tel": "+92",
               "flag": require("/assets/flag/pk.png")
          },
          {
               "short": "PW",
               "en": "Palau",
               "tel": "+680",
               "name": "帕劳",
               "flag": require("/assets/flag/pw.png")
          },
          {
               "short": "PS",
               "name": "巴勒斯坦",
               "en": "Palestine",
               "tel": "+970",
               "flag": require("/assets/flag/ps.png")
          },
          {
               "short": "PA",
               "name": "巴拿马",
               "en": "Panama",
               "tel": "+507",
               "flag": require("/assets/flag/pa.png")
          },
          {
               "short": "PG",
               "name": "巴布亚新几内亚",
               "en": "Papua New Cuinea",
               "tel": "+675",
               "flag": require("/assets/flag/pg.png")
          },
          {
               "short": "PY",
               "name": "巴拉圭",
               "en": "Paraguay",
               "tel": "+595",
               "flag": require("/assets/flag/py.png")
          },
          {
               "short": "PE",
               "name": "秘鲁",
               "en": "Peru",
               "tel": "+51",
               "flag": require("/assets/flag/pe.png")
          },
          {
               "short": "PH",
               "name": "菲律宾",
               "en": "Philippines",
               "tel": "+63",
               "flag": require("/assets/flag/ph.png")
          },
          {
               "short": "PL",
               "name": "波兰",
               "en": "Poland",
               "tel": "+48",
               "flag": require("/assets/flag/pl.png")
          },
          {
               "short": "PT",
               "name": "葡萄牙",
               "en": "Portugal",
               "tel": "+351",
               "flag": require("/assets/flag/pt.png")
          },
          {
               "short": "PR",
               "name": "波多黎各",
               "en": "PuertoRico",
               "tel": "+1787",
               "flag": require("/assets/flag/pr.png")
          },
          {
               "short": "QA",
               "name": "卡塔尔",
               "en": "Qatar",
               "tel": "+974",
               "flag": require("/assets/flag/qa.png")
          },
          {
               "short": "RE",
               "en": "Reunion",
               "tel": "+262",
               "name": "留尼汪",
               "flag": require("/assets/flag/re.png")
          },
          {
               "short": "RO",
               "name": "罗马尼亚",
               "en": "Romania",
               "tel": "+40",
               "flag": require("/assets/flag/ro.png")
          },
          {
               "short": "RU",
               "name": "俄罗斯",
               "en": "Russia",
               "tel": "+7",
               "flag": require("/assets/flag/ru.png")
          },
          {
               "short": "RW",
               "en": "Rwanda",
               "tel": "+250",
               "name": "卢旺达",
               "flag": require("/assets/flag/rw.png")
          },
          {
               "short": "WS",
               "en": "Samoa",
               "tel": "+685",
               "name": "萨摩亚",
               "flag": require("/assets/flag/ws.png")
           },
           {
               "short": "SM",
               "name": "圣马力诺",
               "en": "San Marino",
               "tel": "+378",
               "flag": require("/assets/flag/sm.png")
          },
          {
               "short": "ST",
               "name": "圣多美和普林西比",
               "en": "Sao Tomeand Principe",
               "tel": "+239",
               "flag": require("/assets/flag/st.png")
          },
          {
               "short": "SA",
               "name": "沙特阿拉伯",
               "en": "Saudi Arabia",
               "tel": "+966",
               "flag": require("/assets/flag/sa.png")
          },
          {
               "short": "SN",
               "name": "塞内加尔",
               "en": "Senegal",
               "tel": "+221",
               "flag": require("/assets/flag/sn.png")
          },
          {
               "short": "RS",
               "en": "Serbia",
               "tel": "+381",
               "name": "塞尔维亚",
               "flag": require("/assets/flag/rs.png")
          },
          {
               "short": "SC",
               "name": "塞舌尔",
               "en": "Seychelles",
               "tel": "+248",
               "flag": require("/assets/flag/sc.png")
          },
          {
               "short": "SL",
               "name": "塞拉利昂",
               "en": "Sierra Leone",
               "tel": "+232",
               "flag": require("/assets/flag/sl.png")
          },
          {
               "short": "SG",
               "name": "新加坡",
               "en": "Singapore",
               "tel": "+65",
               "flag": require("/assets/flag/sg.png")
          },
          {
               "short": "SX",
               "en": "Sint Maarten",
               "tel": "+1",
               "name": "荷属圣马丁",
               "flag": require("/assets/flag/sx.png")
          },
          {
               "short": "SK",
               "name": "斯洛伐克",
               "en": "Slovakia",
               "tel": "+421",
               "flag": require("/assets/flag/sk.png")
          },
          {
               "short": "SI",
               "name": "斯洛文尼亚",
               "en": "Slovenia",
               "tel": "+386",
               "flag": require("/assets/flag/si.png")
          },
          {
               "short": "SB",
               "name": "所罗门群岛",
               "en": "SolomonIs",
               "tel": "+677",
               "flag": require("/assets/flag/sb.png")
          },
          {
               "short": "SO",
               "name": "索马里",
               "en": "Somali",
               "tel": "+252",
               "flag": require("/assets/flag/so.png")
          },
          {
               "short": "ZA",
               "name": "南非",
               "en": "South Africa",
               "tel": "+27",
               "flag": require("/assets/flag/za.png")
          },
          {
               "short": "KR",
               "name": "韩国",
               "en": "Korea",
               "tel": "+82",
               "flag": require("/assets/flag/kr.png")
          },
          {
               "short": "SS",
               "en": "South Sudan",
               "tel": "+211",
               "name": "南苏丹",
               "flag": require("/assets/flag/ss.png")
          },
          {
               "short": "ES",
               "name": "西班牙",
               "en": "Spain",
               "tel": "+34",
               "flag": require("/assets/flag/es.png")
          },
          {
               "short": "LK",
               "name": "斯里兰卡",
               "en": "SriLanka",
               "tel": "+94",
               "flag": require("/assets/flag/lk.png")
          },
          {
               "short": "bl",
               "en": "St. Barthélemy",
               "tel": "+590",
               "name": "圣巴泰勒米",
               "flag": require("/assets/flag/bl.png")
          },
          {
               "short": "SH",
               "en": "St. Helena",
               "tel": "+290",
               "name": "圣赫勒拿",
               "flag": require("/assets/flag/sh.png")
          },
          {
               "short": "KN",
               "en": "St. Kitts & Nevis",
               "tel": "+1",
               "name": "圣基茨和尼维斯",
               "flag": require("/assets/flag/kn.png")
          },
          {
               "short": "LC",
               "name": "圣卢西亚",
               "en": "St.Lucia",
               "tel": "+1758",
               "flag": require("/assets/flag/lc.png")
          },
          {
               "short": "MF",
               "en": "St. Martin",
               "tel": "+590",
               "name": "法属圣马丁",
               "flag": require("/assets/flag/mf.png")
          },
          {
               "short": "PM",
               "en": "St. Pierre & Miquelon",
               "tel": "+508",
               "name": "圣皮埃尔和密克隆群岛",
               "flag": require("/assets/flag/pm.png")
          },
          {
               "short": "VC",
               "en": "St. Vincent & Grenadines",
               "tel": "+1",
               "name": "圣文森特和格林纳丁斯",
               "flag": require("/assets/flag/vc.png")
          },
          {
               "short": "SD",
               "name": "苏丹",
               "en": "Sudan",
               "tel": "+249",
               "flag": require("/assets/flag/sd.png")
          },
          {
               "short": "SR",
               "name": "苏里南",
               "en": "Suriname",
               "tel": "+597",
               "flag": require("/assets/flag/sr.png")
          },
          {
               "short": "SE",
               "name": "瑞典",
               "en": "Sweden",
               "tel": "+46",
               "flag": require("/assets/flag/se.png")
          },
          {
               "short": "CH",
               "name": "瑞士",
               "en": "Switzerland",
               "tel": "+41",
               "flag": require("/assets/flag/ch.png")
          },
          {
               "short": "SY",
               "name": "叙利亚",
               "en": "Syria",
               "tel": "+963",
               "flag": require("/assets/flag/sy.png")
          },
          {
               "short": "TW",
               "name": "台湾（中国）",
               "en": "Taiwan",
               "tel": "+886",
               "flag": require("/assets/flag/tw.png")
          },
          {
               "short": "TJ",
               "name": "塔吉克斯坦",
               "en": "Tajikstan",
               "tel": "+992",
               "flag": require("/assets/flag/tj.png")
          },
          {
               "short": "TZ",
               "name": "坦桑尼亚",
               "en": "Tanzania",
               "tel": "+255",
               "flag": require("/assets/flag/tz.png")
          },
          {
               "short": "TH",
               "name": "泰国",
               "en": "Thailand",
               "tel": "+66",
               "flag": require("/assets/flag/th.png")
          },
          {
               "short": "TL",
               "en": "Timor-Leste",
               "tel": "+670",
               "name": "东帝汶",
               "flag": require("/assets/flag/tl.png")
           },
           {
               "short": "TG",
               "name": "多哥",
               "en": "Togo",
               "tel": "+228",
               "flag": require("/assets/flag/tg.png")
          },
          {
               "short": "TK",
               "en": "Tokelau",
               "tel": "+690",
               "name": "托克劳",
               "flag": require("/assets/flag/tk.png")
          },
          {
               "short": "TO",
               "name": "汤加",
               "en": "Tonga",
               "tel": "+676",
               "flag": require("/assets/flag/to.png")
          },
          {
               "short": "TT",
               "name": "特立尼达和多巴哥",
               "en": "Trinidad and Tobago",
               "tel": "+1809",
               "flag": require("/assets/flag/tt.png")
          },
          {
               "short": "TN",
               "name": "突尼斯",
               "en": "Tunisia",
               "tel": "+216",
               "flag": require("/assets/flag/tn.png")
          },
          {
               "short": "TR",
               "name": "土耳其",
               "en": "Turkey",
               "tel": "+90",
               "flag": require("/assets/flag/tr.png")
          },
          {
               "short": "TM",
               "name": "土库曼斯坦",
               "en": "Turkmenistan",
               "tel": "+993",
               "flag": require("/assets/flag/tm.png")
          },
          {
               "short": "TC",
               "en": "Turks & Caicos Islands",
               "tel": "+1",
               "name": "特克斯和凯科斯群岛",
               "flag": require("/assets/flag/tc.png")
          },
          {
               "short": "TV",
               "en": "Tuvalu",
               "tel": "+688",
               "name": "图瓦卢",
               "flag": require("/assets/flag/tv.png")
           },
           {
               "short": "VI",
               "en": "U.S. Virgin Islands",
               "tel": "+1",
               "name": "美属维尔京群岛",
               "flag": require("/assets/flag/vi.png")
          },
          {
               "short": "UG",
               "name": "乌干达",
               "en": "Uganda",
               "tel": "+256",
               "flag": require("/assets/flag/ug.png")
          },
          {
               "short": "UA",
               "name": "乌克兰",
               "en": "Ukraine",
               "tel": "+380",
               "flag": require("/assets/flag/ua.png")
          },
          {
               "short": "AE",
               "name": "阿拉伯联合酋长国",
               "en": "United Arab Emirates",
               "tel": "+971",
               "flag": require("/assets/flag/ae.png")
          },
          {
               "short": "GB",
               "name": "英国",
               "en": "United Kingdom",
               "tel": "+44",
               "flag": require("/assets/flag/gb.png")
          },
          {
               "short": "US",
               "name": "美国",
               "en": "United Statesof America",
               "tel": "+1",
               "flag": require("/assets/flag/us.png")
          },
          {
               "short": "UY",
               "name": "乌拉圭",
               "en": "Uruguay",
               "tel": "+598",
               "flag": require("/assets/flag/uy.png")
          },
          {
               "short": "UZ",
               "name": "乌兹别克斯坦",
               "en": "Uzbekistan",
               "tel": "+233",
               "flag": require("/assets/flag/uz.png")
          },
          {
               "short": "VU",
               "en": "Vanuatu",
               "tel": "+678",
               "name": "瓦努阿图",
               "flag": require("/assets/flag/vu.png")
          },
          {
               "short": "VA",
               "en": "Vatican City",
               "tel": "+39",
               "name": "梵蒂冈",
               "flag": require("/assets/flag/va.png")
          },
          {
               "short": "VE",
               "name": "委内瑞拉",
               "en": "Venezuela",
               "tel": "+58",
               "flag": require("/assets/flag/ve.png")
          },
          {
               "short": "VN",
               "name": "越南",
               "en": "Vietnam",
               "tel": "+84",
               "flag": require("/assets/flag/vn.png")
          },
          {
               "short": "WF",
               "en": "Wallis & Futuna",
               "tel": "+681",
               "name": "瓦利斯和富图纳",
               "flag": require("/assets/flag/wf.png")
           },
           {
               "short": "YE",
               "name": "也门",
               "en": "Yemen",
               "tel": "+967",
               "flag": require("/assets/flag/ye.png")
          },
          {
               "short": "ZM",
               "name": "赞比亚",
               "en": "Zambia",
               "tel": "+260",
               "flag": require("/assets/flag/zm.png")
          },
          {
               "short": "ZW",
               "name": "津巴布韦",
               "en": "Zimbabwe",
               "tel": "+263",
               "flag": require("/assets/flag/zw.png")
          },
          // {
          //      "short": "KT",
          //      "name": "科特迪瓦共和国",
          //      "en": "RepublicofIvoryCoast",
          //      "tel": "+225",
          //      "flag": require("/assets/flag/kt.png")
          // },
          
          // {
          //      "short": "YU",
          //      "name": "南斯拉夫",
          //      "en": "Yugoslavia",
          //      "tel": "+381",
          //      "flag": require("/assets/flag/yu.png")
          // },
          // {
          //      "short": "ZR",
          //      "name": "扎伊尔",
          //      "en": "Zaire",
          //      "tel": "+243",
          //      "flag": require("/assets/flag/zr.png")
          // },
          
     ]
}
