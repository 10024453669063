export const state = () => ({
  email: ''
})

export const mutations = {
  setEmail(state, email) {
    state.email = email
  }
}

export const actions = {
  setEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      commit('setEmail', email)
      resolve()
    })
  }
}
